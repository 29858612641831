.auctions {

  .site-main {
    @include media-breakpoint-up(xl) {
      padding-top: 10px;
      padding-bottom: 80px;
    }
  }

}

.featured-auction {
  margin-bottom: 60px;

  @include media-breakpoint-up(xl) {
    margin-bottom: 75px;
  }

  .auction-title {
    font-size: var(--auctionTitleFontSize);
    text-transform: var(--auctionTitleTextTransform);
    color: var(--auctionTitleColor);
    padding-top: var(--auctionTitlePaddingTop);
    padding-bottom: var(--auctionTitlePaddingBottom);
    margin-bottom: 0;

    a {
      color: inherit;

      @include hover-focus {
        color: theme-color(primary);
      }
    }

    @include media-breakpoint-up(xl) {
      padding-top: 32px;
    }
  }

  .auction-time,
  .auction-place {
    font-size: 1.25rem;
  }

  .auction-time {
    margin-bottom: 0;
    font-size: var(--auctionDateFontSize);
    padding-bottom: var(--auctionDatePaddingBottom);
  }

  .auction-place {
    margin-bottom: 0;
    font-size: var(--auctionPlaceFontSize);
    padding-bottom: var(--auctionPlacePaddingBottom);
  }

  .auction-before {
    margin-bottom: 66px;
    font-size: 1.125rem;
  }

  .auction-excerpt {
    margin-bottom: 46px;
    font-size: 1rem;
  }

  .auction-main-footer {
    @include media-breakpoint-up(xl) {
      position: absolute;
      top: auto;
      bottom: 0;
      left: ($grid-gutter-width / 2);
      right: ($grid-gutter-width / 2);
    }
  }

  .auction-share {
    text-align: right;
  }

  .socials-share-header {
    text-align: left;
  }
}

.auction-title {
  margin-bottom: 6px;
}

.auction-figure-wrapper {
  // margin-bottom:32px;
  text-align: center;
}

.auction-main {
  @include media-breakpoint-up(xl) {
    .auction-figure-wrapper {
      margin-right: -($grid-gutter-width / 2);
    }

    .auction-content-wrapper {
      padding-left: $grid-gutter-width;
    }
  }
}

.auction-image-link {
  width: 100%;
  overflow: hidden;

  &, img {
    display: block;
  }

  @include hoverable-dimmed();
  @include hoverable-zoomed("img");

  &:after{
    display: var(--sliderLayerDisplay);
  }
}

.auction-figure {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

span.auction-figure {
  height: 0;
  padding: {
    bottom: 75%;
  }
  display: block;
  background: no-repeat 50% 50% / cover;
}

.auctions-list {
  margin: 0 0 60px;
  padding: 0;
  list-style: none;

  .auctions & {
    margin-bottom: 0;
  }

  > li {
    display: block;
  }

  .auction-figure-wrapper {
    margin-bottom: 16px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 26px;
    }
  }

  .auction-title {
    font-size: 1.25rem;

    &, & a {
      color: $black;

      @include hover-focus {
        color: theme-color(primary);
      }
    }
  }

  .auction-time {
    font-size: 1.125rem;
  }
}

.auctions-list-article {
  min-height: 410px;
  margin-left: -($grid-gutter-width /2);
  margin-right: -($grid-gutter-width /2);
  padding: 20px;
  transition: background-color $defaultTransitionDuration $defaultTransitionFunction;

  @include hover-focus {
    background-color: theme-color(light);

    .auction-figure-wrapper::after {
      background-color: rgba(0, 0, 0, .1);
    }
  }

  a {
    color: theme-color(primary);

    @include hover-focus {
      color: theme-color(tertiary);
    }

    .auction-time {
      text-decoration: none;

      @include hover-focus {
        color: theme-color(dark);
        text-decoration: none;
      }
    }
  }
}

.auction-title {
  font-size: 2rem;
  line-height: 1.2;
  text-transform: uppercase;

  a {
    &:hover {
      color: var(--secondary) !important;
    }
  }
}

.auction-time {
  &, a & {
    color: theme-color(dark);
    text-decoration: none;

    @include hover-focus {
      color: theme-color(dark);
      text-decoration: none;
    }
  }
}

// .auction-content-section{
//   padding-bottom:80px;
// }

.sort-auctions-dropdown {
  float: right;
  cursor: pointer;
}

.auctions-list.archived {
  .auctions-list-article {
    min-height: auto;

    .auction-title {
      text-transform: var(--auctionArchivedTitleTextTransform);
      font-size: var(--auctionArchivedTitleFontSize);
      color: var(--auctionArchivedTitleColor);
      padding-top: var(--auctionArchivedTitlePaddingTop);
      padding-bottom: var(--auctionArchivedTitlePaddingBottom);
      margin-bottom: 0;

      a {
        color: var(--auctionArchivedTitleColor);
      }
    }

    .auction-time {
      font-size: var(--auctionArchivedDateFontSize);
      padding-bottom: var(--auctionArchivedDatePaddingBottom);
    }

    .auction-figure-wrapper {
      height: var(--auctionArchivedImageHeight);
      margin-bottom: 0;
      @include hoverable-dimmed();

      &:after{
        display: var(--sliderLayerDisplay);
      }

      span.auction-figure {
        height: var(--auctionArchivedImageHeight);
        padding-bottom: 0;
        background: no-repeat 50% 50% / cover;
      }
    }
  }
}
