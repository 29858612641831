.exhibitions {
  .site-main {
    @include media-breakpoint-up(xl) {
      padding-bottom: 100px;
    }
  }

  .exhibition-title {
    font-size: var(--newsExhibitionTitleFontSize);
    color: var(--newsExhibitionTitleFontColor);

    a {
      color: var(--newsExhibitionTitleFontColor);
    }
  }

  .exhibition-time {
    display: var(--newsExhibitionDateDisplay) !important;
    font-size: var(--newsExhibitionDateFontSize);
    margin-bottom: var(--paddingBottomNewsExhibitionDate);
  }

  .socials-share-header {
    text-align: left;
  }

  .page-pagination {
    margin-top: 60px;
  }
}

.exhibition-main {
  margin-bottom: 60px;

  @include media-breakpoint-up(xl) {
    margin-bottom: 90px;
  }
}

.exhibition-row {
  + .exhibition-row {
    margin-top: 50px;
  }
}

.exhibition-main-content {

  @include media-breakpoint-up(lg) {
    padding-bottom: 42px;
  }
}

.exhibition-title {
  margin-bottom: 6px;
}

.exhibition-article-title {
  @include media-breakpoint-up(xl) {
    padding-top: 40px;
  }
}

.exhibition-figure-wrapper {
  // margin-bottom:32px;
  text-align: center;

  .exhibition-link {
    width: 100%;
    display: inline-block;

    @include hoverable-dimmed();
    @include hoverable-zoomed(".img-fluid");

    &.no-zoom {
      @include hover-focus-class {
        .img-fluid {
          transform: scale(1) !important;
        }
      }
    }

    &::after {
      display: var(--sliderLayerDisplay);
    }
  }

  .exhibition-main & {
    @include media-breakpoint-up(xl) {
      padding-left: (60px - $grid-gutter-width);

      &:after {
        left: (60px - $grid-gutter-width);
      }
    }
  }

  .exhibition-figure {
    width: 100%;
  }
}

.exhibition-image-link {
  display: block;
  overflow: hidden;
}

.exhibition-figure {
  margin-left: auto;
  margin-right: auto;
}

span.exhibition-figure {
  height: 0;
  padding: {
    bottom: 75%;
  }
  display: block;
  background: no-repeat 50% 50% / cover;
}

.exhibitions-aside-share {
  @extend .artist-aside-socials-share;

  margin-left: 0;
  margin-right: 0;
  text-align: left;
}

.exhibition-more-wrapper {
  text-align: right;
}

.exhibition-more {
  color: var(--secondary);

  @include hover-focus {
    background-color: var(--primary);
    color: $white;
  }
}

.exhibition-main-footer {
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: auto;
    bottom: 0;
    left: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2);
  }
}

.exhibitions-archive-title {
  margin-bottom: 0;

  @include media-breakpoint-up(xl) {
    margin-bottom: 8px;
  }
}

.exhibitions-list {
  margin: 0 0 60px;
  padding: 0;
  list-style: none;

  > li {
    display: block;
  }

  .list-news-wrapper {
    @include media-breakpoint-up(sm) {
      width: 50%;
    }

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    @include media-breakpoint-up(lg) {
      width: 33%;
    }

    @include media-breakpoint-up(xl) {
      width: 33%;
    }
  }

  .exhibition-figure-wrapper {
    margin-bottom: 16px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 26px;
    }
  }

  .exhibition-title {
    font-size: 1.25rem;

    &, a & {
      @include hover-focus {
        color: theme-color(primary);
      }
    }
  }

  .exhibition-time {
    font-size: 1.125rem;
  }
}

.exhibitions-list-article {
  transition: background-color $defaultTransitionDuration $defaultTransitionFunction;

  @include hover-focus {
    .exhibition-figure-wrapper::after {
      background-color: rgba(0, 0, 0, .1);
    }
  }

  a {
    color: theme-color(primary);

    @include hover-focus {
      color: theme-color(primary);
    }

    .exhibition-time {
      text-decoration: none;

      @include hover-focus {
        color: theme-color(dark);
        text-decoration: none;
      }
    }
  }
}

.exhibition-time {
  &, a & {
    color: var(--dark);
    text-decoration: none;

    @include hover-focus {
      color: theme-color(dark);
      text-decoration: none;
    }
  }
}

.exhibition-content-section {
  padding-bottom: 80px;
}

.sort-exhibitions-dropdown {
  float: right;
  cursor: pointer;
}
