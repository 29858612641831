.contact {
  .page-title {
    margin-bottom: 16px;
  }

  .socials-list {
    margin-bottom: 36px;
  }

  p, li {
    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }
  }

  .contact-us-btn-wrapper {
    text-align: right;
    margin-top: -110px;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    @include media-breakpoint-up(lg) {
      text-align: right;
    }

    @include media-breakpoint-up(xl) {
      text-align: left;
    }
  }

  .btn-contact-us {
    font-weight: 400;

    @include hover-focus {
      background-color: rgba(255, 255, 255, 0);
      color: theme-color(primary);
      font-weight: 600;
    }

    @include bold-on-hover(before, title, theme-color(primary), 600) {
    }
  }
}

.contact-page-section {
  padding-bottom: 0;
}

.contact-tel-email {
  line-height: 1.2222;

  @include media-breakpoint-up(xl) {
    padding-left: 40px;
  }
}

.contact-vcard {
  .contact-data {
    margin-bottom: 24px;
    font-size: var(--contactPageAddressFontSize);
    font-weight: 700;
    line-height: 1.8;

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
      font-weight: 400;
      line-height: 1.4;
    }
  }

  .fn {
    line-height: 1;
  }

  p, ul {
    margin-bottom: 20px;
  }
}

.contact-subheader {
  margin-top: 6px;
  margin-bottom: 6px;
  color: var(--dark);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;

  &.contact-bank-account-header {
    margin-top: 0;

    @include media-breakpoint-up(xl) {
      margin-top: 6px;
    }
  }

  &.contact-social-media-header {
    margin-bottom: 12px;
  }
}

.contacts-list {
  margin-left: 0;
  padding: 0;
  list-style: none;
  line-height: 1.2777;
}

.contact-info-text {
  line-height: 1.2222;
}

// map
.map-section {
  display: var(--googleMapDisplay) !important;

  &.page-section {
    padding-bottom: 0;
  }

  .section-title {
    margin-bottom: 26px;
    color: var(--dark);
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;
  }
}

.gmap-inner {
  height: 350px;

  @include media-breakpoint-up(lg) {
    height: 450px;
  }
}

// formularz kontaktowy
.contact-section {
  padding: 0;
}
