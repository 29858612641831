.object-related-list {
  padding: 0;
  font-size: 1rem;
  list-style: none;

  .related-list-item {
    display: block;

    &:before {
      content: "- ";
    }
  }

  .related-object-link {
    width: 100%;
    display: block;
    color: theme-color(dark);
  }

  .related-item-link {
    color: theme-color(dark);

    @include hover-focus {
      color: theme-color(primary);
    }
  }
}

.related-object-image-link {
  width: 77px;
  height: 44px;
  display: block;

  // @include hoverable-dimmed();
  // @include hoverable-zoomed(".related-object-link-bg");
}

.related-object-link-bg {
  width: 90px;
  min-width: 90px;
  height: 90px;
  display: block;
  background: rgba(255, 255, 255, 0) no-repeat 50% 50% / cover;
}

.object-related {
  margin-top: 52px;
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  .content-section-subheader {
    margin-bottom: 6px;
    font-size: 1rem;
  }
}

.related-artists {
  li {
    margin-bottom: 16px;
  }

  .artist-dates {
    color: theme-color(secondary);
    font-size: 1rem;
  }
}
