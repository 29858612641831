$modalTransitionDuration: $defaultTransitionDuration * 2;
$modalTransitionFunction: $defaultTransitionFunction;

$modalDialogInnerMaxWidths: (
  sm: 480px,
  md: 660px,
  lg: 800px,
  xl: 1000px
);

html.has-modal {
  overflow: hidden;
}

body.modal-open,
body.search-modal-open {
  padding-right: 0 !important;
}

.modal-backdrop {
  opacity: 0;
  transition: opacity $defaultTransitionDuration $defaultTransitionFunction;

  .search-modal-open & {
    opacity: .95;
  }
}

.modal {
  &.modal-transforming {
    overflow: hidden;
  }

  &.show {
    .modal-form-container {
      opacity: 1;

      &.modal-form-container-moving {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &.shown {
    overflow-y: scroll;
  }

  &.work-presentation-modal {
    overflow: hidden;

    &.shown {
      overflow-y: scroll;
    }
  }

  &.fade {
    .modal-dialog {
      transform: translate3d(0, 0, 0);
    }
  }

  .close-icon {
    margin: 2.875vw 1vw 0 auto;

    @media screen and (min-width: 1920px) {
      margin: 56px 20px 0 auto;
    }
  }

  .art-presentation-outer {
    margin-bottom: 0 !important;
  }

  .form-control {
    border-color: $white;
    transition: border-color $defaultTransitionDuration $defaultTransitionFunction;

    &.ng-touched {

      &.ng-invalid {
        border-color: rgba($invalidFieldColor, .4) !important;
      }

    }
  }

  textarea.form-control.ng-invalid {
    + .form-control-invalid {
      top: auto;
      bottom: 0;
    }
  }

  .submit-tried & {
    .form-control.ng-invalid {
      border-color: rgba($invalidFieldColor, .4);
    }
  }

  .form-control-invalid {
    height: 38px;
    position: absolute;
    top: 0;
    bottom: auto;
    left: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2);
    display: block;
    overflow: hidden;
    opacity: 0;
    transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
    color: rgba($invalidFieldColor, .8);
    font-size: .75rem;
    line-height: 38px;
    text-align: right;
    pointer-events: none;

    &.show {
      opacity: 1;
    }

    span {
      width: 100%;
      display: block;
    }
  }

  .form-check {
    .form-control-invalid {
      top: auto;
      bottom: -26px;
      text-align: center;
    }
  }

  .btn {
    cursor: pointer;
  }

  .btn-cancel {
    color: theme-color(primary);
    background-color: theme-color(light);
    z-index: 1;

    &:after {
      opacity: .1 !important;
    }

    @include hover-focus {
      border-color: rgba(0, 0, 0, 0) !important;
      color: theme-color(primary);
      text-decoration: none;

      &:after {
        opacity: 0 !important;
      }
    }

    @include victory-button(theme-color(secondary), theme-color(tertiary));
  }

  .btn-apply {
    text-transform: uppercase;

    &[disabled] {
      opacity: 1;
    }
  }

  .form-additional-info {
    line-height: 1.1;
  }

  .collapse {
    display: block !important;
    max-height: 0 !important;
    overflow: hidden !important;
    transition: all $defaultTransitionDuration $defaultTransitionFunction;

    &.show {
      max-height: 230px !important;
    }
  }

}

.modal-dialog-content {
  max-width: 1230px;
  height: 100vh;
  margin: 0 auto;
  padding: 0 ($grid-gutter-width / 2);
  position: relative;
}

.modal-dialog-inner {
  height: 100%;
  margin: 0 auto;
  position: relative;
  transition: opacity $modalTransitionDuration $modalTransitionFunction;

  @each $bp, $mw in $modalDialogInnerMaxWidths {
    @include media-breakpoint-up($bp) {
      max-width: $mw;
    }
  }

  .modal.show & {
    opacity: 1;
  }
}

.modal-step {
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  opacity: 0;
  transform: scale(1.4) translate3d(0, -10%, 0);
  transition: opacity $modalTransitionDuration $modalTransitionFunction, transform $modalTransitionDuration $modalTransitionFunction;
  pointer-events: none;

  &.show {
    pointer-events: all;

    .modal.show & {
      opacity: 1;
      transform: scale(1) translate3d(0, 0, 0);
    }
  }

  &.hide {
    .modal.show & {
      opacity: 0;
      transform: scale(0) translate3d(0, 0, 0);
    }
  }

  &.modal-step-moved {
    top: 33%;
    transform: scale(1.4) translate3d(0, -50%, 0);

    &.show {
      .modal.show & {
        transform: scale(1) translate3d(0, -20%, 0);
      }
    }

    &.hide {
      .modal.show & {
        transform: scale(0) translate3d(0, -50%, 0);
      }
    }
  }

  .modal-dialog-content-top & {
    top: 0;
  }

  .search-modal & {
    top: 33%;
  }
}

.modal-stepless {
  .modal.show & {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

.modal-step-ty {
  pointer-events: none;

  &.show {
    pointer-events: all;
  }

  &,
  .modal-dialog-content-top & {
    top: 20%;
  }

  .newsletter-modal & {
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
  }

  .art-contact-title, .modal-step-title, p {
    color: theme-color(secondary);
    text-align: center;
    text-transform: none;
  }

  .art-contact-title, .modal-step-title {
    margin-bottom: 26px;
    border-bottom-width: 0;
    font-size: 2rem;
  }

  p {
    margin: 0 auto;
  }
}

.modal-title {
  margin-bottom: 24px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: var(--dark);
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    text-align: left;
  }

  &.modal-title-bordered {
    position: relative;

    &:after {
      height: 3px;
      display: block;
      position: absolute;
      top: auto;
      bottom: 0;
      left: ($grid-gutter-width / 2);
      right: ($grid-gutter-width / 2);
      background-color: theme-color(light);
      content: "";
    }
  }

  &.modal-title-col {
    @include make-col-ready();
    @include make-col(12);
  }
}

.modal-columns-container {
  @include make-row();

  .modal-image-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-image-column,
  .modal-form-column {
    @include make-col-ready();
    @include make-col(12);
  }

  @include media-breakpoint-up(md) {
    .modal-image-column {
      @include make-col(6);
    }

    .modal-form-column {
      padding-left: 30px;

      @include make-col(6);

      .contact-form-modal & {
        padding-left: ($grid-gutter-width / 2);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .modal-image-column {
      @include make-col(4.485);
    }

    .modal-form-column {
      padding-left: 60px;

      @include make-col(7.515);

      .contact-form-modal & {
        padding-left: ($grid-gutter-width / 2);
      }
    }
  }
}

.modal-form-container {
  background-color: theme-color(light);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: $modalTransitionDuration;
  transition-timing-function: $modalTransitionFunction;

  .form-label-col,
  .form-input-col {
    @include make-col-ready();
    padding: {
      left: 5px;
      right: 5px;
    }
  }

  @include media-breakpoint-up(sm) {
    .form-label-col {
      @include make-col(4.08);

      padding: {
        left: 5px;
        right: 7px;
      };

      .contact-form-modal & {
        @include make-col(3.36);
      }
    }

    .form-input-col {
      @include make-col(7.92);

      max-width: 306px; // szerokość recaptchy
      padding: {
        left: 2px;
        right: 4px;
      }

      .contact-form-modal & {
        @include make-col(8.64);
      }
    }
  }

  &.modal-form-container-moving {
    transform: translate3d(0, 30%, 0);
  }

  .modal-footer {

    & > .btn-apply,
    & > .btn-cancel {
      margin: {
        left: .125rem;
        right: .125rem;
      };
    }

    flex-wrap: nowrap !important;
    @include media-breakpoint-up(sm) {
      // margin-left:-$grid-gutter-width;
      // padding-left:33.3333%;
      padding-left: 40.5%;
    }
  }

}

.modal-step-inner {
  padding-bottom: 100px;
  pointer-events: none;

  .modal-form-container {
    pointer-events: none;

    .modal-step.show & {
      pointer-events: all;
    }
  }
}

$modalFormBodyPRight: percentage((44 - strip-unit($grid-gutter-width / 2)) / 570);

.modal-form-body {
  padding-top: 36px;
  padding-bottom: 44px;
  padding-right: $modalFormBodyPRight;

  .form-row {
    margin-bottom: 14px;
    justify-content: flex-end;
  }

  .form-control-outer {
    margin-bottom: 0;
    position: relative;
  }

  .col-form-label {
    padding-left: 0;
    line-height: 1.2;
    text-align: left;

    @include media-breakpoint-up(sm) {
      text-align: right;
    }
  }

  .col-form-label,
  input[type="text"],
  input[type="email"],
  textarea {
    font-size: 1rem;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    border-color: theme-color(light) !important;
    border-radius: 0;

    @include hover-focus {
      border-color: var(--primary) !important;
    }
  }

}

form {
  .form-control {
    border-color: $white;
    transition: border-color $defaultTransitionDuration $defaultTransitionFunction;

    &.ng-touched {

      &.ng-invalid {
        border-color: rgba($invalidFieldColor, .4);
      }

    }
  }

  textarea.form-control.ng-invalid {
    + .form-control-invalid {
      top: auto;
      bottom: 0;
    }
  }

  .submit-tried & {
    .form-control.ng-invalid {
      border-color: rgba($invalidFieldColor, .4);
    }
  }

  .form-control-invalid {
    height: 38px;
    position: absolute;
    top: 0;
    bottom: auto;
    left: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2);
    display: block;
    overflow: hidden;
    opacity: 0;
    transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
    color: rgba($invalidFieldColor, .8);
    font-size: .75rem;
    line-height: 38px;
    text-align: right;
    pointer-events: none;

    &.show {
      opacity: 1;
    }

    span {
      width: 100%;
      display: block;
    }
  }

  .form-check {
    .form-control-invalid {
      top: auto;
      bottom: -26px;
      text-align: center;
    }
  }

  .recaptcha-wrapper {
    border: 1px solid rgba($invalidFieldColor, 0);
    border-radius: 2px;
    position: relative;
    float: right;
    transition: border-color 300ms ease-in;

    &.is-invalid {
      border-color: rgba($invalidFieldColor, .8);
    }
  }

  .btn {
    cursor: pointer;
  }

  .form-additional-info {
    line-height: 1.1;
  }

  .collapse {
    display: block !important;
    max-height: 0 !important;
    overflow: hidden !important;
    transition: all $defaultTransitionDuration $defaultTransitionFunction;

    &.show {
      max-height: 230px !important;
    }
  }

  .form-row {
    margin-bottom: 14px;
  }

  .form-control-outer {
    margin-bottom: 0;
  }

  .col-form-label {
    padding: {
      top: 14px;
      bottom: 0;
      right: 9px;
    };
    line-height: 1.2;
    text-align: left;

    @include media-breakpoint-up(sm) {
      text-align: right;
    }

    @include media-breakpoint-up(md) {
      padding: {
        top: 0;
      };
    }

    @include media-breakpoint-up(lg) {
      padding: {
        top: 14px;
      };
    }
  }

  &.search-form .col-form-label {
    padding-top: 0;
  }

  .col-form-label,
  input[type="text"],
  input[type="email"],
  textarea {
    font-size: 1rem;
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea.form-control {
    border-color: $gray-400;
    border-radius: 0;
    padding: {
      top: 4px;
      bottom: 4px;
    };

    @include hover-focus {
      border-color: theme-color(primary);
      box-shadow: none;
    }
  }

}

.modal-figure-wrapper {
  max-width: 400px;
  margin: 6px auto 0;
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

// .modal-form-content-col{
//   @include media-breakpoint-up(lg){
//     margin:{
//       left:-($grid-gutter-width / 2);
//       // right:-($grid-gutter-width / 2);
//     };
//   }
// }

.modal-form-title {
  color: var(--dark);
  font-size: 1.5rem;
}

.modal-disclaimer {
  margin-top: 16px;
  padding: {
    left: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2);
  };
  opacity: 0;
  font-size: .875rem !important;
  line-height: 1.2;
  transition: opacity $modalTransitionDuration $modalTransitionFunction;

  .modal.show & {
    opacity: 1;
  }
}

.search-modal {
  width: 100%;
  margin: 0 auto;
  transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  right: auto;
  transform: translate3d(-50%, 0, 0);
  opacity: 0;

  .modal-open & {
    overflow-x: visible;
    overflow-y: visible;
  }

  &.show {
    opacity: 1;
  }

  .form-group {
    margin-bottom: 0;
  }

  .modal-content {
    background-color: rgba(0, 0, 0, 0);
  }

}

.modal-form {
  font-size: 1.125rem;

  .modal-dialog-content {
    padding-top: 40px;

    @include media-breakpoint-up(md) {
      padding-top: 4vw;
    }

    .modal-step-ty {
      top: 20%;
    }
  }

  .art-name {
    margin-bottom: 20px;
    color: var(--dark);
    font-size: 1.5rem;
    text-transform: uppercase;

    .work-title {
      width: 100%;
      display: inline-block;
      color: var(--dark);
      font-size: 1.25rem;
    }
  }

  .price-info {
    margin-bottom: 24px;
  }
}

.work-presentation-modal {
  .art-presentation-outer {
    height: 100vh;
    margin-bottom: 0;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition: opacity $defaultTransitionDuration $defaultTransitionFunction ($defaultTransitionDuration / 2);
  }

  .art-modal-presentation {
    margin-bottom: 0;

    .grid-item-inner {
      height: 100vh;
      max-height: 100vh !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .owl-nav {
    top: 50%;
    bottom: auto;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
  }

  .art-thumbs {
    width: 100%;
    height: 150px;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &.show {
      .art-thumbs-list {
        top: 0;
      }
    }
  }

  .art-thumbs-list {
    max-width: 600px;
    height: 150px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 45px;
    position: absolute;
    top: 150px;
    bottom: auto;
    left: 0;
    right: 0;
    transition: top ($defaultTransitionDuration * 2) $defaultTransitionFunction;
    z-index: 0;

    &:before {
      width: 1200px;
      margin-left: -600px;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: auto;
      background-color: rgba($white, .5);
      content: "";
      z-index: -1;
    }
  }

  .art-thumb-item {
    border-top-width: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  &.show {
    .art-presentation-outer {
      opacity: 1;
    }
  }
}

.contact-form-modal {

  .modal-form-container {
    margin: {
      left: -($grid-gutter-width / 2);
      right: -($grid-gutter-width / 2);
    };
  }

  .modal-dialog-inner {
    pointer-events: none;
  }

  .modal-step.show {
    pointer-events: none;
  }

  .contact-form-title {
    color: theme-color(secondary);
    font-size: 1.5rem;
  }

}
