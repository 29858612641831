.carousel-indicators-wrapper {
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 15;
  pointer-events: none;

  .carousel-indicators {
    margin-left: auto;
    margin-right: 0;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    transform: translateY(0);
    pointer-events: all;
  }
}

.carousel-indicators {
  width: $carousel-indicator-width;
  margin-bottom: 0;
  top: 50%;
  left: auto;
  flex-direction: column;
  transform: translateY(-60%);

  .carousel-indicator {
    width: 12px;
    height: 12px;
    margin-bottom: 8px;
    border: 1px solid theme-color(primary);
    flex: 0 0 auto;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;

    @include hover-focus {
      background-color: $carousel-indicator-hover-bg;
    }

    &.active {
      background-color: $carousel-indicator-active-bg;
    }

    &:before,
    &:after {
      height: 4px;
    }

    &:before {
      top: -4px;
      bottom: auto;
    }

    &:after {
      top: auto;
      bottom: -4px;
    }
  }

}
