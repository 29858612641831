html:not([data-scroll='0']) {

  @include media-breakpoint-up(lg) {

    .submenu {
      padding-top: var(--submenuPaddingTopAfterScroll) !important;
      background-color: map-get($submenu, bgOnFixed);

      .full-width-underlay {
        margin-top: var(--submenuPaddingTopAfterScroll);
        @include media-breakpoint-down(md) {
          margin-top: 30px;
          height: calc(100% - 35px);
          background-color: transparent;
          color: var(--menuItemFontColor);
        }
      }

      @include media-breakpoint-up(xl) {
        padding-top: 4px;
      }

      &::before {
        height: 25px;
        z-index: -1;

        @include media-breakpoint-up(xl) {
          height: 21px;
        }
      }
    }

    .submenu-list {
      .submenu-link {
        white-space: nowrap;
        color: map-get($submenuLink, colorOnFixed);

        @include hover-focus {
          color: map-get($submenuLink, titleColorOnFixedHover);
        }
      }
    }

    .submenu-featured-link {
      &,
      .submenu-featured-dates {
        color: var(--submenuFontColor);

        @include hover-focus {
          color: var(--secondary);
        }
      }

      .submenu-featured-title {
        color: var(--submenuFontColor);
      }
    }

    .submenu-featured-cat {
      color: var(--submenuFontColor);
    }
  }
}

.navbar-nav {
  .nav-item {
    position: relative;
    perspective: 1300px;
  }
}

.submenu {
  display: block;
  padding-top: 0 !important;

  .full-width-underlay {
    position: absolute;
    top: 0;
    left: -100vw;
    width: 200vw;
    height: 100%;
    margin-top: var(--submenuPaddingTop);
    background-color: var(--submenuUnderlayBackgroundColor);
    display: var(--submenuUnderlayDisplay);

    @include media-breakpoint-down(md) {
      margin-top: 30px;
      height: calc(100% - 35px);
      background-color: transparent;
      color: var(--menuItemFontColor);
    }
  }
}

.submenu-list {
  padding: 0;
  margin-bottom: 6px;

  .submenu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .submenu-link {
    padding: 0 10px !important;
    color: var(--submenuFontColor) !important;
    flex-grow: 1;

    transition: background-color .5s, color .5s;
    @include media-breakpoint-down(md) {
      color: var(--menuItemFontColor) !important
    }
    //@include hover-focus{
    //  color:map-get($submenuLink, colorMobileHover);
    //}
    &:hover, .active {
      background: var(--submenuItemBackgroundColor) !important;
      color: var(--submenuItemFontColor) !important;
      font-weight: var(--submenuItemFontWeight) !important;
      text-decoration: var(--submenuItemDecoration) !important;
    }
  }
}

.submenu-featured-wrapper {
  display: none;
}

.navbar-nav {
  @include media-breakpoint-up(lg) {
    .nav-item {
      z-index: 2;
    }
  }

  .nav-item.with-submenu {
    @include media-breakpoint-up(lg) {
      z-index: 1;

      // &:before{
      //   height:auto;
      //   display:block;
      //   background-color:rgba($white, 0);
      //   position:absolute;
      //   top:-150px; bottom:-15px;
      //   left:-10px; right:-100vw;
      //   z-index:1;
      //   transition:bottom $headerTransitionDuration $headerTransitionFunction,
      //              background-color ($headerTransitionDuration * 2) $headerTransitionFunction;
      //   pointer-events:none;
      //   content:"";
      // }

      .nav-link {
        z-index: 1;
      }
    }

    // @include media-breakpoint-up(xl){
    //   &:before{
    //     bottom:-36px;
    //   }
    // }
  }
}

@include media-breakpoint-down(md) {

  .submenu {
    background-color: map-get($submenu, bgMobile);
    transition: max-height 300ms ease-in;
    overflow: hidden;

    &.closing,
    &.hide {
      max-height: 0;
    }

    &.opening,
    &.show {
      max-height: 1000px;
    }

    .submenu-link {
      font-size: 1.1rem;
      text-transform: lowercase;

      @include hover-focus {
        color: map-get($submenuLink, colorMobileHover);
      }
    }
  }

}

@include media-breakpoint-up(lg) {

  .submenu {
    margin: 0;
    padding: {
      top: var(--submenuPaddingTop) !important;
      left: var(--submenuPaddingLeft) !important;
      bottom: var(--submenuPaddingBottom);
    };
    border-radius: 2px;
    background-color: var(--submenuBackgroundColor) !important;
    visibility: hidden;
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    right: auto;
    opacity: 0;
    box-shadow: map-get($submenu, boxShadow);
    // box-shadow:0 0 4px 1px rgba(0,0,0,.25);
    transform-origin: 0 0;
    transform-style: preserve-3d;
    transition: padding $headerTransitionDuration $headerTransitionFunction,
    opacity $headerTransitionDuration $headerTransitionFunction,
    background-color $headerTransitionDuration $headerTransitionFunction;
    list-style-type: none;
    text-align: left;
    text-transform: none;

    .main-at-top & {
      background-color: map-get($submenu, bgAtTop);
    }

    &.closing,
    &.show {
      transition-delay: 300ms;
    }

    &.closing,
    &.show,
    &.opening {
      visibility: visible;
    }

    &.show,
    &.opening {
      opacity: 1;
    }

    &.closing,
    &.hide {
      pointer-events: none;
    }
  }

  .submenu-row {
    display: flex;
    flex-direction: row;
  }

  .submenu-list {
    min-width: 175px;
    margin: {
      top: 0;
      bottom: 0;
    };
    padding: 38px 0 0 0;
    flex: 0 0 162px;
    list-style: none;
    text-align: left;

    .submenu-link {
      padding: {
        top: 4px;
        bottom: 2px;
        left: 20px;
        right: 20px;
      };
      display: inline-block;
      color: var(--submenuFontColor) !important;
      font-size: 1.125rem;
      text-transform: none;
      white-space: nowrap;

      @include hover-focus {
        color: map-get($submenuLink, colorHover);
      }
      // @include bold-on-hover-shadow(theme-color(primary));

      .main-at-top & {
        color: map-get($submenuLink, colorAtTop);

        @include hover-focus {
          color: map-get($submenuLink, colorAtTopHover);
        }
      }
    }
  }

  .submenu-featured-wrapper {
    width: 282px;
    padding: {
      top: 7px;
      left: 12px;
    };
    display: block;
    flex: 0 0 282px;
    position: relative;
  }
  .auctions-wrapper {
    display: flex;
    flex-direction: column;
    z-index: 9;

    .list {
      display: flex;
      flex-direction: var(--auctionsSubmenuFlexDirection);
      padding-right: 30px;
    }

    .submenu-featured-wrapper {
      flex: auto;
      width: 172px;
    }

    > .submenu-featured-cat {
      padding-left: 13px;
      padding-top: 20px;
      margin-bottom: 0;
    }
  }

  .submenu-featured-block {
    max-width: 220px;
  }

  .submenu-featured-cat,
  .submenu-featured-link,
  .submenu-featured-title,
  .submenu-featured-dates {
    display: block;
    word-break: break-word;
  }

  .submenu-link {
    padding: {
      top: 13px;
      bottom: 13px;
    };
  }

  .submenu-featured-link {
    max-width: 160px;

    &,
    .submenu-featured-title,
    .submenu-featured-dates {
      color: var(--submenuFontColor);

      @include hover-focus {
        color: var(--secondary)
      }

      .main-at-top & {
        @include hover-focus {
          color: var(--secondary) !important;
        }
      }
    }

    &,
    .submenu-featured-title {
      color: var(--submenuFontColor);

      .main-at-top & {
        color: var(--submenuFontColor);
      }
    }
  }

  .submenu-featured-cat {
    margin-bottom: 8px;
    color: var(--submenuFontColor);
    font-size: .75rem;
    text-transform: uppercase;

    @include hover-focus {
      color: var(--secondary);
    }

    .main-at-top & {
      color: var(--submenuFontColor);

      @include hover-focus {
        color: var(--secondary);
      }
    }
  }

  .submenu-featured-figure {
    max-width: 160px;
    height: auto;
    max-height: 102px;
    margin-bottom: 15px;
    display: block;
  }

  span.submenu-featured-figure {
    height: 0;
    padding-bottom: 63.75%;
    background: no-repeat 50% 50% / cover;
  }

  .submenu-featured-title {
    margin-bottom: 6px;
    font-size: 1.125rem;
    line-height: 1.2;
  }

  .submenu-featured-dates {
    font-size: .9rem;
  }

}
