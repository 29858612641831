.search-results {
  .navbar {
    border-bottom-width: 0;
  }

  .site-main {
    min-height: 42vh;
    padding-bottom: 175px;
  }

  &.no-search-results {
    .site-main {
      padding-bottom: 152px;
    }
  }

  .page-title {
    margin: {
      top: 30px;
      bottom: 22px;
    };
    color: theme-color(dark);
    font-size: 2rem;
    text-transform: none;
  }

  .nav-tabs {
    .nav-link {
      @include hover-focus {
        background-color: theme-color(primary);
      }
    }
  }

  .tab-content,
  .tab-pane {
    padding-bottom: 0;
  }

  .exhibitions-list,
  .masonry-grid {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 90px;
  }

  .row > .masonry-grid,
  .row > .exhibitions-list {
    padding: {
      left: ($grid-gutter-width / 2);
      right: ($grid-gutter-width / 2);
    };
  }

  .recommended-items-section {
    padding-top: 24px;
    padding-bottom: 0;

    .owl-nav.wrapper-container {
      top: 33%;
    }
  }

  .carousel-home-mid {
    padding-top: 64px;
    padding-bottom: 150px;
  }

  .masonry-grid-item-link {
    text-align: left;
  }

  .news-row {
    margin-bottom: 0;
    padding: {
      top: 20px;
      bottom: 20px;
    };
    position: relative;

    @include media-breakpoint-up(lg) {
      &:after {
        display: block;
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: 15px;
        right: 15px;
        box-shadow: $hoverBoxShadow;
        z-index: 0;
        opacity: 0;
        pointer-events: none;
        content: "";
      }

      .news-figure-wrapper {
        margin-bottom: 0;
        padding-left: $grid-gutter-width;
      }

      .news-main-content {
        padding-right: $grid-gutter-width;
      }
    }

    @include media-breakpoint-up(xl) {
      .news-figure-wrapper {
        flex: 0 0 41.46%;
        max-width: 510px;
      }

      .news-main-content {
        flex: 0 0 58.54%;
        max-width: 720px; // 1230 - 510
      }
    }

    @include hover-focus {
      &:after {
        opacity: 1;
      }

      .news-main-footer {
        opacity: 1;
      }
    }
  }
}
