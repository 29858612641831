.big-image-wrapper {
  min-height: 450px;
  background: no-repeat 50% 50%;
  background-size: cover;
  position: relative;

  &.hero-image-fixed {
    background-attachment: fixed;
  }

  @include hoverable-dimmed(relative, before, 0.3, 0.1);
}

.big-image-content {
  width: 100%;
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 50%;
  right: auto;
  transform: translate3d(-50%, -50%, 0);
  color: $white;
  text-align: center;
  text-transform: uppercase;

  @include make-container-max-widths();

  .big-image-title,
  .big-image-text {
    color: inherit;
    font-weight: 300;
  }

  .big-image-title {
    font-size: 2.25rem;

    a, a:hover, a:focus, a:visited {
      color: inherit;
    }
  }

}
