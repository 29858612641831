.single-auction-work {
  .site-main {
    padding-bottom: 112px;
  }

  .contact-us-btn-wrapper {
    margin-bottom: 38px;
  }

  .breadcrumb {
    margin-bottom: 10px;
  }

  .page-content {
    margin-bottom: 50px;

    .page-content-section {
      margin-bottom: 32px;
      font-size: 1.125rem;
    }
  }

  .art-presentation-outer {
    margin-bottom: 0;

    &.no-thumbs {
      margin-bottom: 60px;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 25px;
    }

    + .art-thumbs-outer {
      margin-top: 16px;
      margin-bottom: 60px;
    }
  }

  .art-presentation {
    margin-bottom: 0;
  }

  .images-presentation-images {

    .grid-item-inner {
      a {
        max-height: 560px;
        margin: 0 auto;
      }

      img {
        width: auto;
        max-width: 100%;
        max-height: 560px;
      }
    }

  }

  .recommendations {
    padding-top: 50px;
    padding-bottom: 40px;

    .owl-nav {
      @include media-breakpoint-only(xl) {
        max-width: 150px;
        top: -30px;
        left: auto;
      }
    }
  }

  .recommendations-title {
    margin-bottom: 15px;
  }

  .auction-droit-de-suite,
  .auction-guaranteed-price,
  .auction-conditional-sale {
    font-size: .875rem;
    line-height: 1.4;
  }

  .auction-btn-wrap {
    padding-left: 0;

    @include media-breakpoint-up(lg) {
      margin-top: 4px;
    }
  }

  .auction-info-line {
    width: 100%;
  }

  .auction-ask {
    margin-top: 16px;
    padding-left: 0;
    text-align: left;

    @include media-breakpoint-up(sm) {
      margin-top: 26px;
    }

    @include media-breakpoint-up(md) {
      max-width: 100%;
      margin-right: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 14px;
      padding-left: 10px;
    }
  }

  .auction-links-list {
    margin: 16px 0 0 0;

    @include media-breakpoint-up(sm) {
      columns: 2;
    }

    @include media-breakpoint-up(md) {
      margin: .5rem 0;
    }

    li {
      padding-top: 8px;
      position: relative;
      line-height: 1;
      text-align: left;

      &:first-child {
        padding-top: 0;
      }

      @include media-breakpoint-up(sm) {
        &:nth-child(n+1) {
          padding-top: 0;
        }

        &:nth-child(2n+2) {
          padding-top: 8px;
        }
      }

      @include media-breakpoint-up(md) {
        height: 32px;
      }

      @include media-breakpoint-up(lg) {
        height: auto;
      }
    }
  }
}

.auction-work-header {
  margin-bottom: 32px;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 30px;
  }

  .page-title {
    line-height: 1.4;

    a {
      color: inherit;

      @include hover-focus {
        color: theme-color(primary);
      }
    }
  }

}

.auction-work-ref-no {
  font-size: 1.25rem;
}

.artist-name,
.auction-work-title {
  display: block;
}

.artist-name {
  font-size: 1.5rem;
}

.auction-work-title {
  color: var(--dark);
  font-size: 1.25rem;
  text-transform: none;
}

.auction-work-info {
  margin-bottom: 48px;
}

.auction-work-info-row {
  padding-bottom: 80px;
}

.auction-params {
  margin-bottom: 24px;
}

.auction-work-buttons {
  margin-bottom: 60px;

  .btn-make-order {
    width: 100%;
    max-width: 240px;
    text-transform: uppercase;
    font-size: var(--CTAFontSize);
  }
}

.auction-work-sold-info {
  margin-bottom: 0;
  color: theme-color(primary);
  font-size: 1.125rem;
  text-transform: uppercase;
}

.auction-work-asides {
  @include make-row();

  .socials-share {
    margin: 0 0 0 auto;
  }
}

.auction-work-price,
.change-currency-dropdown {
  display: inline-block;
}

.auction-work-price {
  margin-right: 18px;
}

.parent-auction-info {
  padding: 26px 0 22px;
  background-color: $gray-100;
  display: var(--previewAuctionOfferInfoDisplay);

  p {
    margin: 0;
  }
}

.parent-auction-name {
  margin-bottom: 0;
  font-size: 1.5rem;

  a {
    color: $black;

    @include hover-focus {
      color: theme-color(primary);
    }
  }
}

.parent-auction-date {
  font-size: 1.25rem;
}

.recommended-auction-number {
  display: block;
  color: $body-color;
}

.auction-contact-modal {
  .art-name {
    margin-bottom: 44px;
    line-height: 1.2;
    color: $black;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  .auction-form-checkboxes {
    @include media-breakpoint-up(sm) {
      padding-left: 25%;
    }
  }

  .auction-form-checkboxes-inner {
    text-align: justify;

    @include media-breakpoint-up(sm) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .auction-params {
    margin-bottom: 32px;
  }

  .form-check-label {
    padding-left: 24px;
    font-size: 1rem;
  }

  .form-check-input {
    margin-left: 0;
  }

  .form-check-inline {
    margin-right: 32px;

    + .form-check-inline {
      margin-left: 0;
    }

    .form-check-input {
      position: absolute;
      margin-top: .25rem;
      margin-left: -1.25rem;
    }
  }

  .form-conditions-acceptance {
    font-size: .8125rem;

    a {
      color: inherit;

      @include hover-focus {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  .modal-step-ty {
    p {
      max-width: 590px;
      margin-left: auto;
      margin-right: auto;
    }
  }

}
