@keyframes dropdownSlide {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// filtry
.items-list-filters {
  padding-top: 16px;
  padding-bottom: 0;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }

  .items-filter {
    width: 100%;
    margin-bottom: 0;
    font-size: 1rem;
    z-index: 1;

    @include media-breakpoint-up(sm) {
      width: 50%;
    }

    @include media-breakpoint-up(md) {
      width: auto;
      font-size: 1.125rem;
    }
  }

  .items-filter-tag {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    line-height: 1;

    @include media-breakpoint-up(md) {
      width: auto;
      padding-left: 24px;
      padding-right: 24px;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .input-group {
    padding-top: 0;
    padding-bottom: 0;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }

    &:hover {
      .search-phrase,
      .search-submit {
        border-color: theme-color(primary);
      }
    }
  }

  .search-phrase,
  .search-submit {
    height: 34px;
    border-color: theme-color(secondary);
    border-style: solid;
    border-radius: 0;
  }

  .search-phrase {
    padding-top: 4px;
    padding-bottom: 4px;
    border-width: 1px 0 1px 1px;

    @include hover-focus {
      border-color: theme-color(primary);

      & + .search-submit {
        border-color: theme-color(primary);
      }
    }
  }

  .search-submit {
    border-width: 1px 1px 1px 0;
    background-color: $white;
    background-size: 14px 14px;

    @include hover-focus {
      border-color: theme-color(primary);
    }
  }

  .items-sort-dropdown {
    padding-top: 12px;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }
  }
}

.advanced-filters,
.advanced-filters-collapse {
  position: relative;
}

.items-advanced-filters {
  z-index: 3;
}

.advanced-filters-row {
  position: relative;
}

.advanced-btn-wrapper {
  width: 100%;
  position: relative;
  z-index: 2;
  pointer-events: none;
  min-height: 40px;
}

.advanced-btn {
  width: 100%;
  margin: 0 auto;
  padding: 0 ($grid-gutter-width / 2);
  text-align: right;

  @include make-container-max-widths();
}

.advanced-filters {
  border-bottom-width: 0;
  text-align: right;
  z-index: 10;
}

.advanced-filters-collapse {
  z-index: 1;

  &.show {
    z-index: 3;
  }
}

.active-filters {
  font-size: 1rem;
}

.active-filters-advanced {
  margin-top: -2px;
  border-bottom: 0;
}

.active-filters-set {
  border-bottom-width: 0;
  padding-top: 0;
  padding-bottom: 0;

  .active-filter-item {
    margin: 4px 6px;
    padding: 5px 34px 5px 8px;

    &:first-child {
      margin-top: 6px;
    }

    &:last-child {
      margin-bottom: 6px;
    }
  }
}

.dropdown-item {
  font-size: 1rem !important;

  &.hide {
    display: none;
  }

  &.no-results-info {
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 1rem !important;

    &:before,
    &:after {
      display: none !important;
    }
  }
}

.items-range-filter {
  .dropdown-item {
    @include hover-focus {
      background-color: $white;
      color: theme-color(secondary);
    }
  }
}

.btn-advanced-filter {
  position: relative;
  color: var(--dark);
  font-size: .875rem;
  z-index: 1 !important;
  pointer-events: all;

  &.collapsed {
    &:after {
      content: " +";
    }
  }

  // coś zmieniło się w Bootstrapie, przycisk w spoczynku ma klasę "collapsed"
  // &.open{
  //   &:after{
  //     content:" -";
  //   }
  // }

  @include hover-focus {
    color: var(--secondary);
    background-color: theme-color(primary);
  }

  &:after {
    content: " -";
  }
}

.remove-advanced-filters {
  margin: 0 auto 0;
  padding: 0 ($grid-gutter-width / 2) 10px;
  text-align: right;
  pointer-events: none;

  @include make-container-max-widths();

  .btn {
    padding: 4px 6px;
    display: inline-block;
    font-size: 1rem;
    line-height: 1;
    pointer-events: all;
  }

  .btn-close {
    margin-left: 8px;
    padding: 0 2px;
    display: inline-block;
    font-size: 1.75rem;
    line-height: .65;
    vertical-align: sub;
  }
}

.items-filters {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  display: var(--showArchiveWorksButton);

  @include media-breakpoint-up(md) {
    margin-left: -($grid-gutter-width / 2);
    text-align: left;
  }

  @include media-breakpoint-up(lg) {
    margin-left: 0;
    text-align: left;
  }
}

.items-filter {
  margin: 0 -2px 2px;
  display: inline-block;
  font-size: 1.125rem;
}

.items-filter-group {

  &.items-filter-group-collapsible {
    display: block;
  }

  &.items-currencies-group {
    width: 140px;

    .items-filter-menu {
      min-width: 0;
      width: 140px;
    }
  }

  .btn {
    padding-right: 30px;
    font-size: 1.125rem;

    .simple-filters-group & {
      font-size: 1rem;
    }

    @include hover-focus {
      color: theme-color(secondary);
      text-decoration: none;

      &:after {
        background-position: 0 -6px;
      }
    }

    &:after {
      width: 12px;
      height: 6px;
      border-width: 0;
      display: block;
      position: absolute;
      top: 14px;
      bottom: auto;
      left: auto;
      right: 10px;
      background: url("../images/dropdown-arrow.png") no-repeat 0 0;
      transform: rotate(0);
      transition: transform $defaultTransitionDuration $defaultTransitionFunction;
      z-index: ($zindex-dropdown + 1);
    }
  }

  &.show {
    background-color: theme-color(primary);

    .btn {
      color: var(--secondary) !important;

      @include hover-focus {
        color: var(--secondary) !important;
        text-decoration: none;
      }

      &:after {
        background-position: 0 -6px;
        transform: rotate(-180deg);
        border-color: var(--secondary) !important;
      }
    }
  }
}

.simple-filters-group {
  padding: 12px 0;

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.active-filters .active-filter-advanced-wrapper {
  @include media-breakpoint-up(lg) {
    max-width: 20%;
  }
}

.advanced-filters-content {
  .advanced-filter-wrapper {
    @include media-breakpoint-up(lg) {
      max-width: 20%;
    }
  }

  .items-filter-group {
    margin-top: 16px;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      margin-top: 6px;
      margin-bottom: 7px;
    }
  }

  .items-filter-group {
    width: 100%;
  }

  .dropdown-menu {
    min-width: 100%;
  }

  .btn {
    width: 100%;
    border: 1px solid theme-color(light);
    color: theme-color(secondary);
    text-align: left;
    cursor: pointer;
  }
}

.items-filter-tag {
  padding: 8px 24px;
  display: inline-block;

  .active & {
    background-color: theme-color(primary);
  }

  @include hover-focus {
    .items-filter-tag-btn {
      color: $white;
    }
  }

}

.items-filter-tag,
.items-filter-tag-btn {
  position: relative;
  color: var(--dark);
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  @include hover-focus {
    background-color: theme-color(primary);
    color: var(--secondary) !important;
    text-decoration: none;
  }

  .active & {
    color: var(--secondary) !important;

    .active-filter-item-close {
      width: 18px;
      right: 8px;
      padding: 0 2px;
    }
  }

  .active-filter-item-close {
    color: var(--secondary);
    vertical-align: bottom;
  }
}

.items-filter-dropdown {
  text-align: center;

  @include media-breakpoint-up(md) {
    margin-right: -($grid-gutter-width / 2);
    margin-left: auto;
    text-align: right;
  }
}

.items-filter-selectable {
  .dropdown-item {
    @include hover-focus {
      background-color: theme-color(primary);
      color: $white;
      cursor: pointer;
    }
  }
}

.items-filter-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  color: theme-color(secondary);
  line-height: 1;
  cursor: pointer;
  z-index: 10;

  @include hover-focus {
    color: theme-color(secondary);
    text-decoration: none;
  }
}

.items-filter-menu {
  height: 0;
  max-height: 0;
  padding: 1px 0;
  border-radius: 0;
  display: block;
  overflow: hidden;
  visibility: hidden;
  transition: max-height ($defaultTransitionDuration * 2) $defaultTransitionFunction,
  opacity $defaultTransitionDuration $defaultTransitionFunction;
  text-align: left;
  opacity: 0;
  pointer-events: none;

  &.show {
    height: auto;
    max-height: 400px;
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
}

.items-checkbox-menu {

  .dropdown-item {
    padding-left: 40px;
    position: relative;

    @include hover-focus {
      &:before,
      &:after {
        border-color: $white;
      }
    }

    &:after,
    &:before {
      height: 12px;
      display: block;
      position: absolute;
      top: 50%;
      bottom: auto;
      right: auto;
      content: "";
    }

    &:before {
      width: 12px;
      margin-top: -6px;
      border: 1px solid theme-color(secondary);
      left: 14px;
    }

    &:after {
      width: 6px;
      margin-top: -8px;
      border-width: 0 2px 2px 0;
      border-color: theme-color(primary);
      border-style: solid;
      left: 18px;
      opacity: 0;
      transform: rotate(30deg);
    }

    &.checked:after {
      opacity: 1;
    }
  }
}

.items-range-filter {
  .dropdown-item {
    padding-top: 12px;
    padding-bottom: 28px;
  }
}

.slider-range-displays {
  display: block;
  text-align: center;
}

.active-filter-header {
  margin-right: 16px;
  margin-bottom: 0;
  display: inline-block;
  color: theme-color(secondary);
  font-size: 1.125rem;
  line-height: 1;
  text-transform: uppercase;
}

.active-filter-item,
.active-filter-item-text {
  display: inline-block;
  color: var(--secondary);
}

.active-filter-item {
  padding: 5px 8px;
  background-color: theme-color(light);
  position: relative;
}

.active-filter-item-close {
  width: 0;
  height: 18px;
  padding: 0;
  margin-left: 0;
  position: absolute;
  top: 9px;
  bottom: auto;
  left: auto;
  right: 24px;
  display: block;
  overflow: hidden;
  transition: width $defaultTransitionDuration $defaultTransitionFunction, right $defaultTransitionDuration $defaultTransitionFunction;
  color: var(--secondary);
  font-size: 1.75rem;
  line-height: .65;
  vertical-align: middle;

  @include hover-focus {
    background-color: theme-color(primary);
    color: $white;
    cursor: pointer;
  }

  .active > & {
    width: 18px;
    right: 8px;
    padding: 0 2px;
  }
}

.filter-search {
  display: none;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
  z-index: -1;

  .items-filter-group:hover &,
  .items-filter-group:focus & {
    display: block;
  }

  .items-filter-group.show & {
    display: block;
    opacity: 1;
    z-index: $zindex-dropdown;
  }

  .filter-search-input {
    width: 100%;
    padding: 2px 10px;
    border-width: 1px;
    border-style: solid;
    border-color: theme-color(light) theme-color(light) rgba(theme-color(light), 0) theme-color(light);
    display: block;
    position: relative;
  }
}

// filtr wyszukiwania po nazwie
$itemsSearchInputColor: #999;

.items-search-label {
  width: 100%;
  position: relative;
  color: $itemsSearchInputColor;

  @include media-breakpoint-up(lg) {
    max-width: 267px;
  }

  &:after {
    width: 12px;
    height: 7px;
    display: block;
    position: absolute;
    top: 20px;
    bottom: auto;
    left: auto;
    right: 16px;
    background: url("../images/dropdown-arrow.png") no-repeat 0 0;
    transform: rotate(-90deg);
    transition: transform $defaultTransitionDuration $defaultTransitionFunction;
    content: "";
    z-index: 3;
  }

  @include hover-focus {
    &:after {
      transform: rotate(0);
    }
  }

  &.focused {
    &:after {
      transform: rotate(0);
    }
  }
}

.btn-dropdown-toggle {
  width: 46px;
  border: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  text-indent: -9999px;
  cursor: pointer;
}

.items-search-background-text,
.items-search-field {
  padding: 8px 12px 9px;
}

.items-search-background-text {
  border-radius: 2px;
  display: block;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background-color: $white;
  z-index: 2;
}

.items-search-field {
  border-color: $gray-400;
  border-radius: 2px;
  position: relative;
  z-index: 1;

  &::placeholder {
    color: rgba($itemsSearchInputColor, 0);
  }

  @include hover-focus {
    border-color: theme-color(primary);
    z-index: 2;
    box-shadow: none;

    &::placeholder {
      color: rgba($itemsSearchInputColor, 1);
    }
  }
}

.items-search-list {
  min-width: 100%;
  border: 1px solid rgba($itemsSearchInputColor, 0);
  background-color: #fff;
  opacity: 0;
  transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
  z-index: 10;

  &.show {
    border-color: rgba($itemsSearchInputColor, 1);
    opacity: 1;
  }

  &.items-filter-menu-empty {
    border-color: rgba($itemsSearchInputColor, 0);
  }
}

.items-search-item {
  display: block;
  padding: 4px 14px;
  color: theme-color(secondary);
  font-size: .8125rem;

  @include hover-focus {
    background-color: theme-color(primary);
    color: #fff;
  }
}

// filtr literowy
.filter-letters-list {
  margin-bottom: 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: theme-color(light);
  padding: 11px 0 9px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
}

.letters-filter-item {
  margin: 2px 0;
  padding: 0;
  display: block;
  line-height: 1;
}

.letters-filter-btn {
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: rgba(theme-color(primary), 0);
  font-size: 1.125rem;
  line-height: 40px;

  .current-letter-filter-item & {
    background-color: rgba(theme-color(), 1);
    color: $white;
  }

  .hover &,
  &:hover,
  &.hover {
    background-color: rgba(theme-color(), 1);
    color: $white;
  }

  @include hover-focus {
    box-shadow: none;
  }
}

// per page / na stronę
.per-page-dropdown {
  width: 69px;
  height: 23px;
  border: 1px solid theme-color(light);

  &.hidden {
    display: none;
  }

  .items-filter-btn {
    padding: 2px 24px 2px 12px;
    background-color: rgba(0, 0, 0, 0);
    color: $gray-550;
    font-size: .875rem;

    @include hover-focus {
      background-color: rgba(0, 0, 0, 0);
      color: $gray-550;
    }

    &::after {
      top: 6px;
      right: 6px;
    }
  }

  &.items-filter-group.show {
    background-color: rgba(0, 0, 0, 0);

    .btn {
      color: $gray-550;

      @include hover-focus {
        color: $gray-550;
      }
    }
  }
}
