.socials-share {
  display: inline-block;

  &.socials-aside-share {
    text-align: right;
    @include make-col-ready();
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(12);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(7);
    }
  }
}

.socials-share-header {
  margin-bottom: 0;
  display: inline-block;
  color: theme-color(primary);
  font-size: .875rem;
  line-height: 2;
  vertical-align: middle;

  @include media-breakpoint-up(sm) {
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    margin-right: 10px;
    text-align: right;
  }
}

.socials-list {
  margin: 0;
  padding: 0;
  display: inline-block;
  list-style: none;
  line-height: 1;
  vertical-align: middle;

  &.socials-list-right {
    text-align: right;
  }
}

.socials-item,
.socials-link {
  height: 30px;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;

  .socials-aside & {
    height: 25px;
  }
}

.socials-item {
  margin-right: 2px;
}

.socials-link {
  width: 30px;
  height: 30px;
  position: relative;
  background-color: var(--primary);
  font-size: 0;
  text-indent: -9999px;

  &, &:after {
    background-repeat: no-repeat;
  }

  @include hover-focus {
    &:after {
      opacity: 1;
    }
  }

  &:after {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
    content: "";
  }

  .socials-aside & {
    width: 25px;
    height: 25px;
  }
}

// .socials-facebook{
//   background-image:url("../images/socials/btn-lg-facebook.png");

//   .socials-aside &{
//     background-image:url("../images/socials/btn-facebook.png");
//   }

//   &:after{
//     background-image:url("../images/socials/btn-lg-facebook-hover.png");

//     .socials-aside &{
//       background-image:url("../images/socials/btn-facebook-hover.png");
//     }
//   }
// }

// // tylko mała wersja
// .socials-facebook-like{
//   // width:90px;
//   // background-position:-240px 0;

//   .socials-aside &{
//     width:79px;
//     background-position:-110px 0;
//   }

//   &:after{
//     // background-position:-240px -30px;

//     .socials-aside &{
//       background-position:-110px -22px;
//     }
//   }
// }

// .socials-instagram{
//   background-image:url("../images/socials/btn-lg-instagram.png");

//   &:after{
//     background-image:url("../images/socials/btn-lg-instagram-hover.png");
//   }
// }

// .socials-googleplus{
//   background-image:url("../images/socials/btn-lg-googleplus.png");

//   .socials-aside &{
//     background-image:url("../images/socials/btn-googleplus.png");
//   }

//   &:after{
//     background-image:url("../images/socials/btn-lg-googleplus-hover.png");

//     .socials-aside &{
//       background-image:url("../images/socials/btn-googleplus-hover.png");
//     }
//   }
// }

// .socials-twitter{
//   background-image:url("../images/socials/btn-lg-twitter.png");

//   .socials-aside &{
//     background-image:url("../images/socials/btn-twitter.png");
//   }

//   &:after{
//     background-image:url("../images/socials/btn-lg-twitter-hover.png");

//     .socials-aside &{
//       background-image:url("../images/socials/btn-twitter-hover.png");
//     }
//   }
// }

// .socials-pinterest{
//   background-image:url("../images/socials/btn-lg-pinterest.png");

//   .socials-aside &{
//     background-image:url("../images/socials/btn-pinterest.png");
//   }

//   &:after{
//     background-image:url("../images/socials/btn-lg-pinterest-hover.png");

//     .socials-aside &{
//       background-image:url("../images/socials/btn-pinterest-hover.png");
//     }
//   }
// }

// .socials-youtube{
//   background-image:url("../images/socials/btn-lg-youtube.png");

//   &:after{
//     background-image:url("../images/socials/btn-lg-youtube-hover.png");
//   }
// }

// // tylko mała wersja
// .socials-email{

//   .socials-aside &{
//     background-image:url("../images/socials/btn-email.png");
//   }

//   &:after{
//     // background-position:-180px -30px;

//     .socials-aside &{
//       background-image:url("../images/socials/btn-email-hover.png");
//     }
//   }
// }

// fb like
.fb-like-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
