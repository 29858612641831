.page-title {
  color: theme-color(primary);
  font-size: 1.75rem;
  text-transform: uppercase;
}

.content-section-subheader {
  padding: 0 0 10px 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: theme-color(light);
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  margin-top: 30px;
}

.page-content {
  p {
    margin-bottom: 28px;
  }
}

.page-section {
  padding-top: 40px;
  padding-bottom: 34px;
}

.article-header,
.page-header {
  font-size: 1.5rem;

  .header-small-text {
    margin-bottom: 12px;
    display: block;
    font-size: 66.6666%;
    line-height: 1;
  }
}

.header-small-text {
  color: var(--dark);
  text-transform: uppercase;
}

// aside
.aside-title {
  color: theme-color(primary);
  font-size: 1.5rem;
  text-transform: uppercase;
}
