.home-articles {
  padding-top: 60px;
  padding-bottom: 60px;

  @include media-breakpoint-up(md) {
    padding-bottom: 30px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 90px;
  }
}

.carousel-home-mid {
  position: relative;

  @include media-breakpoint-up(md) {
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);
    padding-bottom: 30px;
  }

  .owl-carousel {
    margin: 0 auto;

    @each $bPoint, $mWidth in $container-max-widths {
      @include media-breakpoint-up($bPoint) {
        max-width: $mWidth;
      }
    }
  }

  .carousel-indicators {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  .carousel-content-col {
    position: relative;
  }

  .carousel-article-header {
    margin-bottom: 18px;

    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }
  }

  .carousel-article-tag {
    color: theme-color(secondary);
    font-size: 1rem;
    text-transform: uppercase;
    display: var(--newsExhibitionLabelDisplay);
  }

  .carousel-article-title {
    min-height: 62px;
    margin: 8px 0 10px;
    line-height: 1.3333;
    padding-bottom: var(--paddingBottomNewsExhibitionTitle);
    padding-top: var(--paddingTopNewsExhibitionTitle);
    font-size: var(--newsExhibitionTitleFontSize);
    text-transform: var(--newsExhibitionTitleTextTransform);
    color: var(--newsExhibitionTitleFontColor);

    a {
      color: var(--newsExhibitionTitleFontColor);

      &:hover {
        color: var(--primary);
      }
    }

    @include media-breakpoint-up(md) {
      min-height: 0;
    }
  }

  .carousel-article-dates {
    margin: 0;
    color: theme-color(secondary);
    margin-bottom: var(--paddingBottomNewsExhibitionDate);
    display: var(--newsExhibitionDateDisplay) !important;
    font-size: var(--newsExhibitionDateFontSize);
    line-height: 1;
  }

  .carousel-article-text {
    display: none;

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }

  .carousel-article-more {
    margin-top: -56px;
    padding: 4px 24px;
    display: block;
    float: right;
    transition: background-color 300ms ease-in, color $headerTransitionDuration $headerTransitionFunction;
    color: theme-color(secondary);
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      position: absolute;
      top: auto;
      bottom: 0;
      left: auto;
      right: $grid-gutter-width;
    }

    @include victory-button();

    @include hover-focus {
      // background-color:theme-color(primary);
      // color:#fff;
      text-decoration: none;
    }
  }

  .carousel-content-col {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  .carousel-figure-col {
    padding-left: $grid-gutter-width;
  }

  .carousel-article-figure {
    margin-right: -($grid-gutter-width / 2);
  }

  span.carousel-article-figure {
    height: 0;
    padding-bottom: 75%;
    overflow: hidden;
    position: relative;
    display: block;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
