.how-to-sell {
  .page-content {
    margin-bottom: 90px;
  }
}

.how-to-sell-aside {

  @include media-breakpoint-up(lg) {
    padding-top: 4px;
  }
}

.how-to-sell-subheader {
  margin: 0 0 2px 0;
  font-size: 1.125rem;
  font-weight: 600;
}

.how-to-sell-info-list {
  margin: 0 0 18px 0;
  padding: 0;
  list-style: none;

  li {
    font-size: 1.125rem;
  }
}

.how-to-sell-aside-inner {
  padding-left: 12px;
  border-left: 2px solid theme-color(tertiary);
}
