.breadcrumb {
  margin-left: -($grid-gutter-width / 2);
  margin-right: -($grid-gutter-width / 2);
  font-size: .875rem;
  text-align: center;

  @include media-breakpoint-up(lg) {
    margin-bottom: 56px;
    text-align: left;
  }
}

.breadcrumb-item {
  a {
    color: $breadcrumb-divider-color;
  }

  &.active {
    font-weight: 600;
  }
}
