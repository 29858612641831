@import "bootstrap/mixins/hover";
@import "variables";

@mixin hoverable-dimmed($position:relative, $pseudo-element:'after', $bg-opacity:0.3, $bg-opacity-hovered:0, $transition-duration:$defaultTransitionDuration, $transition-function:$defaultTransitionFunction) {
  position: $position;

  &::#{$pseudo-element} {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, $bg-opacity);
    transition: background-color $transition-duration $transition-function;
    pointer-events: none;
    content: "";
  }

  @include hover-focus {
    &::#{$pseudo-element} {
      background-color: rgba(0, 0, 0, $bg-opacity-hovered);
    }
  }
}

@mixin hoverable-zoomed($child, $scale:1.05, $transition-duration:3s, $transition-function:$defaultTransitionFunction) {
  overflow: hidden;
  @content;

  #{$child} {
    transform: scale(1);
    transition: transform $transition-duration $transition-function;
  }

  @include hover-focus-class {
    #{$child} {
      transform: scale($scale);
    }
  }
}

@mixin hover-focus-class() {
  &:hover,
  &:focus,
  &.hover {
    @content;
  }
}

@mixin hover-focus-parent($parent) {
  #{$parent}:hover &,
  #{$parent}:focus &,
  #{$parent}.hovered & {
    @content;
  }
}

@mixin victory-button($bg-color:theme-color(primary), $color:$white, $transition-duration:$buttonTransitionDuration, $transition-function:$buttonTransitionFunction) {
  position: relative;
  overflow: hidden;
  transition-duration: $transition-duration;
  @content;

  &:after {
    width: 150%;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $bg-color;
    opacity: .5;
    transform: translateY(-50%) translateX(-50%) rotate(90deg);
    transition: all $transition-duration $transition-function 0s;
    z-index: -1;
    content: "";
  }

  @include hover-focus {
    border-color: $bg-color;
    background-color: rgba(0, 0, 0, 0);
    color: $color;

    &:after {
      opacity: 1;
      height: 800%;
    }
  }
}

@mixin bold-on-hover($pseudoElem:before, $attr:title, $color:theme-color(primary), $fontWeight:700) {

  &::#{$pseudoElem} {
    height: 0;
    display: block;
    overflow: hidden;
    visibility: hidden;
    font-weight: $fontWeight;
    text-align: center;
    content: attr($attr);
  }

  &:hover,
  &:focus,
  &.hover,
  .hover & {
    color: $color;
    font-weight: $fontWeight;
    @content;
  }

}

$boldOnHoverTextShadowOffset1: .5px 0;
$boldOnHoverTextShadowOffset2: -0.5px 0;
$boldOnHoverTextShadowOffset3: 0 -0.5px;

@mixin bold-shadow($color) {
  color: $color;
  text-shadow: #{$boldOnHoverTextShadowOffset1} #{$color}, #{$boldOnHoverTextShadowOffset2} #{$color}, #{$boldOnHoverTextShadowOffset3} #{$color};
}

@mixin bold-on-hover-shadow($color:theme-color(primary)) {
  transition: color 200ms ease-in-out,
  text-shadow 200ms ease-in-out;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);

  &:hover,
  &:focus,
  &.hover,
  .hover & {
    @include bold-shadow($color);
    @content;
  }

}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
