.mCSB_inside {
  > .mCSB_container {
    margin-right: 0;
  }
}

.mCSB_scrollTools {
  .mCSB_dragger {
    width: 10px;
    height: 8px;
    background-color: theme-color(primary);
    right: 4px;

    .mCSB_dragger_bar {
      visibility: hidden;
    }
  }
}
