.single-work {
  .site-main {
    padding-bottom: 68px;
  }

  .breadcrumb {
    margin-bottom: 64px;
  }

  .contact-us-btn-wrapper {
    margin-bottom: 38px;
  }

  .recommended-items-section {
    margin-top: 0;
    padding: {
      top: 0;
      bottom: 0;
    };

    @include media-breakpoint-up(xl) {
      margin-top: 0;
      padding-bottom: 0;
    }

    .owl-nav.wrapper-container {
      top: -12px;

      @include media-breakpoint-only(lg) {
        top: -16px;
      }

      @media screen and (min-width: 1350px) {
        top: 33%;
      }
    }

    .grid-item-title,
    .grid-item-text {
      color: var(--dark);
      font-size: 1.25rem;
    }
  }

  .recommendations-section-title {
    margin-bottom: 14px;
  }

  .recommendations-title {
    margin: 0;
  }

  .page-content {
    margin-bottom: 60px;
  }

  .socials-aside-share {
    max-width: 100%;
    margin: 0 0 0 auto;
    flex: 0 0 100%;
  }

  .artist-name a {
    @include hover-focus {
      color: theme-color(primary);
    }
  }

  // promowany
  .item-promotion-info {
    position: absolute;
    top: -25px;
    bottom: auto;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    font-size: 1rem;
    font-weight: 400;

    @include media-breakpoint-up(md) {
      left: auto;
      right: ($grid-gutter-width / 2);
      transform: translateX(0);
    }
  }

  .promoted-item-price {
    font-sizze: 1.25rem;
  }

  .btn-maximize-image {
    top: 2.875vw;
    left: 1vw;
  }
}

@include media-breakpoint-up(lg) {

  .work-description {
    padding-left: 25px;
  }

}

.work-header {
  text-align: center;
  margin-bottom: 0;

  @include media-breakpoint-up(md) {
    line-height: 1;
    text-align: left;
  }

  .page-title {
    line-height: 1.5;
    margin-bottom: 0;
  }
}

.work-ref-no {
  font-size: var(--fontSizeUnderTitleOnPreviewForOffer);
}

.artist-name,
.work-title {
  display: block;
  color: var(--dark);

  span, a {
    color: var(--dark);
  }
}

.artist-name {
  font-size: 1.5rem;

  a {
    @include hover-focus {
      color: darken(theme-color(primary), 15%);
      text-decoration: underline;
    }
  }
}

.work-title {
  color: var(--dark);
  font-size: 1.25rem;
  text-transform: none;

  &.offer-page {
    font-size: var(--fontSizeTitleOnPreviewForOffer);
  }
}

.work-info {
  margin-bottom: 36px;
  font-size: var(--fontSizeUnderTitleOnPreviewForOffer);
}

.work-info-row {
  padding-bottom: 60px;
}

.work-sold-info {
  margin-bottom: 18px;;
  color: theme-color(primary);
  font-size: var(--CTAFontSize);
  text-transform: uppercase;
}

.work-asides {
  @include make-row();
}

.work-sold-container {
  display: flex;
  justify-content: space-between
}

.work-price-aside {
  display: flex;
  justify-content: flex-start;
  @include make-col-ready();
  @include make-col(12);

}

.work-sold-container,
.work-price-aside {

  .droit-de-suite {
    display: flex;
    align-self: flex-end;
    width: 238px;
  }
}

.work-price,
.change-currency-dropdown {
  display: inline-block;
}

.change-currency-menu {
  min-width: 5rem;
}

.work-price {
  margin-right: 18px;
  font-size: 1.125rem;
}

// owl
.art-presentation-outer {
  margin-bottom: 18px;
  pointer-events: all;
}

.images-presentation {
  // margin:{
  //   top:0;
  //   bottom:0;
  //   left:-($grid-gutter-width / 2);
  //   right:-($grid-gutter-width / 2);
  // };
  margin: 0;
  opacity: 0;
  transition: opacity 300ms ease-in-out 300ms;

  &--loaded {
    opacity: 1;
  }
}

.images-presentation-images {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;

  @include media-breakpoint-between(md, lg) {
    margin-right: -($grid-gutter-width / 2);
  }

  .art-presentation-outer,
  .art-thumbs-outer {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .art-thumbs-outer {
    margin-bottom: 33px;
    // border-width:1px 0;
    // border-color:theme-color(light);
    // border-style:solid;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .artist-art-thumbs {
    width: auto;

    @include media-breakpoint-between(md, lg) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.images-presentation-zoom {
  position: relative;
  z-index: 10;
}

.art-presentation {
  width: 100%;
  max-width: 100%;

  // .owl-slide{
  //   background-color:theme-color(light);
  // }

  .grid-item-inner {
    // height:480px;
    position: relative;
    text-align: center;
    white-space: nowrap;

    a {
      width: 100%;
      display: block;
    }

    // @include media-breakpoint-up(md){
    //   height:660px;
    // }
    //
    // @include media-breakpoint-up(lg){
    //   height:424px;
    // }
    //
    // @include media-breakpoint-up(xl){
    //   height:560px;
    // }

    // &:before{
    //   height:100%;
    //   margin-left:-6px;
    //   display:inline-block;
    //   vertical-align:middle;
    //   content:"";
    // }
  }

  .grid-figure {
    width: auto !important;
    max-width: 100%;
    height: auto;
    max-height: 460px;
    margin: 0 auto;
    display: block;
    vertical-align: middle;

    .modal & {
      max-height: 100vh;
    }

    // @include media-breakpoint-up(md){
    //   max-height:660px;
    // }

    // @include media-breakpoint-up(lg){
    //   max-height:424px;
    // }

    // @include media-breakpoint-up(xl){
    //   max-height:560px;
    // }
  }
}

.art-thumbs {
  width: 100%;
  margin-top: 16px;
  padding: 0;
  list-style: none;
}

.art-thumbs-list {
  margin: 0;
  padding: 0 8px;
  list-style: none;
  text-align: left;

  &.owl-carousel {
    padding-left: 44px;
    padding-right: 44px;
  }

  &.art-thumbs-modal-list {
    padding-left: 0;
    padding-right: 0;
  }

  .owl-item {
    text-align: center;
  }

  .owl-nav {
    margin: {
      top: 0;
      left: 0;
      right: 0;
    };
    top: 50%;
    transform: translateY(-50%);

    @include media-breakpoint-up(xl) {
      margin: {
        left: -28px;
        right: -28px;
      };
    }

    .owl-prev,
    .owl-next {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: rgba(theme-color(primary), 0);
      transition: background-color $defaultTransitionDuration $defaultTransitionFunction;

      @include hover-focus {
        background-color: rgba(theme-color(primary), 1) !important;
      }

      &:after {
        background-size: 60px 60px;
      }

      @include hover-focus {
        &:after {
          background-position: 0 -30px;
        }
      }
    }

    .owl-prev {
      &:after {
        background-position: 0 0;
      }
    }

    .owl-next {
      &:after {
        background-position: -30px 0;
      }

      @include hover-focus {
        &:after {
          background-position: -30px -30px;
        }
      }
    }
  }

}

.art-thumb-item {
  margin: 0 -8px;
  padding: 7px 16px;
  border-top: 1px solid $white;
  display: inline-block;
  background-color: var(--mainBackground);

  &.owl-slide {
    height: 65px;
    margin: 16px 0;
    padding: 0;

    .art-thumbs-modal-list & {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.art-thumb-link {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0) no-repeat 50% 50%;
  background-size: cover;
  overflow: hidden;
  position: relative;

  &:before {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .25);
    transition: background-color $defaultTransitionDuration $defaultTransitionFunction;
    content: "";
  }

  &:hover, &:focus {
    &:before {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  img {
    width: auto;
    height: 100%;
    margin: 0 auto;
    display: inline-block;
  }
}
