.news {
  .site-main {
    padding-top: 18px;
  }

  .items-list-filters {
    padding: 12px 0;
  }

  .items-filter-tag {
    padding-left: 30px;
    padding-right: 30px;
    transition: padding $defaultTransitionDuration $defaultTransitionFunction;
    line-height: 1;
  }

  .active .items-filter-tag {
    padding-left: 18px;
    padding-right: 42px;
  }

  .items-filter {
    margin-bottom: 0;
  }

  .search-results {
    margin-top: 40px;
  }

  .sort-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.news-container {
  padding-top: 30px;
  padding-bottom: 148px;

  &.with-pagination {
    padding-bottom: 60px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 108px;
    }
  }

  .list-news-wrapper {
    @include media-breakpoint-up(sm) {
      width: 50%;
    }

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    @include media-breakpoint-up(lg) {
      width: 33.3333%;
    }

    @include media-breakpoint-up(xl) {
      width: 33.3333%;
    }
  }
}

.list-news-wrapper {
  .news-image-link {
    width: 100%;
    display: inline-block;

    @include hoverable-dimmed();
    @include hoverable-zoomed("img");

    &::after {
      display: var(--sliderLayerDisplay);
    }
  }

  .news-figure {
    width: 100%;

    .news & {
      margin-bottom: 0;
    }
  }
}

.news-list {
  margin: {
    left: -($grid-gutter-width / 2);
    right: -($grid-gutter-width / 2);
  };
  padding-bottom: 58px;

  @include media-breakpoint-up(md) {
    margin: {
      left: 0;
      right: 0;
    };
    padding-bottom: 126px;
  }

  .masonry-grid-item:hover {
    background-color: var(--secondaryBackground);
  }
}

.news-figure {
  margin: 0 auto 24px;
}

.news-tag {
  margin-bottom: 12px;
  display: var(--newsExhibitionLabelDisplay);
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
}

.news-title {
  padding-bottom: var(--paddingBottomNewsExhibitionTitle);
  padding-top: var(--paddingTopNewsExhibitionTitle);
  font-size: var(--newsExhibitionTitleFontSize);
  text-transform: var(--newsExhibitionTitleTextTransform);
  color: var(--newsExhibitionTitleFontColor);

  a {
    color: var(--newsExhibitionTitleFontColor);
  }
}

.news-time {
  margin-bottom: var(--paddingBottomNewsExhibitionDate);
  display: var(--newsExhibitionDateDisplay) !important;
  font-size: var(--newsExhibitionDateFontSize);
  line-height: 1;
}

.news-excerpt {
  font-size: 1.125rem;
}
