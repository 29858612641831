$defaultTransitionDuration: 300ms;
$defaultTransitionFunction: ease-in-out;
$defaultTransitionDurationSlow: $defaultTransitionDuration * 2;

$buttonTransitionDuration: 500ms;
$buttonTransitionFunction: $defaultTransitionFunction;

$owl-image-path: "../images/";

$invalidFieldColor: #f23;

// Colors
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-550: #999999;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  550: $gray-550,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
);

$blue: #007bff;
$indigo: #6610f2;
$golden: #b99667;
;
$dark-blue: #024c7d;
$darker-blue: #2b414f;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$theme-colors: (
  primary: rgba(#c0392b, 1),
  secondary: rgba(51, 51, 51, 1),
  tertiary: rgba(51, 51, 51, 1),
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-200,
  dark: $gray-800
);

$body-color: var(--dark);

// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1230px,
  xxl: 1560px
);

$container-max-widths: (
  sm: 546px,
  md: 720px,
  lg: 960px,
  xl: 1230px
);

$wrappercontainerMaxWidths: (
  xs: 520px,
  sm: 708px,
  md: 934px,
  lg: 1140px,
  xl: 1330px,
  xxl: 1530px
);

$modalMaxWidths: (
  xs: 520px,
  sm: 708px,
  md: 934px,
  lg: 1000px,
);

// Owl
$owlWrapperMaxWidths: (
  xs: 86.25vw,
  sm: 56vw,
  md: 78.125vw,
  lg: 86.25vw,
  xl: 1200px
);

$hoverBoxShadow: 0 0 4px 1px rgba(0, 0, 0, .25);

// Fonts
$font-size-base: 1rem;

$fontFirst: var(--globalFontFamily);
$fontSecond: Roboto, "Helvetica Neue", Arial, sans-serif;

$font-family-sans-serif: $fontFirst;
$headings-font-family: $fontFirst;

// Site header
$headerTransitionDuration: 600ms;
$headerTransitionFunction: $defaultTransitionFunction;

$siteHeader: (
  bg: rgba(255, 255, 255, 0),
  bgFixed: rgba(255, 255, 255, 1),
  bgMobile: rgba(255, 255, 255, 0),
  bgMobileFixed: rgba(255, 255, 255, 1),
  borderBottom: 4px solid rgba(#ecf0f1, 0),
  borderBottomFixed: 4px solid rgba(#ecf0f1, 1),
  boxShadow: 0 0 0 0 rgba(0, 0, 0, 0),
  boxShadowFixed: 0 0 0 0 rgba(0, 0, 0, 0),
);

// Navbar
$navbar-padding-y: 18px;
$navbar-light-color: #fff;

$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;

$menu: (
  bg: rgba(255, 255, 255, 0),
  bgMobile: rgba(255, 255, 255, 0),
  bgOnFixed: rgba(255, 255, 255, 1),
);

$hamburgerMenu: (
  linesColor: rgba(#000, 1),
  linesColorOnFixed: rgba(#000, 1),
  linesColorAtTop: rgba(#fff, 1),
);

$menuLink: (
  color: rgba(51, 51, 51, 1),
  colorHover: rgba(255, 255, 255, 1),
  colorMobile: rgba(51, 51, 51, 1),
  colorMobileHover: rgba(#fff, 1),
  colorAtTop: rgba(255, 255, 255, 1),
  colorAtTopHover: rgba(255, 255, 255, 1),
  colorOnFixed: rgba(51, 51, 51, 1),
  colorOnFixedHover: rgba(255, 255, 255, 1),
  bgMobile: rgba(#c0392b, 0),
  bgMobileHover: var(--primary),
  bg: rgba(#c0392b, 0),
  bgHover: var(--primary),
  bgAtTop: rgba(#c0392b, 0),
  bgAtTopHover: var(--primary),
  bgOnFixed: rgba(#c0392b, 0),
  bgOnFixedHover: var(--primary),
);

$menuLinkActive: (
  color: var(--primary),
  colorHover: rgba(255, 255, 255, 1),
  colorMobile: var(--primary),
  colorMobileHover: rgba(255, 255, 255, 1),
  colorAtTop: rgba(255, 255, 255, 1),
  colorAtTopHover: rgba(255, 255, 255, 1),
  colorOnFixed: var(--primary),
  colorOnFixedHover: rgba(255, 255, 255, 1),
  bg: rgba(#c0392b, 0),
  bgHover: var(--primary),
  bgMobile: rgba(#c0392b, 0),
  bgMobileHover: var(--primary),
  bgAtTop: rgba(#c0392b, 0),
  bgAtTopHover: var(--primary),
  bgOnFixed: rgba(#c0392b, 0),
  bgOnFixedHover: var(--primary),
);

$menuLinkPadding: (
  xsTop: 15px,
  xsBottom: 15px,
  lgTop: 8px,
  lgBottom: 8px,
);

$submenu: (
  bgMobile: rgba(255, 255, 255, 0),
  bg: rgba(255, 255, 255, 1),
  bgAtTop: rgba(255, 255, 255, 1),
  bgOnFixed: rgba(255, 255, 255, 1),
  boxShadow: 0 0 0 0 rgba(0, 0, 0, 0),
);

$submenuLink: (
  color: rgba(51, 51, 51, 1),
  colorHover: rgba(51, 51, 51, 1),
  colorMobile: rgba(51, 51, 51, 1),
  colorMobileHover: var(--primary),
  colorAtTop: rgba(51, 51, 51, 1),
  colorAtTopHover: rgba(51, 51, 51, 1),
  colorOnFixed: rgba(51, 51, 51, 1),
  colorOnFixedHover: rgba(51, 51, 51, 1),
  bg: rgba(#c0392b, 0),
  bgHover: rgba(#c0392b, 0),
  bgOnFixed: rgba(#c0392b, 0),
  bgOnFixedHover: var(--primary),
);

$submenuFeatured: (
  titleColor: rgba(51, 51, 51, 1),
  titleColorHover: rgba(51, 51, 51, 1),
  titleColorOnFixed: var(--primary),
  titleColorOnFixedHover: var(--primary),
  titleColorAtTop: var(--primary),
  titleColorAtTopHover: var(--primary),
  catColor: rgba(51, 51, 51, 1),
  catColorOnFixed: var(--primary),
  catColorAtTop: rgba(51, 51, 51, 1),
  catColorAtTopHover: rgba(51, 51, 51, 1),
);

$langLink: (
  color: rgba(51, 51, 51, 1),
  colorHover: var(--primary),
  colorMobile: rgba(51, 51, 51, 1),
  colorMobileHover: rgba(255, 255, 255, 1),
  colorAtTop: rgba(255, 255, 255, 1),
  colorAtTopHover: var(--primary),
  colorOnFixed: rgba(51, 51, 51, 1),
  colorOnFixedHover: var(--primary),
  bg: rgba(255, 255, 255, 0),
  bgHover: rgba(255, 255, 255, 0),
  bgMobile: rgba(#c0392b, 0),
  bgMobileHover: var(--primary),
  bgAtTop: rgba(255, 255, 255, 0),
  bgAtTopHover: rgba(255, 255, 255, 0),
  bgOnFixed: rgba(255, 255, 255, 0),
  bgOnFixedHover: rgba(255, 255, 255, 0),
);

$langLinkActive: (
  color: var(--primary),
  colorHover: var(--primary),
  colorMobile: rgba(255, 255, 255, 1),
  colorMobileHover: rgba(255, 255, 255, 1),
  colorAtTop: var(--primary),
  colorAtTopHover: rgba(255, 255, 255, 1),
  colorOnFixed: var(--primary),
  colorOnFixedHover: var(--primary),
  bg: rgba(255, 255, 255, 0),
  bgHover: rgba(255, 255, 255, 0),
  bgMobile: var(--primary),
  bgMobileHover: var(--primary),
  bgAtTop: rgba(255, 255, 255, 0),
  bgAtTopHover: rgba(255, 255, 255, 0),
  bgOnFixed: rgba(255, 255, 255, 0),
  bgOnFixedHover: rgba(255, 255, 255, 0),
);

// Carousel
$carousel-control-width: 30px;

$carousel-indicator-width: 12px;
$carousel-indicator-height: 12px;
$carousel-indicator-spacer: 0;

$carousel-indicator-border-color: var(--primary);
$carousel-indicator-hover-bg: var(--primary);
$carousel-indicator-active-bg: var(--primary);

$containersSpacingBase: 30px;
$homeTopSliderPadding: 10px;

// Buttons
$btn-border-radius: 0;

// Dropdowns
$dropdown-padding-y: $containersSpacingBase;
$dropdown-border-color: var(--light);

$dropdown-link-color: var(--dark);
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: var(--primary);

// Breadcrumbs
$breadcrumb-padding-y: 0;
// $breadcrumb-padding-x:    0;

$breadcrumb-margin-bottom: 38px;

$breadcrumb-bg: $white;
$breadcrumb-divider-color: rgb(204, 204, 204);
$breadcrumb-active-color: rgb(153, 153, 153);
$breadcrumb-divider: ">";

// Pagination
$pagination-padding-y: 14px;
$pagination-padding-x: 18px;

$pagination-color: var(--tertiary);
$pagination-bg: var(--light);
$pagination-border-width: 2px;
$pagination-border-color: var(--light);

$pagination-hover-color: var(--primary);
$pagination-hover-bg: $white;
$pagination-hover-border-color: var(--primary);

$pagination-active-color: $white;
$pagination-active-bg: var(--primary);
$pagination-active-border-color: var(--primary);

$pagination-disabled-color: var(--tertiary);
$pagination-disabled-bg: var(--light);
$pagination-disabled-border-color: var(--light);

// Tabs
$nav-tabs-border-color: var(--light);
$nav-tabs-link-active-border-color: $nav-tabs-border-color;
$nav-tabs-border-radius: 0;

$nav-tabs-link-active-bg: var(--light);
$nav-tabs-border-color: $nav-tabs-link-active-bg;

// Links
$link-hover-decoration: none;
$link-hover-color: var(--primary);

// Modals
$modal-dialog-margin: 0;
$modal-dialog-margin-y-sm-up: 0;

$modal-content-bg: var(--light);
$modal-content-border-width: 0;
$modal-content-box-shadow-xs: none;
$modal-content-box-shadow-sm-up: none;

$modal-backdrop-bg: $white;
$modal-backdrop-opacity: 1;
$modal-header-border-color: $gray-200;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding: 15px;

$modal-md: 570px;

$modal-transition: transform .3s ease-out;

// Masonry lists
$masonryListColors: (
  bg: rgba(255, 255, 255, 0),
  bgHover: rgba(#e9ecef, 1),
);

// Internal nav
$pageNav: (
  bg: rgba(#ecf0f1, 1),
  bgHover: rgba(255, 255, 255, 1),
  bgDisabled: rgba(#ecf0f1, .4),
  border: 2px solid rgba(#ecf0f1, 1),
  borderHover: 2px solid var(--primary),
  borderDisabled: 2px solid rgba(#ecf0f1, 1),
);
