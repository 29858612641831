@mixin about-section-title($color: theme-color(dark)) {
  color: $color;
  font-size: 1.5rem;
  line-height: 1.8125;
  text-align: center;
  text-transform: uppercase;
}

.content {
  padding-top: 18px;

  &__title {
    margin-bottom: 18px;
    font-size: 1.75rem;
    line-height: 1.2;

    @include media-breakpoint-up(lg) {
      margin-top: -8px;
    }
  }

  &__lead {
    font-weight: 700;
  }

  &__nomargin {
    margin-bottom: 0 !important;

    + .content__list {
      margin-top: 0;
    }
  }

  &__col {
    &--text {
      @include media-breakpoint-up(lg) {
        &:first-child {
          padding-right: 45px;
        }

        &:last-child {
          padding-left: 45px;
        }
      }

      @include media-breakpoint-up(xl) {
        &:first-child {
          padding-right: 60px;
        }

        &:last-child {
          padding-left: 60px;
        }
      }
    }

    &--image {
      @include media-breakpoint-up(lg) {
        &:first-child {
          padding-right: 0;
        }

        &:last-child {
          padding-left: 0;
        }
      }
    }
  }

  &__list {
    margin: {
      top: 20px;
      bottom: 20px;
    }
    padding-left: 16px;
    list-style-type: disc;
  }

  &__images-wrapper {
    height: 100%;
    padding: {
      top: 32px;
      bottom: 32px;
    }
  }

  &__images-list {
    height: 100%;
    display: flex;

    &--logos {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &--logos {
      .content__list-image {
        max-height: 124px;
      }
    }
  }

  &__list-image-item {
    padding: 10px 25px;

    a {
      display: block;
    }
  }

  &__list-image {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
  }

  &__larger {
    font-size: 1.125rem !important;
  }

  p {
    margin-bottom: 19px;
    line-height: 1.4;
  }

  &__btn {
    padding-top: 6px;
    padding-bottom: 7px;
    font-size: 1.25rem;
    font-weight: 400;
    text-transform: none;
  }
}

// o nas
.content--about {
  .content {
    &__image {
      margin: {
        top: 24px;
        bottom: 32px;
      }

      @include media-breakpoint-up(lg) {
        margin: {
          top: 0;
          bottom: 86px;
        }
      }
    }
  }
}

// nasze serwisy
.our-services {
  padding: {
    top: 68px;
  }

  &__title {
    @include about-section-title();
  }

  &__list {
    margin: 0;
    padding: 24px 0;
    border: {
      top: 1px solid theme-color(light);
      bottom: 1px solid theme-color(light);
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
  }

  &__item {
    max-width: 100%;
    padding: {
      top: 15px;
      bottom: 15px;
      left: 25px;
      right: 25px;
    }
    flex: 1 0 100%;

    @include media-breakpoint-up(sm) {
      max-width: 50%;
      flex: 1 0 50%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 25%;
      padding: {
        top: 0;
        bottom: 0;
      }
      flex: 1 0 25%;

      &--so {
        max-width: 31%;
        flex: 1 0 31%;
      }

      &--snp {
        max-width: 29%;
        flex: 1 0 29%;
      }

      &--wo {
        max-width: 21%;
        flex: 1 0 21%;
      }

      &--sp {
        max-width: 19%;
        flex: 1 0 19%;
      }
    }
  }

  &__link {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    // &::after{
    //   height:100%;
    //   display:inline-block;
    //   vertical-align:middle;
    //   content:"";
    // }
  }

  &__image {
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
  }
}

// wirtualny spaceru
.virtual-stroll {
  margin-top: 75px;
  padding-bottom: 180px;

  &__title {
    margin-bottom: 14px;
    @include about-section-title();
  }
}

// zespół
.content--team {
  padding-bottom: 70px;

  .content {
    &__col {
      padding-bottom: 62px;

      @include media-breakpoint-up(md) {
        &:nth-child(2n + 1) {
          padding-right: 30px;
        }

        &:nth-child(2n + 2) {
          padding-left: 30px;
        }
      }
    }

    &__title {
      margin-bottom: 20px;
    }

    &__member-name {
      font-size: 1.125rem;
      line-height: 1.2;
    }

    &__member-photo {
      margin-bottom: 32px;
    }
  }
}

// Wykłady
.content--lectures {
  padding-bottom: 60px;

  .content {
    &__row {
      @include media-breakpoint-up(lg) {
        margin-bottom: 90px;
      }
    }

    &__col {
      &--image {
        margin: {
          top: 10px;
          bottom: 24px;
        }

        @include media-breakpoint-up(lg) {
          margin: {
            top: 0;
            bottom: 0;
          }
        }
      }
    }
  }
}

// doradztwo
.content--assistance {
  padding-bottom: 150px;
}

// skup
.content--purchase {
  .content {
    &__lead {
      margin-bottom: 20px;
    }

    &__list {
      margin-bottom: 18px;
    }

    &__col {
      &--text {
        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
      }

      &--image {
        text-align: center;
      }
    }
  }

  p {
    margin-bottom: 20px;
  }

  p,
  ul,
  ol {
    line-height: 1.375;
  }
}

.purchase-page {
  .site-main {
    padding-bottom: 180px;
  }
}

// timeline
.content--timeline {
  .content {
    &__title {
      text-align: center;
      font-size: 1.5rem;
      text-transform: uppercase;
    }
  }

  .art-thumb-link {
    overflow: hidden;
    text-indent: -999px;
  }
}

.timeline {
  &__presentation {
    .images-presentation {
      opacity: 1;
    }
  }

  &__item {
    margin: {
      left: -15px;
      right: -15px;
    }

    &:nth-child(2n + 1) {
      .timeline__item-inner {
        margin: {
          left: 0;
          right: auto;
        }
      }

      @include media-breakpoint-up(md) {
        .timeline__date {
          order: 3;
        }

        .timeline__text {
          order: 2;
        }

        .timeline__images {
          order: 1;
        }

        .timeline__date-header {
          text-align: right;
        }
      }
    }

    &:nth-child(2n + 2) {
      .timeline__item-inner {
        margin: {
          left: auto;
          right: 0;
        }
      }
    }
  }

  &__item-inner {
    max-width: 915px;
    padding: {
      top: 26px;
      bottom: 38px;
    }
    display: flex;
    position: relative;
    flex: 0 0 915px;
    flex-direction: column;

    &::before {
      height: 2px;
      display: block;
      position: absolute;
      top: 0;
      bottom: auto;
      left: 15px;
      right: 0;
      background-color: theme-color(primary);
      content: "";
    }

    .timeline__item:nth-child(2n + 1) & {
      &::before {
        left: 15px;
        right: 0;
      }
    }

    .timeline__item:nth-child(2n + 2) & {
      &::before {
        left: 0;
        right: 15px;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  // obrazy
  &__images {
    width: 100%;
    padding: {
      left: 15px;
      right: 15px;
    }
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      max-width: 25%;
      flex: 0 0 25%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 42.5%;
      flex: 0 0 42.5%;
      flex-direction: row;
    }
  }

  &__main-image {
    width: 100%;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      max-width: auto;
    }

    @include media-breakpoint-up(lg) {
      max-width: 300px;

      .timeline__item:nth-child(2n + 2) & {
        order: 2;
      }
    }
  }

  &__image {
    max-width: 100%;
    height: auto;
    display: block;
  }

  &__thumbnails-col {
    width: 100%;
    margin-top: 16px;

    @include media-breakpoint-up(lg) {
      width: 50px;
      margin-top: 0;
      flex: 0 0 50px;

      .timeline__item:nth-child(2n + 1) & {
        margin-left: 9px;
      }

      .timeline__item:nth-child(2n + 2) & {
        margin-right: 9px;
        order: 1;
      }
    }
  }

  &__thumbnails {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @include media-breakpoint-up(lg) {
      flex-direction: column;
    }
  }

  &__thumbnail {
    max-width: 18%;
    flex: 0 0 18%;

    @include media-breakpoint-up(lg) {
      max-width: 100%;
      margin-bottom: 10px;
      flex: 0 0 100%;
    }
  }

  &__thumbnail-link {
    height: 0;
    display: block;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
    background: no-repeat 50% 50% / cover;
    text-indent: -999px;

    &::after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.4);
      content: "";
    }

    @include hover-focus {
      &::after {
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }

  // data
  &__date {
    width: 100%;

    .timeline__item:nth-child(2n + 1) & {
      padding: {
        left: 15px;
        right: 0;
      }
    }

    .timeline__item:nth-child(2n + 2) & {
      padding: {
        left: 15px;
        right: 15px;
      }

      @include media-breakpoint-up(lg) {
        padding: {
          left: 0;
        }
      }
    }

    @include media-breakpoint-up(md) {
      max-width: 15%;
      flex: 0 0 15%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 8%;
      flex: 0 0 8%;
    }
  }

  &__date-header {
    color: theme-color(primary);
    font-size: 1.5rem;
    font-weight: 700;
  }

  // treść
  &__text {
    width: 100%;
    padding: {
      left: 15px;
      right: 15px;
    }

    .timeline__item:nth-child(2n + 1) & {
      @include media-breakpoint-up(md) {
        padding: {
          left: 25px;
          right: 25px;
        }
      }
    }

    .timeline__item:nth-child(2n + 2) & {
      @include media-breakpoint-up(md) {
        padding: {
          left: 25px;
          right: 25px;
        }
      }
    }

    @include media-breakpoint-up(md) {
      max-width: 60%;
      flex: 0 0 60%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 49.5%;
      flex: 0 0 49.5%;
    }

    p {
      text-align: justify;
    }
  }
}

// eventy
.content--events {
  .art-thumb-link {
    overflow: hidden;
    text-indent: -999px;
  }

  .content__btn {
    width: 100%;
    max-width: 298px;
    display: block;
  }

  .content__col {
    &--text {
      margin-bottom: 42px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        padding-right: 50px;
      }

      @include media-breakpoint-up(xl) {
        padding-right: 65px;
      }
    }

    .side-carousel {
      @include media-breakpoint-up(lg) {
        margin-left: -5px;
      }
    }
  }
}

.side-presentation {
  &__carousel {
    .images-presentation {
      opacity: 1;
    }
  }
}

.side-carousel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__image {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 12px;

    @include media-breakpoint-up(sm) {
      max-width: 49%;
      flex: 0 0 49%;

      &:nth-child(2n + 1) {
        margin: {
          left: 0;
          right: auto;
        }
      }

      &:nth-child(2n + 2) {
        margin: {
          left: auto;
          right: 0;
        }
      }
    }

    @include media-breakpoint-up(md) {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: 12px;

      &:nth-child(2n + 1) {
        margin: {
          left: auto;
          right: auto;
        }
      }

      &:nth-child(2n + 2) {
        margin: {
          left: auto;
          right: auto;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      max-width: 49%;
      flex: 0 0 49%;
      margin-bottom: 8px;

      &:nth-child(2n + 1) {
        margin: {
          left: 0;
          right: auto;
        }
      }

      &:nth-child(2n + 2) {
        margin: {
          left: auto;
          right: 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      max-width: 190px;
      flex: 0 0 190px;
      margin-bottom: 20px;
    }
  }

  &__link {
    height: 0;
    padding-bottom: 100%;
    display: block;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    text-indent: -9999px;
  }
}

//overwritten bootsrap

$link-color: var(--primary) !default;

form input[type="text"]:hover,
form input[type="text"]:focus,
form input[type="tel"]:hover,
form input[type="tel"]:focus,
form input[type="email"]:hover,
form input[type="email"]:focus,
form textarea.form-control:hover,
form textarea.form-control:focus {
  border-color: var(--primary);
}

.btn-link {
  color: var(--primary);
}

.btn-link:hover {
  color: var(--dark);
}

.upload-form__files-info {
  color: var(--primary);
}

.upload-form__label--file {
  border-color: var(--primary);
}

.carousel-home-mid .carousel-article-more:after {
  background-color: var(--primary);
}

a {
  color: var(--secondary);
  color: var(--primary);
}

.home-top-jumbotron.product-counter strong {
  color: var(--primary);
}


.footer-newsletter-request-submit {
  border-color: var(--newsletterBorderColor);
  color: var(--newsletterBorderColor);
}

.footer-newsletter-request-submit:hover,
.footer-newsletter-request-submit:focus {
  background-color: var(--newsletterBorderColor);
  color: var(--primary);
}

.footer-privacy-policy .footer-privacy-policy-link {
  color: var(--footerFontColor);
}

.footer-link, .footer-link:hover,
.footer-privacy-policy .footer-privacy-policy-link:hover,
.footer-privacy-policy .footer-privacy-policy-link:focus {
  color: var(--footerFontColor);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary);
}

.items-filter-selectable .dropdown-item:hover,
.items-filter-selectable .dropdown-item:focus {
  background-color: var(--primary);
}

.active .items-filter-tag {
  background-color: var(--primary);
}

.items-list-filters .search-phrase:hover,
.items-list-filters .search-phrase:focus {
  border-color: var(--primary);
}

.items-list-filters .search-phrase:hover + .search-submit,
.items-list-filters .search-phrase:focus + .search-submit {
  border-color: var(--primary);
}

.items-list-filters .input-group:hover .search-phrase,
.items-list-filters .input-group:hover .search-submit {
  border-color: var(--primary);
}

.btn-advanced-filter:hover,
.btn-advanced-filter:focus {
  background-color: var(--primary);
}

.advanced-filters-content .items-filter-group.show {
  background-color: var(--primary);
}

.hover .letters-filter-btn,
.letters-filter-btn:hover,
.letters-filter-btn.hover {
  background-color: var(--primary);
}

.partners-block .partners-title {
  color: var(--primary);
}

.artist-name {
  text-transform: none;

  &.on-list-name {
    padding-top: var(--artistNameOnListPaddingTopForArtist);
    padding-bottom: var(--artistNameOnListPaddingBottomForArtist);
    padding-right: var(--artistNameOnListPaddingRightForArtist);

    a {
      color: var(--artistNameFontColorForList);

      > span {

        color: var(--artistNameFontColorForList);

      }
    }
  }

  a {
    color: var(--artistNameFontColorForArtist);
    font-size: var(--artistNameOnListFontSizeForArtist);

    > span {

      color: var(--artistNameFontColorForArtist);
      font-size: var(--artistNameOnListFontSizeForArtist);

      &.last-name {
        text-transform: var(--artistNameTextTransformForArtist);
      }
    }

    &:hover,
    &:focus {
      color: var(--primary);
    }
  }

  &.page-title {
    padding-top: var(--artistNameOnPreviewPaddingTopForArtist);
    padding-bottom: var(--artistNameOnPreviewPaddingBottomForArtist);

    > span, span {
      font-weight: bold;

      color: var(--artistNameFontColorForArtist);
      font-size: var(--artistNameOnPreviewFontSizeForArtist);

      .last-name {
        text-transform: var(--artistNameTextTransformForArtist);
      }
    }
  }

  &.grid-item-title {
    text-transform: none;
    padding-bottom: var(--artistNameOnListPaddingBottomForOffer);
    padding-top: var(--artistNameOnListPaddingTopForOffer);
    font-size: var(--artistNameOnListFontSizeForOffer);
    margin-top: 0;
    margin-bottom: 0;

    > span, span {
      color: var(--artistNameFontColorForOffer);

      &.last-name {
        text-transform: var(--artistNameTextTransformForOffer);
      }
    }
  }

  &.offer-page {
    a {
      padding-top: var(--artistNameOnPreviewPaddingTopForOffer);
      padding-bottom: var(--artistNameOnPreviewPaddingBottomForOffer);
      text-transform: none;
      float: left;
      width: 100%;

      &.no-click {
        text-decoration: none;
        pointer-events: none;
      }

      > span, span {
        color: var(--artistNameFontColorForOffer);
        font-size: var(--artistNameOnPreviewFontSizeForOffer);
        font-weight: 600;

        &.last-name {
          text-transform: var(--artistNameTextTransformForOffer);
        }
      }
    }
  }
}

.work-title.offer-page {
  padding-bottom: var(--paddingBottomTitleOnPreviewForOffer);

  > span:not(.work-date) {
    font-style: var(--offerNameFontStyleForOffer);
    font-size: var(--fontSizeTitleOnPreviewForOffer);
  }
}

.grid-item-text.offer-title {
  padding-bottom: var(--paddingBottomTitleOnListForOffer);
  padding-top: var(--paddingTopTitleOnListForOffer);
  font-size: var(--fontSizeTitleOnListForOffer);
  margin-bottom: 0;

  .title {
    font-style: var(--offerNameFontStyleForOffer);
  }

  .work-date {
    display: var(--datesDisplay);
  }

  .work-dimensions {
    display: var(--dimensionsDisplay);
    color: var(--dark)
  }
}

.masonry-grid-item-info {
  font-size: var(--fontSizeTitleOnListForOffer);
}

.current-letter-filter-item .letters-filter-btn {
  background-color: var(--primary);
}

.items-filter-tag:hover,
.items-filter-tag:focus,
.items-filter-tag-btn:hover,
.items-filter-tag-btn:focus {
  background-color: var(--primary);
}

.items-filter-group.show {
  background-color: var(--primary);
}

.cookies-message {
  color: var(--primary);
}

// OWL NAV

.owl-prev,
.owl-next {
  background-image: none !important;
}

.owl-nav .owl-prev::after,
.owl-nav .owl-next::after {
  background-color: var(--secondary);
  opacity: 1;
  height: 50%;
  top: 25%;
  left: 25%;
}

.owl-nav .owl-prev:hover::after,
.owl-nav .owl-next:hover::after {
  background-color: var(--primary);
}

.owl-nav .owl-prev::after {
  mask: url("../../assets/svg/left-arrow.svg") no-repeat !important;
}

.owl-nav .owl-next::after {
  mask: url("../../assets/svg/right-arrow.svg") no-repeat !important;
}

//socials

.socials-link {
  background-color: #fff;
}

.socials-link::after {
  opacity: 1;
  background-color: var(--primary);
}

.socials-facebook::after {
  mask: url("../../assets/svg/facebook.svg");
}

.socials-facebook:hover::after {
  background-color: #3B5998;
}

.socials-instagram::after {
  mask: url("../../assets/svg/instagram.svg");
}

.socials-instagram:hover::after {
  background-color: var(--footerFontColor);
}

.socials-twitter::after {
  mask: url("../../assets/svg/twitter.svg");
}

.socials-twitter:hover::after {
  background-color: #00acee;
}

.socials-pinterest::after {
  mask: url("../../assets/svg/pinterest.svg");
}

.socials-pinterest:hover::after {
  background-color: #c8343c;
}

.socials-email::after {
  mask: url("../../assets/svg/email_2.svg");
  mask-position: center;
}

.socials-email:hover::after {
  background-color: #891112;
}

.socials-youtube::after {
  mask: url("../../assets/svg/youtube.svg");
}

.socials-youtube:hover::after {
  background-color: #c4302b;
}

// no-masonry

.no-masonry {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .masonry-grid-item {
    //min-height: 500px;
  }

  .four-columns {
    width: 25% !important;
  }

  .three-columns {
    width: 33% !important;
  }

  .four-columns,
  .three-columns {
    @include media-breakpoint-down(sm) {
      width: 50% !important;
    }

    @include media-breakpoint-down(xs) {
      width: 100% !important;
    }
  }
}

// page-top
.page-top {
  display: flex;
  justify-content: center;

  .carousel-home-top.full-size {
    width: 100%;

  }

  .carousel-home-top.container {
    padding: 0;
  }

}

.lang-item-current .lang-link, .lang-link {
  color: var(--primary) !important;

  @include media-breakpoint-down(md) {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
}

.lang-item-current .lang-link {
  color: var(--secondary) !important;
}

.lang-link:hover {
  color: var(--menuItemActiveFontColor) !important
}

.navbar-search.has-separator:after {
  margin-top: -4px;
}

.navbar-search-link-custom {
  width: var(--searchIconSize) !important;
  transition: color 300ms ease-in-out;
  color: var(--menuItemIconsColor);
  font-size: var(--searchIconSize);
  height: 32px;
  align-items: center;
  display: flex;
  @media (max-width: 991.98px) {
    padding-top: 5px;
  }
  @media (min-width: 991.99px) {
    margin-right: 10px;
  }

  &:hover {
    color: var(--menuItemActiveFontColor);
  }
}

.breadcrumb {
  background-color: var(--mainBackground);
}

.page-item-next,
.page-item-prev,
.owl-nav .owl-prev,
.owl-nav .owl-next,
.internal-nav-next,
.internal-nav-prev,
.scrolltop-outer {
  border-color: var(--secondaryBackground) !important;
  background-color: var(--secondaryBackground) !important;
  background-image: none !important;
  mask-image: none !important;
  position: relative;

  &::after {
    content: ' ' !important;
    top: 15px !important;
    left: 13px !important;
    mask-image: none !important;
    position: absolute;
    width: 15px !important;
    height: 15px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 36px !important;
    font-weight: bold;
    border: 2px solid var(--dark);
    border-bottom: none;
    border-left: none;
    opacity: 1;
    background: none !important;
  }
}

.page-item-next,
.page-item-prev,
.owl-nav .owl-prev,
.owl-nav .owl-next,
.internal-nav-next,
.internal-nav-prev {
  &:hover {
    border-color: var(--primary) !important;
    background-color: transparent !important;

    &:after {
      border-color: var(--primary) !important;
    }
  }
}

.scrolltop-outer:hover,
.scrolltop-outer:focus {
  border-color: var(--newsletterBorderColor) !important;
  background-color: transparent !important;

  &:after {
    border-color: var(--newsletterBorderColor) !important;
  }
}

.page-item-prev::after,
.owl-nav .owl-prev::after,
.internal-nav-prev::after {
  transform: rotate(225deg);
  left: 17px !important;
}

.page-item-next::after,
.owl-nav .owl-next::after,
.internal-nav-next::after {
  transform: rotate(45deg);
}

.scrolltop-outer {
  position: fixed;

  &::after {
    top: 20px !important;
    left: 15px !important;
    transform: rotate(-45deg);
  }
}

.page-item-next,
.page-item-prev {
  a.page-link {
    background-image: none !important;
    cursor: pointer;

    &:hover {
      background: transparent !important;
    }
  }

  &::after {
    top: 18px !important;
  }
}

.page-item-next {
  &::after {
    left: 15px !important;
  }
}

.page-item-prev {
  &::after {
    left: 20px !important;
  }
}

.page-item.disabled {
  &:hover {
    &:after {
      border-color: var(--dark) !important;
    }
  }
}

.internal-nav-next,
.internal-nav-prev {
  a.internal-nav-link {
    z-index: 99;
  }
}

.search-results-tabs .nav .nav-item a {
  &.active {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  &:hover {
    background-color: var(--secondary);
  }
}

.masonry-grid-item.search-result:hover {
  background-color: var(--secondaryBackground) !important;
}

.modal-form-container {
  background-color: var(--secondaryBackground) !important;
}

.modal-title.modal-title-bordered:after {
  background-color: var(--secondaryBackground) !important;
}

.contact-us-btn-wrapper {
  button {
    &:active,
    &:hover,
    &:focus {
      background-color: var(--primary) !important;
      color: var(--secondary) !important
    }
  }
}


.modal-form .art-name {
  color: var(--primary) !important;
}

.modal-form-body {
  .btn-cancel {
    color: var(--primary) !important;
  }

  .btn-apply {
    &:active,
    &:hover,
    &:focus {
      background-color: var(--primary) !important;
      color: var(--secondary) !important;
    }
  }
}

.artist-name a:hover {
  color: var(--primary) !important;
}

.masonry-grid-item:hover {
  background: var(--secondaryBackground) !important;
}

.artists .filters-container {
  background: var(--secondaryBackground) !important;
}

.filter-letters-list {
  border-color: var(--secondaryBackground) !important;
}

.irs--flat .irs-bar {
  background-color: var(--primary);
}

.active-filter-item-close {
  color: var(--secondary);

  &:hover {
    color: var(--secondary);
    background-color: var(--primary);
  }
}

.btn.btn-primary.btn-advanced-filter-cancel {
  background-color: transparent;
  color: var(--primary);

  &:hover {
    background: var(--primary);
    color: var(--secondary);
  }
}

.page-title.not-found-title,
.page-title:not(.exhibition-title),
.socials-share-header {
  color: var(--primary) !important;
}

.artist-tabs .nav-item a {
  background-color: var(--primary) !important;
}

.search-modal .search-submit {
  color: var(--primary) !important;

  &:hover {
    color: #ffffff !important;
    background-color: var(--primary) !important;
  }
}

a.btn.btn-primary.btn-contact-us {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;

  &:hover {
    color: var(--primary);
    background-color: transparent;
  }
}

.carousel-article-link {
  h1.carousel-article-title,
  .carousel-article-text {
    &:hover {
      //color: var(--primary) !important;
    }
  }
}

.active-filter-item {
  background-color: var(--primary) !important;
}

.navbar-search.has-separator:after {
  background-color: var(--primary) !important;
}

.irs--flat .irs-handle {
  > i:first-child {
    background-color: var(--primary);
  }

  &:hover {
    > i:first-child {
      background-color: var(--secondary);
    }
  }
}

.items-checkbox-menu .dropdown-item:after {
  border-color: var(--primary);
}

.mCSB_scrollTools .mCSB_dragger {
  background-color: var(--primary);
}

.advanced-filters-collapse.show {
  z-index: 10;
}

.image-zoom-viewer__close:before,
.viewer-toolbar > ul > li.viewer-zoom-in::before,
.viewer-toolbar > ul > li.viewer-zoom-out::before,
.viewer-button:hover, .viewer-button:focus,
.btn-maximize-image:after,
.close-icon:before {
  background-color: var(--primary) !important;
}

.footer-newsletter-form-email {
  border-color: var(--newsletterBorderColor) !important;

  &.ng-invalid.ng-touched {
    border-color: rgba(255, 34, 51, 0.4) !important;
  }
}

.navbar-brand.order-1 {
  align-self: center;
}

.items-filter-group {
  .btn:after {
    background-image: none !important;
    content: ' ';
    border: 2px solid var(--primary);
    width: 10px;
    height: 10px;
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
    margin: 0 !important;
    top: 30% !important;
    transition-property: top, transform;
  }

  &.show .btn:after {
    border-color: #ffffff;
    transform: rotate(-135deg);
    top: 50% !important;
  }
}


.socials-share-header {
  font-family: var(--globalFontFamily);
}

.breadcrumb {
  display: block;
  text-align: left;

  li {
    display: inline;
    padding-left: 0 !important;

    &:before {
      display: none !important;
    }

    &:after {
      display: inline-block;
      padding-right: .5rem;
      color: #ccc;
      content: ">";
    }

    &:last-child:after {
      display: none !important;
    }
  }
}

.exhibition-title .exhibition-link {
  color: var(--dark);

  &:hover {
    color: var(--primary);
  }
}

.exhibition-link, .news-link {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
}

.artist-masonry-grid .img-fluid {
  max-height: 250px;
}

.tab-content.artist-tabs-content {
  padding-top: 0 !important;
}

h2.page-title.artist-name {
  margin-bottom: 0 !important;
}

.auction-estimation {
  font-size: 1rem;
}

.navbar-brand {
  width: 100%;
}

.exhibition-label {
  display: var(--newsExhibitionLabelDisplay) !important;
}

.artist-content-section nav.artist-tabs ul.nav-tabs li.nav-item a.nav-link {
  background-color: var(--artistWorksLabelBackground) !important;
  color: var(--artistWorksLabelFontInactiveColor);

  &.active {
    color: var(--artistWorksLabelFontActiveColor);
  }
}

.styled-header {
  font-size: var(--styledHeaderFontSize) !important;
  color: var(--styledHeaderColor) !important;
}

.catalogue-label,
.exhibition-catalogue-id {
  display: var(--catalogueWorksDisplay);
}

@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: calc(#{$value} * #{$number});
    }
  }

  @return $value;
}

@function sqrt($r) {
  $x0: 1; // initial value
  $solution: false;

  @for $i from 1 through 10 {
    @if abs(2 * $x0) < 0.00000000000001 { // Don't want to divide by a number smaller than this
      $solution: false;
    }

    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0) !global;

    @if ( abs($x1 - $x0) / abs($x1)) < 0.0000001 { // 7 digit accuracy is desired
      $solution: true;
    }

    $x0: $x1;
  }

  @if $solution == true {
    // If $r is negative, then the output will be multiplied with <a href="http://en.wikipedia.org/wiki/Imaginary_number">i = √-1</a>
    // (√xy = √x√y) => √-$r = √-1√$r
    @if $r < 0 {
      @return $x1 #{i};
    } @else {
      @return $x1;
    }
  } @else {
    @return "No solution";
  }
}

.sort-label {
  font-size: var(--sortLabelFontSize) !important;

  &:after {
    width: calc(sqrt(pow(var(--sortLabelFontSize), 2) + pow(var(--sortLabelFontSize), 2)));
    height: calc(sqrt(pow(var(--sortLabelFontSize), 2) + pow(var(--sortLabelFontSize), 2)));
  }
}

.contact-page-section .contact-us-btn-wrapper {
  display: var(--contactButtonOnContactPageDisplay);
}

.artist-works {
  display: var(--artistWorksDisplay) !important;
}

.artist-archive {
  display: var(--artistArchivedWorksDisplay) !important;
}

.social-media-share {
  display: var(--socialMediaDisplay) !important;
}

.exhibitions-list-article, .news-article {
  height: var(--exhibitionListGridElementHeight);

  img {
    &.exhibition-figure, &.news-figure {
      object-fit: cover;
      object-position: center;
      height: calc(var(--exhibitionListGridElementHeight)
      - ((var(--newsExhibitionTitleFontSize) * 2)
      + var(--paddingBottomNewsExhibitionTitle)
      + var(--paddingTopNewsExhibitionTitle)
      + var(--newsExhibitionDateFontSize)
      + var(--paddingBottomNewsExhibitionDate))
      );
    }
  }
}

.grid-item-content {
  position: relative;
}

.custom-footer-content {
  .icon {
    img {
      display: inline !important;
    }

    img.hover {
      display: none !important;
    }

    &:hover {
      img:not(:last-child) {
        display: none !important;
      }

      img.hover {
        display: inline !important;
      }
    }
  }
}

.show-more-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &.for-artist {
    margin-top: -14px;
  }

  &.for-offer-auction {
    margin-top: -20px;
  }

  &.single-offer {
    margin-bottom: -30px;
  }

  .show-more-button {
    margin-top: 10px;
    cursor: pointer;
    display: flex;
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    a {
      &.nav-link,
      &.lang-link {
        width: auto;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}

.catalogue-list-item-article,
.auction-work-header,
.offer-list-item {
  .grid-item-title,
  .artist-name {
    color: var(--artistNameFontColorForOffer);
    padding-bottom: var(--artistNameOnListPaddingBottomForOffer);
    padding-top: var(--artistNameOnListPaddingTopForOffer);
    font-size: var(--artistNameOnListFontSizeForOffer);
    margin-bottom: 0;
    font-weight: 600;

    span {
      color: var(--artistNameFontColorForOffer);
    }

    .last-name {
      text-transform: var(--artistNameTextTransformForOffer);
    }
  }

  .grid-item-text,
  .offer-item-text {
    font-style: var(--offerNameFontStyleForOffer);
    padding-bottom: var(--paddingBottomTitleOnListForOffer);
    padding-top: var(--paddingTopTitleOnListForOffer);
    margin-bottom: 0;
    font-size: var(--fontSizeTitleOnListForOffer);

    .work-date, .product-medium {
      font-style: normal;
    }
  }

  .work-dimensions {
    display: var(--dimensionsDisplay);
  }

  .work-date {
    display: var(--datesDisplay);
  }
}

.owl-slide-article {
  .work-dimensions {
    display: var(--dimensionsDisplay);
  }

  .work-date {
    display: var(--datesDisplay);
  }
}

.auction-work-description {
  .page-title {
    margin-bottom: 0;
  }

  .auction-work-header {
    margin-bottom: 0;

    .auction-work-title {
      font-style: var(--offerNameFontStyleForOffer);
      font-size: var(--fontSizeTitleOnPreviewForOffer);
      padding-bottom: var(--paddingBottomTitleOnPreviewForOffer);

      .date {
        font-style: normal;
      }
    }

    .artist-name {
      padding-bottom: var(--artistNameOnPreviewPaddingBottomForOffer);
      padding-top: var(--artistNameOnPreviewPaddingTopForOffer);
      font-size: var(--artistNameOnPreviewFontSizeForOffer);
      font-weight: 600;

      a {
        font-size: var(--artistNameOnPreviewFontSizeForOffer);

        span {
          font-size: var(--artistNameOnPreviewFontSizeForOffer);
        }
      }
    }
  }

  .auction-work-info,
  .auction-work-ref-no {
    font-size: var(--fontSizeUnderTitleOnPreviewForOffer);
  }
}

.auction-main-container,
.auction-content-wrapper {
  .auction-title {
    font-size: var(--auctionTitleFontSize);
    text-transform: var(--auctionTitleTextTransform);
    color: var(--auctionTitleColor);
    padding-top: var(--auctionTitlePaddingTop);
    padding-bottom: var(--auctionTitlePaddingBottom);

    a:first-child {
      color: var(--auctionTitleColor) !important;

      &:hover {
        color: var(--secondary) !important;
      }
    }
  }
}

.auction-main-container {
  .page-title.auction-title {
    color: var(--auctionTitleColor) !important;
  }
}

.items-basic-filters,
.auction-catalogue-filters {
  background: var(--filterBgColor);
}

.work-dimensions {
  color: var(--dark);
  margin: 0;
  padding: 0;

  &.on-offer-list-grid,
  &.in-artist-preview,
  &.in-exhibition-preview {
    display: var(--dimensionsDisplay);
  }
}

.offer-item-info.price,
.masonry-grid-item-link .masonry-grid-item-info.price {
  padding-top: var(--pricePaddingTopForList);
}

.auction-content-wrapper .auction-title a:first-child.hoverable-element:hover,
a.hoverable-element:hover,
.hoverable-element:hover {
  color: var(--activeTextElementsOnHover) !important;
  text-decoration: none !important;

  * {
    color: var(--activeTextElementsOnHover) !important;
  }

  &.with-underline {
    text-decoration: underline !important;
  }
}

.search-results-tabs {
  .nav-tabs {
    .nav-item {
      a {
        &.nav-link.active, &.nav-link:hover {
          background-color: var(--searchResultsTabBgColor);
          border-color: var(--searchResultsTabBgColor);
        }

        &.nav-link:not(.active) {
          color: var(--searchResultsInactiveTabFontColor);
        }

        &.nav-link.active {
          color: var(--searchResultsActiveTabFontColor);
        }
      }
    }
  }
}

.no-padding,
*.no-padding,
* > .no-padding {
  padding: 0 !important;
}

.no-margin,
*.no-margin,
* > .no-margin {
  margin: 0 !important;
}

.mCustomScrollBox {
  max-height: none !important;
}

.account-number {
  display: var(--accountNumberDisplay);
}

.icon-with-custom-padding {
  padding-bottom: var(--offerPhotoPaddingBottom);
}

.statusOnOfferList {
  display: var(--statusOnOfferListStyle) !important;
}

.auctionDetailsNotPv {
  display: var(--auctionDetailsDisplay) !important;
}
