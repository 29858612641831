// adres
.footer-address {
  width: 100%;
  display: block;

  @include media-breakpoint-up(md) {
    margin-top: -9px;
    margin-bottom: 54px;
  }

  p {
    margin-bottom: 32px;
  }

  p, h1, li {
    font-family: var(--footerFontFamily) !important;
    color: var(--footerFontColor) !important;
  }
}

.footer-title {
  margin: 0 0 16px;
  font-size: 1.25rem;
  text-transform: uppercase;
}

.localization-title {
  line-height: 23px;
}

.footer-title-contact-link {
  width: 18px;
  height: 0;
  margin: 0 8px;
  padding-bottom: 23px;
  display: inline-block;
  vertical-align: text-bottom;
  background: url("../images/footer-marker.png") no-repeat 50% 50%;
  vertical-align: bottom;
  font-size: 0;
}

.footer-contacts-list {
  margin-bottom: 32px;

  li {
    justify-content: center;
    @include media-breakpoint-up(md) {
      justify-content: unset;
    }
  }
}
