.single-news {
  .page-header {
    margin-bottom: 42px;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .header-small-text {
      margin-bottom: 8px;
    }
  }

  .news-time {
    margin-bottom: 0;
    line-height: 1;
  }

  .news-content-section {
    margin-top: 80px;
    padding-bottom: 0;
  }

  .masonry-grid {
    padding-bottom: 140px;
  }

  .art-presentation-outer {
    margin-bottom: 0;
  }

  .images-presentation-images {
    margin-bottom: 10px;
  }
}

.news-internal-nav {
  @include media-breakpoint-only(xl) {
    max-width: 48%;
    left: 0;
    right: auto;
  }
}

.single-news-breadcrumb {
  margin-bottom: 36px;

  @include media-breakpoint-up(md) {
    margin-bottom: 56px;
  }
}

.news-presentation-text {
  margin-bottom: 50px;

  @include media-breakpoint-up(xl) {
    padding-right: $grid-gutter-width;
  }
}

.news-presentation-materials {
  @include media-breakpoint-up(xl) {
    padding-left: $grid-gutter-width;
  }
}

.single-news-title {
  margin-bottom: 6px;
  padding-left: 45px;
  padding-right: 45px;
  color: theme-color(primary);
  font-size: 1.5rem;
  line-height: 1.2;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    padding-left: 0;
    padding-right: 100px
  }
}

.news-time {
  font-size: var(--newsExhibitionDateFontSize);
}

.news-presentation-outer {
  margin-bottom: 10px;
}

.news-related {
  margin-top: 52px;
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  .content-section-subheader {
    margin-bottom: 6px;
    font-size: 1rem;
  }
}

.news-related-list {
  padding: 0;
  font-size: 1rem;
  list-style: none;

  .related-list-item {
    display: inline;

    &:after {
      content: ",";
    }

    &:last-child:after {
      content: "";
    }
  }

  .related-event-link {
    width: 100%;
    display: block;
  }

  .related-event-image-link {
    width: 70px;
    height: 44px;
    display: block;

    @include hoverable-dimmed();
    @include hoverable-zoomed(".related-event-link-bg");
  }

  .related-event-link-bg {
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(255, 255, 255, 0) no-repeat 50% 50%;
    background-size: cover;
  }
}

.news-aside-socials-share {
  @extend .artist-aside-socials-share;

  margin-left: 0;
  margin-right: 0;
  text-align: right;
}

.news-recommendations {
  .recommendations-title {
    margin-bottom: 10px;
  }

  .owl-carousel-outer {
    margin-top: 0;
    padding-bottom: 150px;
  }

  .owl-nav {
    @include media-breakpoint-only(xl) {
      max-width: 150px;
      top: -20px;
      left: auto;
      right: 0;
    }
  }
}

.news-related-section {
  margin: {
    top: 74px;
    bottom: 0;
  };
  padding: {
    left: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2);
  };

  &.related-works-catalogue {
    margin-top: 56px;
  }

  &.related-events {
    margin-top: 40px;
  }

  .recommendations-section-title {
    margin: {
      bottom: 22px;
      left: -($grid-gutter-width / 2);
      right: -($grid-gutter-width / 2);
    }
  }

  .recommendations-title {
    margin-bottom: 10px;
  }

  .tiles-container {
    margin: {
      left: 0;
      right: 0;
    };
  }

  .grid-item-figure {
    margin-bottom: 34px;
  }

  .work-sort {
    color: theme-color(primary);
    font-size: 1rem;
    font-weight: 700;
  }

  .carousel-article-link {
    color: theme-color(dark);
    text-transform: none;

    @include hover-focus {
      color: theme-color(primary);
    }
  }

  .carousel-article-text {
    &, p {
      color: theme-color(dark);
      font-size: 1rem;
    }
  }

  .carousel-article-dates {
    font-size: 1.125rem;
  }

  .carousel-home-mid {
    padding: {
      bottom: 0;
      left: 0;
      right: 0;
    };
  }

  .news-carousel {
    margin: {
      left: 0;
      right: 0;
    };

    .carousel-article-header {
      @include media-breakpoint-up(md) {
        margin-top: 10px;
      }
    }

    .carousel-article-title {
      line-height: 1.2;
    }
  }

  .news-row .news-figure-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

}

.news-related-events-carousel {
  position: relative;
}
