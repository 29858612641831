// newsletter
$newsletterFormPadding: (
  top: 46px,
  bottom: 58px,
  left: 50px,
  right: 34px
);

$newsletterFormPaddingLg: (
  top: 42px,
  bottom: 60px,
  left: 50px,
  right: 64px
);

.footer-newsletter-form {
  max-width: 310px;
  margin: 0 auto;
  padding: 46px 0 82px;
  color: $black;
  display: var(--newsletterDisplay);

  @include media-breakpoint-up(md) {
    max-width: 350px;
    margin: 0 0 0 auto;
    padding: 0 0 30px;
  }

  .newsletter-form {
    &.doing-submit {
      .footer-newsletter-form-email {
        background-image: url("../images/preloader.gif");
        background-position: 100% 50%;
        background-repeat: no-repeat;
      }
    }
  }

  .input-group {
    position: relative;
  }

  .form-control-invalid {
    position: absolute;
    top: 100%;
    bottom: -20px;
    left: 0;
    right: 66px;
    overflow: hidden;
    opacity: 0;
    transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
    color: $invalidFieldColor;
    font-size: .75rem;
    line-height: 20px;
    text-align: right;
    pointer-events: none;

    &.show {
      opacity: 1;
    }

    span {
      width: 100%;
      height: 20px;
      display: block;
    }
  }
}

.footer-newsletter-form-title {
  color: var(--footerFontColor);
  font-family: var(--footerFontFamily) !important;
  font-size: 1.125rem;
  line-height: 1.1;
  text-align: center;

  @media (max-width: 767px) {
    padding-bottom: 8px;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 10px;
    text-align: left;
  }
}

.footer-newsletter-form-email {
  padding-top: 6px;
  padding-bottom: 6px;
  border-width: 2px 0 2px 2px;
  border-color: var(--footerFontColor);
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  color: $black;
  font-size: 1rem;

  &:focus {
    border-color: $black;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0);
    color: $black;
  }
}

.footer-newsletter-request-submit {
  width: 60px;
  border: 2px solid var(--footerFontColor);
  background-color: rgba(0, 0, 0, 0);
  color: var(--footerFontColor);
  text-align: center;
  cursor: pointer;

  &[disabled] {
    opacity: .8;
    pointer-events: none;
  }

  @include hover-focus {
    background-color: var(--footerFontColor);
    color: theme-color(light);
  }
}
