.proudly-sold {
  .site-main {
    padding-bottom: 80px;
  }

  .breadcrumb {
    margin-bottom: 55px;
  }

  .page-title {
    margin-bottom: 34px;
    color: theme-color(primary);
    font-size: 1.5rem;
  }

  .masonry-grid {
    width: 100%;
  }

  .grid-item-title {
    margin-bottom: 4px;
  }

  .grid-item-text {
    margin-bottom: 8px;
  }

}
