.e404 {
  .site-main {
    padding-top: 64px;
    padding-bottom: 65px;
  }

  .page-title {
    color: theme-color(secondary);
    text-transform: none;
  }

  .e404-header {
    margin-bottom: 60px;

    p {
      font-size: 1.125rem;
      text-align: left;
    }
  }

  .recommendations-section {
    padding-bottom: 25px;
  }

  .articles-carousel-section {
    padding-top: 45px;
    padding-bottom: 30px;
  }

}
