.works {
  .site-main {
    //   padding-top:32px;
    padding-bottom: 150px;
  }

  .masonry-grid {
    padding-bottom: 60px;
  }

  .search-results {
    margin-top: 40px;
  }

  .items-basic-filters {
    padding: 13px 0 0;
  }

  .items-filters-top {
    position: relative;
    padding-bottom: 12px;

    &:before {
      width: 200vw;
      display: block;
      position: absolute;
      top: -13px;
      bottom: 0;
      left: -50vw;
      right: auto;
      background-color: var(--secondaryBackground);
      // z-index:-1;
      content: "";

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  .items-filters-bottom {
    padding-top: 12px;
    padding-bottom: 12px;

    @include media-breakpoint-up(md) {
      margin-left: -($grid-gutter-width / 2);
    }

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }
  }

  ul.pagination,
  ol.pagination {
    margin-top: 45px;
  }

  &.works-list-view {
    ul.pagination,
    ol.pagination {
      margin-top: 75px;
    }
  }

}

.recommendations-block {
  padding-top: 30px;
  padding-bottom: 20px;

  @include media-breakpoint-up(xl) {
    margin-top: 83px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.view-switch-wrapper {
  margin-left: 12px;
  text-align: right;
}

.offer-view-switches {
  .items-filter-group {
    &.show {

      .items-filter-btn {
        color: #ffffff;

        &:after {
          background-position: 0 0;
          opacity: 1;
        }

        &:hover {
          color: var(--secondary) !important;
        }
      }
    }

    .items-filter-btn {
      padding: 6px 28px 6px 6px;
      border-width: 0;
      background-color: rgba(255, 255, 255, 0);
      font-size: .875rem;
      text-align: left;

      @include hover-focus {
        color: rgba(theme-color(secondary), 1);

        &:after {
          background-position: 0 0;
          opaciy: 1;
        }
      }

      @include media-breakpoint-up(lg) {
        text-align: right;
      }

      &:after {
        top: 12px;
        right: 6px;
        opaciy: .75;
      }
    }
  }
}

.btn-view-switch {
  width: 22px;
  height: 21px;
  padding: 0;
  background: url("../images/btn-switch-view.png") no-repeat 0 0;
  overflow: hidden;
  text-indent: -9999px;

  &.btn-view-switch-is-tiles {
    background-position: -24px 0;

    @include hover-focus {
      background-position: -24px -21px;
    }
  }

  @include hover-focus {
    background-position: 0 -21px;
    box-shadow: none;
  }
}

// prace
.offer-items-list {
  margin-top: 24px;

  @include media-breakpoint-up(md) {
    margin-top: 6px;
  }
}

.offer-list-item {
  margin-bottom: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border: 1px solid rgba(theme-color(primary), 0);
  border-radius: 2px;
  transition: background-color $defaultTransitionDuration $defaultTransitionFunction;

  @include hover-focus {
    //background-color:map-get($masonryListColors, bgHover);
    // box-shadow:$hoverBoxShadow;

    .no-hover-effect & {
      background-color: map-get($masonryListColors, bgHover);
      // box-shadow:none;
    }

    .offer-item-more {
      opacity: 1;
    }
  }

  &:hover, &:focus {
    background-color: var(--secondaryBackground) !important;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;
  }

  &.offer-list-item-promoted {
    @include hover-focus {
      border-color: rgba(theme-color(primary), 1);
    }
  }
}

.offer-list-article {
  min-height: 250px;
}

.offer-list-figure {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding-bottom: var(--offerPhotoPaddingBottom);
}

.offer-list-figure-inner {
  margin: 0 auto;
  display: block;
  overflow: hidden;
  text-align: center;
}

.offer-list-image {
  max-height: 250px;
  margin: 0 auto;
}

.offer-list-content-wrapper {
  padding-top: 14px;

  .offer-item-description {
    font-size: var(--fontSizeUnderTitleOnPreviewForOffer) !important;
  }
}

.top-row {
  display: flex;
  flex-direction: row;

  .offer-item-link {
    width: calc(100% - 170px);
  }

  .droit-de-suite {
    width: 170px;
    padding-top: calc(var(--artistNameOnListPaddingTopForOffer) + 2px);
  }
}

.offer-item-link {
  display: block;
  color: theme-color(dark);

  @include hover-focus {
    //   color:theme-color(primary);
    color: theme-color(dark);
    text-decoration: none;
  }
}

.offer-item-title {
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 1.375rem;
  text-transform: uppercase;
}

.offer-item-text {
  margin-bottom: 20px;
  font-size: 1.125rem;
}

.offer-item-title,
.offer-item-text {
  color: inherit;
}

.offer-item-more {
  display: block;
  position: absolute;
  top: auto;
  bottom: 28px;
  left: auto;
  right: 18px;
  opacity: 0;
}

.reserved-item-info,
.sold-item-info {
  color: theme-color(primary);
  font-weight: 600;
}

.advanced-filters-content {
  z-index: 1;
}

.btn-advanced-filter {
  z-index: 0;
}

.works-per-page-dropdown {
  padding-top: 24px;
  position: relative;
  // overflow:hidden;

  @include media-breakpoint-up(sm) {
    padding-top: 0;
    overflow: visible;
  }

  .per-page-dropdown {
    border-radius: 2px;
    float: right;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: -55px;
      bottom: auto;
      left: auto;
      right: 0;
      float: none;
    }
  }

  &.no-pagination {
    padding: {
      top: 24px;
      bottom: 24px;
    };
    zoom: 1; // floatfix

    // floatfix:
    &::after {
      display: block;
      clear: both;
      content: "";
    }

    .per-page-dropdown {
      float: right;

      @include media-breakpoint-up(sm) {
        position: relative;
        top: 0;
      }
    }
  }

  .items-filter-btn {
    width: 100%;

    &::after {
      opacity: .6;
    }
  }

}

.zooming-mask {
  width: 100%;
  height: 0;
  opacity: 0;
  background-color: rgba(255, 255, 255, .7);
  transition: background-color 100ms ease-in;
  pointer-events: none;

  &.zooming-mask-visible {
    height: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    opacity: 1;
    z-index: 1080;
  }
}
.item-status-on-offers-list{
  display: var(--showStatusForOfferList);
}
