.privacy-policy {
  .site-main {
    padding-bottom: 60px;
  }

  .page-content {
    @include media-breakpoint-up(xl) {
      padding-right: 5px;
    }
  }

  .page-title {
    color: theme-color(secondary);
    text-transform: none;
  }

  p {
    margin-bottom: 2px;

    + h2 {
      margin-top: 24px;
    }
  }

  h2 {
    font-size: 1.5rem;
  }
}
