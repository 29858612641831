.about {
  .page-section {
    &.about-us-section {
      padding-top: 0;

      @include media-breakpoint-up(lg) {
        padding-bottom: 28px;
      }
    }
  }
}

.about-content {
  padding-bottom: 10px;

  @include media-breakpoint-up(lg) {
    padding-right: 20px;
    padding-bottom: 0;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 60px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

// partnerzy
.aside-partners-list {
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  list-style: none;
  text-align: left;

  @include media-breakpoint-up(lg) {
    text-align: center;
  }
}

.aside-partners-item {
  margin: 4px 2px;
  display: inline-block;
}

.about-us {
  .page-aside {
    @include media-breakpoint-up(lg) {
      margin-left: -($grid-gutter-width / 2);
      margin-right: -($grid-gutter-width / 2);
    }
  }

  .aside-title {
    text-align: left;

    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }
}

// usługi
.about-services-figure {
  @include media-breakpoint-up(lg) {
    padding-right: 30px;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 0;
  }
}

.services-figure {
  margin: 0 auto;
  display: block;
}

.about-services-content {
  @include media-breakpoint-up(xl) {
    padding-left: 100px;
  }
}
