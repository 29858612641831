.irs {
  margin-top: 3px;
}

.irs-line,
.irs-bar {
  height: 1px;
  cursor: w-resize;
}

.irs-line {
  background-color: #666;
}

.irs-bar {
  margin-top: -1px;
  background-color: theme-color(primary);
}

.irs-slider {
  width: 8px;
  height: 10px;
  margin-top: -5px;
  background-color: theme-color(primary);
  cursor: pointer;
}

.irs-to,
.irs-from,
.irs-single,
.irs-min,
.irs-max {
  display: none;
}
