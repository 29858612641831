.verification-page {
  .verification-content {
    min-height: 60vh;
    padding-top: 64px;
  }

  .page-title {
    text-align: center;
  }

  .submit-info {
    padding-bottom: 36px;
    display: none;
    position: relative;
    text-align: center;

    &.show {
      display: block;
    }

    &.submit-info-progress {
      &:after {
        background-image: url("../images/preloader.gif");
      }
    }

    &.submit-info-success {
      &:after {
        background-image: url("../images/icon-success.png");
      }
    }

    &.submit-info-error {
      &:after {
        background-image: url("../images/icon-error.png");
      }
    }

    &:after {
      width: 32px;
      height: 32px;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      content: "";
    }
  }
}
