.masonry-grid {
  padding-bottom: 80px;
  overflow: hidden;
}

ul.masonry-grid {
  padding-left: 0;
  list-style: none;
}

.masonry-grid-item {
  width: 100%;
  padding: 20px;
  background-color: map-get($masonryListColors, bg);
  float: left;
  transition: background-color $defaultTransitionDuration $defaultTransitionFunction;

  &.with-opened-zooming {
    transform: none !important; // pojawia się problem, jeśli
  }

  @include media-breakpoint-up(md) {
    width: 50%;
  }

  @include media-breakpoint-up(lg) {
    width: 33%;
  }

  @include media-breakpoint-up(xl) {
    width: 25%;

    #news-tab-content & {
      width: 33.3333%;
    }
  }

  @include hover-focus {
    background-color: map-get($masonryListColors, bgHover);

    .grid-item-figure::after {
      background-color: rgba(0, 0, 0, .3);
    }
  }

  @include hoverable-zoomed(".img-fluid");

  &.no-zoom {
    @include hover-focus-class {
      .img-fluid {
        transform: scale(1) !important;
      }
    }
  }
}

.masonry-grid-item-link {
  display: block;
  text-align: center;

  @include hover-focus {
    text-decoration: none;
    cursor: pointer;
  }
}

.masonry-grid-item-info {
  color: $body-color;
}

.grid-item-figure {
  &:not(.offer) {
    @include hoverable-dimmed();

    &::after {
      display: var(--sliderLayerDisplay);
    }
  }

  &.offer {
    .img-fluid {
      object-fit: contain;
    }
  }

  @include hoverable-zoomed(img);

  &.no-zoom {
    @include hover-focus-class {
      .img-fluid {
        transform: scale(1) !important;
      }
    }
  }

  img.img-fluid {
    height: 250px;
    object-fit: cover;
  }
}

.grid-item-link {
  padding: 20px;
  text-decoration: none;

  &:hover {
    background-color: var(--secondaryBackground);
  }

  @include hover-focus {
    text-decoration: none;
  }
}

.grid-item-title {
  margin: 30px 0 8px;
  font-size: 1.25rem;
  font-weight: 600;

  &, a & {
    color: var(--dark);
    text-decoration: none;

    @include hover-focus {
      text-decoration: none;
    }
  }

}

.grid-item-text {
  margin-bottom: 16px;
  color: var(--dark);
  font-size: 1.125rem;
}
