.owl-carousel-container {
  position: relative;
}

.owl-carousel-outer {
  margin: 0;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin: 0 -($grid-gutter-width / 2);
  }
}

.owl-carousel {
  margin: 0 auto;
}

.carousel-indicators {
  width: $carousel-indicator-width;
  margin-bottom: 0;
  top: 50%;
  left: auto;
  flex-direction: column;
  transform: translateY(-60%);

  .owl-dot {
    width: 12px;
    height: 12px;
    margin-bottom: 8px;
    border: 1px solid $carousel-indicator-border-color;
    border-radius: 50%;
    flex: 0 0 auto;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;

    @include hover-focus {
      background-color: $carousel-indicator-hover-bg;
    }

    &.active {
      background-color: $carousel-indicator-active-bg;
    }

    &:before,
    &:after {
      height: 4px;
    }

    &:before {
      top: -4px;
      bottom: auto;
    }

    &:after {
      top: auto;
      bottom: -4px;
    }
  }

}

.recommendations-carousel {
  @include make-container-max-widths($owlWrapperMaxWidths);
}

.owl-nav {
  margin-top: -25px;
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 0;
  right: 0;
  overflow: hidden;
  display: none;
  pointer-events: none;

  @include media-breakpoint-up(sm) {
    display: block;
    top: 33%;
  }

  @include media-breakpoint-up(xl) {
    left: $containersSpacingBase;
    right: $containersSpacingBase;
  }

  &.owl-nav-outer {
    @include media-breakpoint-up(md) {
      width: 120px;
      left: auto;
    }

    @media screen and (min-width: 1340px) {
      width: auto;
      left: $containersSpacingBase;
    }

    .exhibition & {
      top: 290px;

      @include media-breakpoint-up(md) {
        top: 260px;
      }

      @include media-breakpoint-up(lg) {
        top: 242px;
      }

      @include media-breakpoint-up(xl) {
        display: none;
      }

      @media screen and (min-width: 1340px) {
        display: block;
      }
    }
  }

  &.wrapper-container {
    left: 0;
    right: 0;
  }

  .owl-prev,
  .owl-next {
    width: 50px;
    height: 50px;
    border: map-get($pageNav, border);
    background-color: map-get($pageNav, bg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
    position: relative;
    transition: background-color $defaultTransitionDuration $defaultTransitionFunction,
    border-color $defaultTransitionDuration $defaultTransitionFunction;
    pointer-events: all;
    cursor: pointer;

    @include hover-focus {
      border: map-get($pageNav, borderHover);
      background-color: map-get($pageNav, bgHover);
      outline: 0 none;

      &:after {
        opacity: 1;
      }
    }

    &.disabled {
      opacity: .4;
      pointer-events: none;
    }

    &:after {
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
      content: "";
    }

    span {
      display: none;
    }
  }

  .owl-prev {
    background-image: url("../images/btn-prev.png");
    float: left;

    &:after {
      background-image: url("../images/btn-prev-hover.png");
    }
  }

  .owl-next {
    background-image: url("../images/btn-next.png");
    float: right;

    &:after {
      background-image: url("../images/btn-next-hover.png");
    }
  }
}

.owl-slide-link {
  display: block;
  transition: background-color $defaultTransitionDuration $defaultTransitionFunction;
  text-align: center;
  // @include hover-focus{
  //   .grid-item-figure::after{
  //     background-color:rgba(0,0,0,.3);
  //   }
  // }
  .grid-item-figure {
    padding-bottom: var(--offerPhotoPaddingBottom);

    img.img-fluid {
      height: auto;
    }
  }
}
