@keyframes closeRotation {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.cookies-message {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: ($zindex-fixed + 20);
  color: theme-color(primary);

  &.hide {
    display: none;
  }
}

.cookies-message-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.cookies-message-content {
  margin-left: -($grid-gutter-width / 2);
  margin-right: -($grid-gutter-width / 2);
  padding: 34px 40px 48px 70px;

  @include media-breakpoint-up(lg) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 100px;
    padding-right: 0;
    background-position: 0 50%;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 28px;
  }

  @include media-breakpoint-up(lg) {
    .cookie-image {
      mask-image: url("../../assets/svg/4_cookie.svg");
      mask-size: 59px 59px;
      mask-repeat: no-repeat;
      background-color: var(--primary);
      width: 59px;
      height: 59px;
      position: absolute;
      top: 50%;
      left: 6px;
      transform: translate(0px, -50%);
    }
  }
}

.cookies-message-title {
  margin-bottom: 12px;
  font-size: 1.5rem;

  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

.cookies-message-text {
  margin-bottom: 0;
  font-size: 1rem;

  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
  }
}

.cookies-message-link {
  padding-left: 15px;
  display: inline-block;
  font-weight: 700;
}

.cookies-close {
  width: 100%;
  max-width: 200px;
  height: 40px;
  // background:url("../images/btn-close-cookies.png") no-repeat 50% 50% !important;
  opacity: 1;
  // transform:rotate(0);
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 10px;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 34px;
    bottom: auto;
    right: 0;
    margin: 0;
  }

  @include hover-focus-active {
    outline: 0 none;
    background-color: #fff;
    color: var(--primary);
    // animation:closeRotation ($defaultTransitionDuration * 2) infinite;

    // &:after{
    //   opacity:0;
    //   transform:translate3d(-50%,-50%,0) rotate(90deg);
    // }
  }

  &:after {
    display: none;
    //   width:40px;
    //   height:40px;
    //   position:absolute;
    //     top:50%; bottom:auto;
    //     left:50%; right:auto;
    //   background:$white url("../images/btn-close-cookies.png") no-repeat 50% 50%;
    //   opacity:1;
    //   transform:translate3d(-50%,-50%,0) rotate(0);
    //   transition:transform $defaultTransitionDuration $defaultTransitionFunction;
    //   pointer-events:none;
    //   content:"";
  }
}
