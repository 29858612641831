$siteFooterPaddingTop: 45px;
$siteFooterMinusOffsetTop: 9px;

.site-footer {
  margin: 0 auto;
  padding: $siteFooterPaddingTop $containersSpacingBase 0;
  border-top: var(--footerTopBorderWidth) solid var(--footerTopBorderColor);
  background-color: var(--footerBackgroundColor);
  color: $black;
  text-align: center;

  > .container {
    @include make-container-max-widths($navbarWrapperMaxWidths);
  }

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.footer-notes {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--copyrightBackgroundColor);
  font-size: var(--copyrightFontSize) !important;

  @include media-breakpoint-up(md) {
    padding-left: $containersSpacingBase;
    padding-right: $containersSpacingBase;
  }

  p {
    margin: 0;
    font-size: var(--copyrightFontSize) !important;
  }

  a {
    color: inherit;
    font-size: var(--copyrightFontSize) !important;

    @include hover-focus {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.footer-privacy-policy {
  clear: both;

  .footer-privacy-policy-link {
    color: var(--footerFontColor);
    font-family: var(--footerFontFamily) !important;

    @include hover-focus {
      color: var(--footerFontColor);
    }
  }
}

.footer-copy-note,
.footer-powered-by {
  display: block;
  color: var(--copyrightFontColor);
}

.footer-copy-note {
  width: 100%;
  padding: 14px 0 6px;
  float: left;
  text-align: center;

  @include media-breakpoint-up(md) {
    width: 50%;
    padding: 8px 0;
    text-align: left;
  }
}

.footer-powered-by {
  width: 100%;
  padding: 6px 0 14px;
  float: right;
  text-align: center;

  @include media-breakpoint-up(md) {
    width: 50%;
    padding: 8px 0;
    text-align: right;
  }
}

// scrolltop
.scrolltop-outer {
  width: 50px;
  height: 50px;
  border: 2px solid theme-color(light);
  display: none;
  overflow: hidden;
  background: theme-color(light) url("../images/btn-scrolltop.png") no-repeat 0 0;
  transition: border-color $defaultTransitionDuration $defaultTransitionFunction, opacity $defaultTransitionDuration $defaultTransitionFunction;
  position: fixed;
  top: auto;
  bottom: 60px;
  left: auto;
  right: 50px;
  opacity: 0;
  text-indent: -999px;
  z-index: $zindex-modal;

  @include media-breakpoint-up(sm) {
    display: block;
  }

  @include hover-focus {
    border-color: theme-color(primary);

    &:after {
      opacity: 1;
    }
  }

  &:after {
    display: block;
    background: url("../images/btn-scrolltop.png") no-repeat 0 -50px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
    content: "";
  }

}

html:not([data-scroll='0']) {
  .scrolltop-outer {
    opacity: 1
  }
}

@import "footer/newsletter";
@import "footer/contact";
@import "footer/socials";
