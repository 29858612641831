.btn {
  padding: .5rem .75rem;
}

.btn-primary {
  border-width: 2px;
  z-index: 1;
  background-color: var(--primary);
  border-color: var(--primary);

  @include hover-focus {
    border-color: var(--primary);
    background-color: rgba(255, 255, 255, 1);
    color: var(--primary);
  }

}

.btn-wide {
  width: 100%;
}

.btn-contact-us,
.btn-offer-contact,
.btn-make-offer {
  max-width: 238px;
  font-size: var(--CTAFontSize);
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;

  .contact & {
    max-width: 100%;
    padding: 8px 26px;
    // font-weight:600;

    @include hover-focus {
      color: $white;
      // font-weight:400;
    }
  }
}

.btn-offer-contact {
  border-width: 2px;

  @include hover-focus {
    border-color: var(--primary);
  }

  @include media-breakpoint-up(md) {
    margin-left: 14px;

    &.btn-primary {
      margin-left: 0;
    }
  }
}

.btn-change-currency {
  border: 0 none;
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  background-position: -182px -2px;
  font-size: 0;
  text-indent: -9999px;

  &, &:after {
    width: 26px;
    height: 26px;
    background-image: url("../images/btn-socials.png");
    background-repeat: no-repeat;
  }

  @include hover-focus {
    outline: 0 none !important;
    cursor: pointer;

    &:after {
      opacity: 1;
    }
  }

  &:after {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: -182px -32px;
    opacity: 0;
    transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
    content: "";
  }
}
