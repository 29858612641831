.nav-tabs {
  .nav-link {
    color: var(--dark);

    @include hover-focus {
      color: $white;
    }

    &.active {
      color: theme-color(secondary);

      .artist-tabs & {
        background-color: theme-color(primary);
        color: $white;
      }

      @include hover-focus {
        background-color: theme-color(primary);
        color: $white;
      }
    }
  }
}

.tab-pane {
  padding-bottom: 30px;
}
