.text-image-wrapper {
  padding-top: 74px;
  padding-bottom: 150px;

  @include media-breakpoint-up(lg) {
    padding-top: 50px;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 110px;
  }

  .text-image-title {
    margin-bottom: 14px;
    color: var(--primary);
    font-weight: 400;
    font-size: 2rem;
    text-transform: uppercase;
  }

  .text-image-excerpt {
    margin-bottom: 38px;
    font-size: 1.125rem;
  }

  .text-image-content {
    font-size: 1rem;
    white-space: pre-line;
  }
}
