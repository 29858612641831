html:not([data-scroll='0']) {

  .navbar-search {
    @include media-breakpoint-up(lg) {
      &.has-separator {
        &:after {
          background-color: theme-color(secondary);
        }
      }
    }
  }

  .navbar-search-link {
    background-image: url("../images/btn-navbar-search.png");
  }
}

.navbar-search {
  display: var(--searchDisplay) !important;
  margin: 0 14px 0 auto;
  position: relative;

  &.d-none {
    display: none !important;
    @include media-breakpoint-up(lg) {
      &.d-lg-flex {
        display: var(--searchDisplay) !important;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.d-lg-none {
      display: none !important;
    }
    height: 36px;
    margin-left: 0;
    margin-right: 4px;

    &.has-separator {

      &:after {
        width: 1px;
        height: 18px;
        display: block;
        background-color: theme-color(secondary);
        position: absolute;
        top: 11px;
        bottom: auto;
        left: auto;
        right: 0;
        transition: background-color $defaultTransitionDuration $defaultTransitionFunction;
        content: "";

        .main-at-top & {
          background-color: #fff;
        }
      }
    }
  }
}

.navbar-search-link {
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  background: url("../images/btn-navbar-search.png") no-repeat 50% 50%;
  background-size: 30px 30px;
  overflow: hidden;
  color: #fff;
  line-height: 80px;
  text-align: center;
  text-indent: -9999px;
  vertical-align: middle;

  @include media-breakpoint-up(lg) {
    width: 40px;
    height: 26px;
    margin: auto;
    background-size: 15px 15px;
  }

  @include hover-focus {
    &:before {
      opacity: 1;
    }
  }

  .main-at-top & {
    background-image: url("../images/btn-navbar-search-alt.png");
  }

  &:before {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("../images/btn-navbar-search-hover.png") no-repeat 50% 50%;
    background-size: 30px 30px;
    opacity: 0;
    transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
    content: "";
    pointer-events: none;

    @include media-breakpoint-up(lg) {
      background-size: 15px 15px;
    }

  }
}

.search-modal {
  display: none;
}

.search-submit {
  width: 38px;
  height: 38px;
  margin-left: auto;
  padding: .5rem .75rem;
  border: 0 none;
  overflow: hidden;
  display: block;
  float: right;
  background: transparent url("../images/btn-navbar-search.png") no-repeat 50% 50%;
  text-indent: -9999px;
  cursor: pointer;
}
