ul.pagination,
ol.pagination {
  margin-bottom: 0;
  padding: 0;
}

.page-item {
  margin: 0 1px;
  text-align: center;

  .page-link {
    min-width: 52px;
    margin: 0;
    border-radius: 0 !important;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &.page-item-prev,
  &.page-item-next {
    overflow: hidden;
    text-indent: -999px;
  }

  &.page-item-prev {
    .page-link {
      background-image: url("../images/btn-prev.png");

      @include hover-focus {
        background-image: url("../images/btn-prev-hover.png");
      }
    }

    &.disabled {
      .page-link {
        background-image: url("../images/btn-prev-disabled.png");
      }
    }
  }

  &.page-item-next {
    .page-link {
      background-image: url("../images/btn-next.png");

      @include hover-focus {
        background-image: url("../images/btn-next-hover.png");
      }

      &.disabled {
        .page-link {
          background-image: url("../images/btn-next-disabled.png");
        }
      }
    }
  }

  &.active {
    .page-link {
      @include hover-focus {
        background-color: $pagination-hover-bg;
        color: $pagination-hover-color;
      }
    }
  }

}
