.artists {
  .site-main {
    min-height: 60vh;
    padding-top: 60px;
    padding-bottom: 180px;
  }

  .filters-container {
    margin: {
      bottom: 48px;
      left: -($grid-gutter-width / 2);
      right: -($grid-gutter-width / 2);
    };
    background-color: theme-color(light);
  }

  .filters-inner-container {
    margin: 0 auto;
    padding: 0 ($grid-gutter-width / 2);
    @include make-container-max-widths();
  }

  .filters-info-container {
    margin: 0 auto;
    @include make-container-max-widths();
  }

  .filter-letters-list {
    margin: 0 auto 0 0;
  }

  .filters-row {
    margin-top: -48px;
    margin-bottom: 18px;
  }

  .active-filters-set {
    padding-left: 12px;
    display: block;
    line-height: 24px;
  }

  .active-filter-item {
    margin-right: 8px;
  }

  .active-filter-item-text {
    &:before,
    &:after {
      content: "";
    }
  }

  .remove-advanced-filters-wrapper {
    margin: 0;
  }

  .remove-advanced-filters {
    padding: 0;
    background-color: rgba(0, 0, 0, 0);

    // .btn-advanced-filter-cancel{
    //   margin:{
    //     top:18px;
    //     bottom:22px;
    //   };
    //   padding:0;
    //   border-width:0;
    //   background-color:rgba(0,0,0,0);
    //   color:theme-color(primary);
    //   font-size:1rem;
    //   font-weight:700;
    //   line-height:25px;
    //
    //   @include media-breakpoint-up(lg){
    //     margin:{
    //       top:0;
    //       bottom:0;
    //     };
    //   }
    //
    //   &:before,
    //   &:after{
    //     display:none;
    //   }
    // }
  }

  .page-content {
    margin-bottom: 60px;
  }

  .page-pagination {
    margin-bottom: 40px;
  }

  .not-found-title {
    margin: {
      top: -7px;
      bottom: 58px;
    };

    @include media-breakpoint-up(lg) {
      margin: {
        top: 0;
        bottom: 8px;
      }
    }
  }

  .not-found-title {
    margin-bottom: 148px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }
}

.artists-preview-list {
  flex: 0 0 calc(100% - 330px);
  max-width: calc(100% - 330px);
  min-width: 200px;
  @include media-breakpoint-down(xs) {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
  }
}

.artists-preview-col {
  max-width: 330px;
  margin: {
    top: 0;
    right: -($grid-gutter-width / 2);
    bottom: 0;
    left: auto;
  };
  display: var(--artistListPhotoDisplay) !important;
  @include media-breakpoint-down(xs) {
    max-width: calc(100% - 205px);
    display: none !important;
  }
}

.artist-preview-column {
  // width:100%;
  height: 100%;
  min-height: 500px;
  // position:absolute !important;
  //   top:0;
  //   right:0;
}

.artists-list {
  margin: 0;
  padding: 0;
  list-style: none;
  columns: 1;
  line-height: 2;
  text-align: center;

  .artist-name {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  @include media-breakpoint-up(md) {
    line-height: 1.5;
    text-align: left;
  }

  @include media-breakpoint-up(lg) {
    columns: calc(var(--artistListColumns) - 1);
  }

  @include media-breakpoint-up(xl) {
    columns: var(--artistListColumns);
  }
}

.artist-name {
  display: block;
  font-size: 1.5rem;
}

.artist-link {
  color: var(--dark);
  transition: color $defaultTransitionDuration $defaultTransitionFunction;

  @include hover-focus {
    color: theme-color(tertiary);
    text-decoration: none;
  }
}

.artist-preview {
  max-width: 300px;
  margin: 12px 0 0 auto;
  
  &.sticky {
    position: fixed;
    top: 100px;
  }
}

.artist-preview-image {
  width: 100%;
  opacity: 1;
  display: block;
  transition: opacity $defaultTransitionDuration $defaultTransitionFunction;

  &.changing-preview {
    opacity: 0;
  }
}

.artists {
  .site-main {
    min-height: 60vh;
    padding-bottom: 30px;
  }

  .breadcrumb {
    @include media-breakpoint-up(lg) {
      margin-bottom: 54px;
    }
  }
}
