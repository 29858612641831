html {
  overflow-y: scroll;

  &.has-modal {
    overflow: hidden;
  }
}

body {
  border-width: var(--bodyBorderWidth);
  border-color: var(--bodyBorderColor);
  border-style: solid;

  &.modal-open {
    padding-right: 0 !important;
  }
}

.site-transition-preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 300ms ease-in-out,
  z-index 500ms ease-in;
  z-index: -99;
  pointer-events: none;

  &--visible {
    opacity: 1;
    z-index: 9999;
    pointer-events: all;
  }

  .loader-image {
    width: 50px;
    height: 50px;
    background: var(--primary);
    mask-image: url("../../assets/svg/3_loader.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100%;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
}

.page-wrapper {
  // max-width:1600px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: var(--mainBackground) !important;
  padding-bottom: 80px;
  @include media-breakpoint-up(md) {
    padding-bottom: calc(20px + var(--copyrightFontSize));
  }
}

.wrapper-container {
  @include make-container();
  @include make-container-max-widths($wrappercontainerMaxWidths);
}

.site-main {
  min-height: 60vh;
  margin-top: 90px;
  position: relative;
  transition: transform $defaultTransitionDuration $defaultTransitionFunction,
  margin-top 500ms $defaultTransitionFunction;

  // @include media-breakpoint-up(sm){
  //   margin-top:94px;
  // }

  @include media-breakpoint-up(lg) {
    margin-top: 124px;
  }

  // @include media-breakpoint-up(xl){
  //   margin-top:150px;
  // }

  .has-hero-image & {
    padding-top: 450px;

    @include media-breakpoint-up(lg) {
      padding-top: 550px;
    }
  }

  .main-at-top & {
    margin-top: 0;
  }
}

html:not([data-scroll='0']) {
  .home {
    .site-main {
      margin-top: 0;
    }
  }
}

.hero-image {
  height: 450px;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 1;
  transition: opacity $defaultTransitionDuration $defaultTransitionFunction;

  width: 100%;
  overflow: hidden;

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    background-size: auto;
  }

  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    background-size: cover;
  }

  &.hide {
    opacity: 0;
  }

  @include media-breakpoint-up(lg) {
    height: 550px;
  }

  &.hero-image-fixed {
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
    }

    @supports not (-webkit-touch-callout: none) {
      /* CSS for other than iOS devices */
      background-attachment: fixed;
    }
  }

  @include hoverable-dimmed(absolute, after, 0.3, 0.1);

  &::after {
    display: var(--sliderLayerDisplay);
  }
}

.section-title {
  margin-bottom: 6px;
  font-size: 1.5rem;
  text-transform: uppercase;
}

// nawigacja w ramach jednego typu treści
.internal-nav {
  padding: {
    left: 6px;
    right: 6px;
  };
  display: block;
  overflow: hidden;
  position: absolute;
  top: 90px;
  bottom: auto;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 10;

  @include media-breakpoint-up(md) {
    max-width: 150px;
    left: auto;
    right: 0;
  }

  @include media-breakpoint-up(lg) {
    max-width: map-get($wrappercontainerMaxWidths, lg);
    top: 270px;
  }

  @include media-breakpoint-up(xl) {
    max-width: map-get($wrappercontainerMaxWidths, xl);
    left: 0;
    right: 0;
  }

  @include media-breakpoint-up(xxl) {
    max-width: map-get($wrappercontainerMaxWidths, xxl);
  }
}

.internal-nav-prev,
.internal-nav-next {
  width: 50px;
  height: 50px;
  border: map-get($pageNav, border);
  background-color: map-get($pageNav, bg);
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: border-color $defaultTransitionDuration $defaultTransitionFunction,
  background-color $defaultTransitionDuration $defaultTransitionFunction;
  pointer-events: all;
  cursor: pointer;

  &.disabled {
    opacity: .4;
    pointer-events: none;
  }

  // &, .internal-nav-link{
  //   background:url("../images/btn-nav.png") no-repeat;
  // }

  @include hover-focus {
    border: map-get($pageNav, borderHover);
    background-color: map-get($pageNav, bgHover);

    .internal-nav-link {
      opacity: 1;
    }
  }

  .internal-nav-link {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
  }
}

.internal-nav-prev {
  background-image: url("../images/btn-prev.png");
  float: left;

  @include hover-focus {
    background-image: url("../images/btn-prev-hover.png");
  }

  @include media-breakpoint-up(xl) {
    margin-left: auto;
    margin-right: 14px;
  }

  @include media-breakpoint-up(xxl) {
    margin-left: 0;
    margin-right: auto;
  }

  .internal-nav-link {
    background-position: 0 -60px;
  }
}

.internal-nav-next {
  background-image: url("../images/btn-next.png");
  float: right;

  @include hover-focus {
    background-image: url("../images/btn-next-hover.png");
  }

  .internal-nav-link {
    background-position: -60px -60px;
  }
}

.internal-nav-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
}

// preloader
.page-preloader {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  // max-height:0;
  background-color: #fff;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
  transition: opacity ($defaultTransitionDuration * 3) $defaultTransitionFunction,
  z-index ($defaultTransitionDuration * 4) $defaultTransitionFunction;
  z-index: -99;
  display: flex;
  justify-content: center;
  align-items: center;

  &.show {
    opacity: 1;
    z-index: 999;
  }

  &.preloader-initialize {
    transition: none;
  }

  .recommended & {
    height: auto;
    min-height: 40vh;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .loader-image {
    width: 50px;
    height: 50px;
    background: var(--primary);
    mask-image: url("../../assets/svg/3_loader.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100%;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.close-icon {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0) url("../images/btn-x.png") no-repeat 50% 50%;
  transition: background-color $defaultTransitionDuration $defaultTransitionFunction;
  display: block;
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 0;
  opacity: 1;
  cursor: pointer;
  z-index: 10;

  @include hover-focus {
    background-color: theme-color(primary);

    &:before {
      opacity: 1;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: theme-color(primary) url("../images/btn-x--hover.png") no-repeat 50% 50%;
    opacity: 0;
    transition: opacity $defaultTransitionDuration $defaultTransitionFunction;
    content: "";
  }
}

@import "content";
