// hero
.hero-slider {
  margin-bottom: 0;
}

.carousel-home-hero {
  margin-left: -($grid-gutter-width / 2);
  margin-right: -($grid-gutter-width / 2);
  position: relative;

  .owl-item-wrapper {
    min-height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    @include hoverable-dimmed(relative, before);

    @include media-breakpoint-up(lg) {
      min-height: 450px;
    }
  }

  .carousel-content {
    position: absolute;
    top: 44%;
    bottom: auto;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .owl-item-title {
    margin-bottom: 16px;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
      font-size: 2.25rem;
    }

  }

  .owl-item-link {
    max-width: 252px;
    margin: 0 auto;
    padding: 8px 90px;
    border: 2px solid #fff;
    display: block;
    color: #fff;
    font-size: 1.25rem;
    line-height: 1.2;
    text-transform: uppercase;

    @include hover-focus {
      border-color: theme-color(primary);
      background-color: theme-color(primary);
      text-decoration: none;
    }
  }
}
