.recommended-items-section {
  padding: {
    top: 54px;
    bottom: 142px;
  }
;
  position: relative;

  .home & {
    padding: {
      top: 30px;
      bottom: 50px;
    };
  }

  .owl-nav.wrapper-container {
    margin-top: 0;
    display: block;
    top: 38%;
    z-index: 900;
  }

  .recommendations-nav {
    margin: 0 0 0 auto;

    @include media-breakpoint-up(md) {
      max-width: 100%;
      margin-right: auto;
    }
  }

  .grid-item-title {
    margin-top: 0;
    color: var(--dark);
    font-size: 1.25rem;
    font-weight: 600;
  }

  .grid-item-text {
    margin-bottom: 0;
    color: var(--dark);
    font-size: 1.125rem;
    font-weight: 400;
  }

}

.recommendations-section-title {
  margin: {
    top: 0;
    bottom: 24px;
    left: auto;
    right: auto;
  };
  padding: {
    top: 0;
    bottom: 0;
    left: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2);
  };

  .home & {
    margin-bottom: 14px;
  }

  @include make-container-max-widths();
}

.recommendations-title {
  width: 100%;
  margin: {
    top: 0;
    bottom: 0;
    // left:-($grid-gutter-width / 2);
    // right:-($grid-gutter-width / 2);
  };
  padding: {
    top: 0;
    bottom: 0;
    // left:($grid-gutter-width / 2);
    // right:($grid-gutter-width / 2);
  };
  font-size: 1.5rem;
  text-align: left;
  text-transform: uppercase;

  .home & {
    padding-bottom: 20px;
  }
}

.recommendations-carousel {
  padding: 0 ($grid-gutter-width / 2);
  @include make-container-max-widths($container-max-widths);
}
