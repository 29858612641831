html:not([data-scroll='0']) {
  .lang-link {
    @include media-breakpoint-up(lg) {
      background-color: map-get($langLink, bgOnFixed);
      color: var(--menuItemFontColor);
      font-family: var(--menuFontFamily) !important;

      @include hover-focus {
        background-color: map-get($langLink, bgOnFixedHover);
        color: map-get($langLink, colorOnFixedHover);
      }
    }
  }

  .lang-item-current .lang-link {
    color: map-get($langLinkActive, colorMobile);
    background-color: map-get($langLinkActive, bgMobile);

    @include media-breakpoint-up(lg) {
      background-color: map-get($langLinkActive, bgOnFixed);
      color: map-get($langLinkActive, colorOnFixed);

      @include hover-focus {
        background-color: map-get($langLinkActive, bgOnFixedHover);
        color: map-get($langLinkActive, colorOnFixedHover);
        text-decoration: none;
      }
    }
  }
}

.lang-menu {
  margin: 0;
  padding: 0;
  display: flex;
  align-self: center;
  list-style: none;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  @include media-breakpoint-up(lg) {
    margin-right: -4px;
  }
}

li {
  display: flex;
  align-items: center;
}

.lang-item {
  max-height: 58px;
  display: flex;
  text-transform: var(--menuItemTextTransform);
  font-size: var(--menuFontSize) !important;
  line-height: 1;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    text-align: center;
  }
}

.lang-link {
  padding: 8px 4px;
  display: inline-block;
  background-color: map-get($langLink, bgMobile);
  transition: color $defaultTransitionDuration $defaultTransitionFunction,
  background-color $defaultTransitionDuration $defaultTransitionFunction;
  color: var(--menuItemFontColor);
  font-family: var(--menuFontFamily) !important;
  text-decoration: none;

  span {
    font-family: var(--menuFontFamily) !important;
  }

  @include hover-focus {
    background-color: map-get($langLink, bgMobileHover);
    color: var(--menuItemFontColor);
    font-family: var(--menuFontFamily) !important;
    text-decoration: none;
  }

  @include media-breakpoint-up(lg) {
    background-color: map-get($langLink, bg);
    color: var(--menuItemFontColor);

    @include hover-focus {
      background-color: map-get($langLink, bgHover);
      color: map-get($langLink, colorHover);
    }

    .main-at-top & {
      background-color: map-get($langLink, bgAtTop);
      color: var(--menuItemFontColor);
      font-family: var(--menuFontFamily) !important;

      @include hover-focus {
        background-color: map-get($langLink, bgAtTopHover);
        color: map-get($langLink, colorAtTopHover);
      }
    }
  }

  .lang-item-current & {
    color: map-get($langLinkActive, colorMobile);
    background-color: map-get($langLinkActive, bgMobile);

    @include hover-focus {
      color: map-get($langLinkActive, colorMobileHover);
      background-color: map-get($langLinkActive, bgMobileHover);
    }

    @include media-breakpoint-up(lg) {
      background-color: map-get($langLinkActive, bg);
      color: map-get($langLinkActive, color);

      @include hover-focus {
        background-color: map-get($langLinkActive, bgHover);
        color: map-get($langLinkActive, colorHover);
      }

      .main-at-top & {
        background-color: map-get($langLinkActive, bgAtTop);
        color: map-get($langLinkActive, colorAtTop);

        @include hover-focus {
          background-color: map-get($langLinkActive, bgAtTopHover);
          color: map-get($langLinkActive, colorAtTopHover);
        }
      }
    }
  }
}
