html:not([data-scroll='0']) {
  .site-header:not(.single-menu-version) {
    height: 74px;
    // box-shadow:0 2px 6px 0 rgba(0,0,0,.25);
    box-shadow: map-get($siteHeader, boxShadowFixed);
    //background-color:map-get($siteHeader, bgMobileFixed);
    background-color: var(--menuScrollBackgroundColor) !important;
    align-items: center;
    padding-top: var(--menuPaddingTopAfterScroll) !important;
    top: 0;
    right: 0;
    left: 0;

    @include media-breakpoint-up(lg) {
      background-color: map-get($siteHeader, bgFixed);
    }
  }

  .site-header:not(.single-menu-version) {
    .navbar-brand {
      max-width: 176px;

      @include media-breakpoint-up(lg) {
        max-width: 160px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 160px;
      }

      &.navbar-brand-inactive {
        pointer-events: all;
      }

      .brand-logo-small {
        opacity: 1;
      }

      .brand-logo-large {
        opacity: 0 !important;
      }
    }
  }
}

.site-header {
  background-color: var(--menuBackgroundColor) !important;
  padding-top: var(--menuPaddingTop) !important;
  padding-bottom: var(--menuPaddingBottom) !important;
  border-bottom: var(--menuBottomBorderWidth) solid var(--menuBottomBorderColor);
  box-shadow: map-get($siteHeader, boxShadow);
  transition-property: height, border-color, border-width, background-color, box-shadow;
  transition-duration: $headerTransitionDuration;
  transition-timing-function: $headerTransitionFunction;
  font-family: var(--menuFontFamily) !important;

  top: var(--bodyBorderWidth);
  right: var(--bodyBorderWidth);
  left: var(--bodyBorderWidth);
  transition: top .1s, left .1s, right .1s;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    background-color: map-get($siteHeader, bg);
  }

  .navbar {
    padding-top: 0;
    height: 100%;

    > .container,
    > .container-fluid {
      flex-wrap: nowrap;
    }
  }
}

.navbar-brand {
  max-width: unset !important;
  padding-top: 0;
  margin-right: 0;
  margin-bottom: -10px;
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
  transition-property: max-width;
  transition-duration: $headerTransitionDuration;
  transition-timing-function: $headerTransitionFunction;
  z-index: 3;

  @include media-breakpoint-up(sm) {
    max-width: 150px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 160px;
    padding-top: 2px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 160px;
    margin-left: -($grid-gutter-width / 2);
    z-index: 10;
  }

  &.navbar-brand-inactive {
    pointer-events: none;
  }

  .brand-logo {
    max-width: 100%;
    transition: opacity 600ms ease-in,
    transform 200ms ease-in;
    object-fit: contain;
  }

  .brand-logo-small {
    opacity: 0;
    transform: translate3d(0, 0, 0) translate(0px, -50%);
    // transform:translate3d(-50%,-50%,0);
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: auto;
    transition: opacity 500ms ease-in 300ms;
  }

  .brand-logo-large {
    opacity: 1 !important;
    transition: opacity 500ms ease-in;

    // @include media-breakpoint-up(lg){
    //   opacity:1;
    // }

    // .main-at-top &{
    //   opacity:0;
    //
    //   @include media-breakpoint-up(lg){
    //     opacity:0;
    //   }
    // }
  }

  &.brand-small-delayed {
    .brand-logo-small {
      transition-delay: 0ms;
    }

    .brand-logo-large {
      transition-delay: 300ms;
    }
  }

}

@import "header/navbar";
@import "header/search";
@import "header/lang";
@import "header/submenu";
