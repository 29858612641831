.home-top-jumbotron {
  margin-bottom: 0;
  padding: {
    top: 50px;
    bottom: 30px;
  };
  background-color: var(--textBlockBackgroundColor);
  text-align: center;

  > .container {

    @include media-breakpoint-up(xl) {
      max-width: 1140px;
    }
  }

  h1 {
    color: var(--textBlockFontColor);
    font-size: var(--textBlockFontSize);
    font-family: var(--textBlockFontFamily);
    margin-bottom: 20px;
  }

  div {
    color: var(--textBlockFontColor);
    font-size: var(--textBlockFontSize);
    font-family: var(--textBlockFontFamily);
  }

  &.product-counter {

    p {
      &:first-child {
        margin-bottom: 12px;
      }
    }

    strong {
      color: theme-color(primary);
    }
  }

}

.home .home-top-jumbotron {
  background: var(--textBlockBackgroundColorForHomePage);
}
