.how-to-buy {
  .page-content {
    margin-bottom: 70px;
  }
}

.how-to-buy-content {
  font-size: 1.125rem;

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 6px;
    font-weight: 600;
  }

  h1, h2, h3 {
    text-transform: uppercase;
  }

  h1 {
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1.125rem
  }

  h3, h4 {
    font-size: 1.0625rem;
  }

  h5, h6 {
    font-size: 1rem;
  }

  p, ul, ol {
    margin-bottom: 14px;

    & + h1, & + h2, & + h3, & + h4, & + h5, & + h6 {
      margin-top: 24px;
    }
  }

  // .main-at-top &{
  //   margin-top:36px;
  // }
}
