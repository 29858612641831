.search-modal-backdrop.show {
  opacity: .95;
}

.search-modal {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      max-width: map-get($container-max-widths, $breakpoint);
    }
  }

  .modal-content {
    background-color: rgba(0, 0, 0, 0);
  }

  .search-phrase,
  .search-submit {
    width: 100%;
    height: 65px;
    border-style: solid;
    border-color: theme-color(secondary);
    font-size: 2rem;
  }

  .search-phrase {
    border-width: 1px;
    border-radius: 0;
    padding: 6px 12px;
    color: $black;
    font-weight: 300;

    &::placeholder {
      font-weight: 300;
    }

    @include hover-focus {
      background-color: rgba(255, 255, 255, 0);
    }

    @include media-breakpoint-up(sm) {
      border-width: 1px 0 1px 1px;
    }
  }

  .search-submit {
    border-width: 1px;
    background-image: none;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color $defaultTransitionDuration $defaultTransitionFunction, color $defaultTransitionDuration $defaultTransitionFunction;
    color: rgb(192, 57, 43);
    text-align: center;
    text-indent: 0;
    text-transform: uppercase;

    @include hover-focus {
      background-color: rgba(theme-color(primary), 1);
      color: $white;
    }

    @include media-breakpoint-up(sm) {
      border-width: 1px 1px 1px 0;
    }
  }
}
