$homeTopSliderHeight: (
  xs: 500px,
  lg: 752px
);

.carousel-home-top {
  position: relative;

  @include hover-focus {
    .carousel-item-bg:after {
      background-color: rgba(0, 0, 0, .1);
    }
  }

  .carousel-inner {
    padding-bottom: $homeTopSliderPadding;
  }

  .owl-item {
    width: 100%;
    padding-bottom: $homeTopSliderPadding;
  }

  .carousel-item-bg-position {
    width: 100%;
    min-height: map-get($homeTopSliderHeight, xs);;
    height: map-get($homeTopSliderHeight, xs);;
    // tu trzeba nadpisac w razuie gdyby user wprowadzil zle dane to stare ustawienie zadziala
    min-height: var(--slideHeight) !important;
    height: var(--slideHeight) !important;
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      min-height: map-get($homeTopSliderHeight, lg);
      height: map-get($homeTopSliderHeight, lg);
      // tu trzeba nadpisac w razuie gdyby user wprowadzil zle dane to stare ustawienie zadziala
      min-height: var(--slideHeight) !important;
      height: var(--slideHeight) !important;
    }
    @include media-breakpoint-down(lg) {
      min-height: map-get($homeTopSliderHeight, lg);
      height: map-get($homeTopSliderHeight, lg);
      // tu trzeba nadpisac w razuie gdyby user wprowadzil zle dane to stare ustawienie zadziala
      min-height: var(--slideHeightTablet) !important;
      height: var(--slideHeightTablet) !important;
    }
    @include media-breakpoint-down(md) {
      min-height: map-get($homeTopSliderHeight, lg);
      height: map-get($homeTopSliderHeight, lg);
      // tu trzeba nadpisac w razuie gdyby user wprowadzil zle dane to stare ustawienie zadziala
      min-height: var(--slideHeightSmartphone) !important;
      height: var(--slideHeightSmartphone) !important;
      margin-top: var(--slideMarginTopSmartphone);
    }
  }

  .carousel-item-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    transform: rotate(0) scale(1);
    transition: transform 12s ease-in-out;

    @include hoverable-dimmed(absolute, after, 0.3, 0.1);

    &::after {
      display: var(--sliderLayerDisplay);
    }
  }

  .active {
    .carousel-item-bg {
      transform: rotate(1.5deg) scale(1.1);
    }
  }

  .owl-translating .active {
    .carousel-item-bg {
      transform: rotate(0) scale(1);
    }
  }

  .carousel-article {
    height: 0;
    margin: 0 auto;
    padding-bottom: map-get($homeTopSliderHeight, xs);
    padding-bottom: var(--slideHeight) !important; // tu trzeba nadpisac w razuie gdyby user wprowadzil zle dane to stare ustawienie zadziala
    position: relative;

    @include make-container-max-widths($wrappercontainerMaxWidths);

    @include media-breakpoint-up(lg) {
      padding-bottom: map-get($homeTopSliderHeight, lg);
      padding-bottom: var(--slideHeight) !important; // tu trzeba nadpisac w razuie gdyby user wprowadzil zle dane to stare ustawienie zadziala
    }
    @include media-breakpoint-down(lg) {
      padding-bottom: map-get($homeTopSliderHeight, lg);
      padding-bottom: var(--slideHeightTablet) !important; // tu trzeba nadpisac w razuie gdyby user wprowadzil zle dane to stare ustawienie zadziala
    }
    @include media-breakpoint-down(md) {
      padding-bottom: map-get($homeTopSliderHeight, lg);
      padding-bottom: var(--slideHeightSmartphone) !important; // tu trzeba nadpisac w razuie gdyby user wprowadzil zle dane to stare ustawienie zadziala
    }
  }

  .carousel-bg-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
  }

  .carousel-caption {
    max-width: 768px;
    background-color: var(--sliderCaptionBackgroundColor);
    position: absolute;
    top: var(--sliderCaptionTop) !important;
    left: var(--sliderCaptionLeft) !important;
    transform: var(--sliderCaptionTransform) !important;
    right: unset;
    bottom: unset;
    padding: 0 !important;
  }

  .carousel-article-title,
  .carousel-article-text {
    text-align: left;
  }

  .carousel-article-title,
  .carousel-article-link {
    display: block;
    color: var(--sliderCaptionFontColor);
  }

  .carousel-article-link {
    @include hover-focus {
      text-decoration: none;
    }

    .carousel-article-title {
      @include hover-focus {
        text-decoration: underline;
      }
    }
  }

  .carousel-article-title {
    padding: var(--sliderCaptionTitlePadding) !important;
    font-family: var(--sliderCaptionTitleFontFamily) !important;
    font-size: var(--sliderCaptionTitleFontSize) !important;
    font-weight: var(--sliderCaptionTitleFontWeight) !important;
    line-height: 1.3333;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
  }

  .carousel-article-text {
    padding: var(--sliderCaptionPadding) !important;
    font-size: var(--sliderCaptionParagraphFontSize) !important;
    font-family: var(--sliderCaptionParagraphFontFamily) !important;
    color: var(--sliderCaptionFontColor);
  }
}
