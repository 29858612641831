.single-auction {
  .site-main {
    margin-top: 156px;
  }

  .page-header {
    margin-bottom: 42px;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 30px;
      margin-bottom: 24px;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: auto;
    }
  }

  .auction-title {
    font-size: var(--auctionTitleFontSize);
    padding-left: 45px;
    padding-right: 45px;
    text-transform: var(--auctionTitleTextTransform);
    color: var(--auctionTitleColor);
    padding-top: var(--auctionTitlePaddingTop);
    padding-bottom: var(--auctionTitlePaddingBottom);

    a:first-child {
      color: var(--auctionTitleColor) !important;
    }

    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: 100px;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .auction-time,
  .auction-place {
    font-size: 1.25rem;
  }

  .auction-before {
    margin-top: 16px;
    margin-bottom: 6px;
    font-size: 1.125rem;

    @include media-breakpoint-up(xl) {
      margin-top: auto;
    }
  }

  .auction-time {
    margin-bottom: 0;
    line-height: 1;
    font-size: var(--auctionDateFontSize);
    padding-bottom: var(--auctionDatePaddingBottom);
  }

  .auction-place {
    margin-bottom: 0;
    font-size: var(--auctionPlaceFontSize);
    padding-bottom: var(--auctionPlacePaddingBottom);
  }

  .auction-main-container {
    padding-bottom: 60px;
  }

  .auction-content-section {
    padding-bottom: 0;

    .section-title {
      margin-bottom: 14px;
    }
  }

  .auction-presentation-text {
    padding-bottom: 30px;

    @include media-breakpoint-up(xl) {
      padding-left: $grid-gutter-width + ($grid-gutter-width / 2);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .auction-figure-wrapper {
    @include media-breakpoint-up(lg) {
      padding-right: 0;
    }
  }

  .advanced-filters {
    &:after {
      display: none;
    }
  }

  .internal-nav {
    top: 46px;
    // top:60px;

    @include media-breakpoint-up(md) {
      top: 40px;
      // top:80px;
    }

    @include media-breakpoint-up(lg) {
      left: auto;
      right: 0;
      max-width: 1140px;
      top: 270px;

      .internal-nav-prev {
        margin-left: auto;
        margin-right: 14px;
      }
    }

    @include media-breakpoint-up(xl) {
      max-width: map-get($wrappercontainerMaxWidths, xl);
      left: 0;
      right: 0;
    }

    @include media-breakpoint-up(xxl) {
      max-width: map-get($wrappercontainerMaxWidths, xxl);
    }
  }

  .masonry-grid {
    padding-bottom: 140px;
  }

  .search-results {
    margin-top: 40px;
  }

}

.auction-internal-nav {
  @include media-breakpoint-only(xl) {
    max-width: 48%;
    left: 0;
    right: auto;
  }
}

.single-auction-breadcrumb {
  margin-bottom: 12px;
  // margin-bottom:36px;
  //
  // @include media-breakpoint-up(md){
  //   margin-bottom:56px;
  // }
}

.auction-presentation-outer {
  margin-bottom: 10px;
}

.auction-subheader {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

.auction-info-list {
  margin: 0 0 8px 0;
  padding: 0;
  list-style: none;

  li {
    font-size: 1rem;

    a {
      color: $body-color;
    }
  }
}

.auction-links-wrap {
  position: relative;

  @include media-breakpoint-up(lg) {
    margin-bottom: 30px;
  }
}

.auction-links-list {
  margin: .5rem 0 8px .5rem;
  padding: 0;
  list-style: none;

  .single-auction & {

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: auto;
      bottom: 0;
      left: auto;
      right: 0;
    }

    li {
      margin-bottom: 8px;
      line-height: 1;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  li {
    font-size: 1rem;
    text-align: right;
  }

  a {
    color: $body-color;

    @include hover-focus {
      color: theme-color(tertiary);
    }
  }
}

.auction-catalogue-sorting {
  text-align: right;
}

.auction-catalogue-filters {
  padding-top: 6px;
  padding-bottom: 6px;
  // padding-top:12px;
  // padding-bottom:12px;
  background-color: theme-color(light);

  .auction-catalogue-filter {
    @include media-breakpoint-up(xl) {
      max-width: 20%;
    }
  }

  .advanced-filters-content {
    .items-filter-group,
    .input-group {
      margin-top: 6px;
      margin-bottom: 6px;
      background-color: $white;

      @include media-breakpoint-up(xl) {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }

  .input-group {
    // padding-top:16px;
    // padding-bottom:12px;

    &:hover {
      .search-phrase,
      .search-submit {
        border-color: theme-color(primary);
      }
    }
  }

  .search-phrase,
  .search-submit {
    height: 34px;
    border-color: theme-color(secondary);
    border-style: solid;
    border-radius: 0;
  }

  .search-phrase {
    padding-top: 4px;
    padding-bottom: 4px;
    border-width: 1px 0 1px 1px;

    @include hover-focus {
      border-color: theme-color(primary);

      & + .search-submit {
        border-color: theme-color(primary);
      }
    }
  }

  .search-submit {
    border-width: 1px 1px 1px 0;
    background-color: $white;
    background-size: 14px 14px;

    @include hover-focus {
      border-color: theme-color(primary);
    }
  }

}

.active-auction-filters {
  @include media-breakpoint-up(lg) {
    .active-filter-advanced-wrapper {
      max-width: 20%;
    }
  }
}

.auction-catalogue-list {
  padding: 40px 0 80px;
  font-size: 1rem;
  list-style: none;

  .catalogue-list-item {
    margin-bottom: 30px;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color $defaultTransitionDuration $defaultTransitionFunction;

    @include hover-focus {
      background-color: rgba(theme-color(light), 1);
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 12px;
    }
  }

  .grid-item-inner {
    padding: 20px;
  }

  .grid-item-image {
    text-align: center;
  }

  .auction-work-info,
  .auction-catalogue-id {
    font-size: var(--fontSizeUnderTitleOnPreviewForOffer) !important;
  }

  .auction-work-info {
    margin: 0 !important;
  }

  .catalogue-list-item-link {
    margin-bottom: 18px;
    display: inline-block;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-bottom: auto;
    }

    // @include media-breakpoint-up(lg) {
    //   margin-bottom:0;
    // }

    @include hover-focus {
      text-decoration: none;
    }
  }

  .grid-item-figure {
    max-width: 100%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      //  max-width:220px;
      margin-bottom: 0;
    }

    .catalogue-list-item-link {
      margin: 0 auto;

      @include hoverable-zoomed(img);

      &.no-zoom {
        @include hover-focus-class {
          .img-fluid {
            transform: scale(1) !important;
          }
        }
      }
    }

    .img-fluid {
      max-height: 240px;
      display: block;
    }
  }

  .grid-item-content {
    @include media-breakpoint-up(md) {
      padding-left: 25px;
      padding-bottom: 6px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }

  .grid-item-title {
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .grid-item-text {
    margin-bottom: 0;
    font-size: 1.125rem;
  }

  .btn-make-order {
    width: 100%;
    text-transform: uppercase;
    font-size: var(--CTAFontSize);
  }

}

.auction-catalogue-id {
  margin: 0;
  display: block;
  color: theme-color(dark);
  font-size: 1rem;
  line-height: 1;
  text-align: left;
}

.auction-sale-info {
  margin-top: 0;

  &.auction-sale-info-hasspace {
    margin-top: 38px;
  }
}

.auction-info-line {
  width: 100%;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  line-height: 1.1;

  + .auction-info-line {
    padding-top: 8px;
  }
}

.auction-droit-de-suite,
.auction-guaranteed-price {
  margin-top: auto;
  margin-bottom: 0;
}

@include media-breakpoint-up(lg) {

  .auction-droit-de-suite,
  .auction-guaranteed-price {
    margin-left: 10px;
  }

}

.auction-starting-price,
.auction-estimation {
  font-weight: 300;

  @include media-breakpoint-up(lg) {
    margin-right: -($grid-gutter-width / 2);
  }
}

.auction-estimation {
  padding-bottom: 16px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }
}

.auction-price-bid {
  padding-top: 12px;
  padding-left: 16px;
  color: theme-color(primary);
  line-height: 1.1;

  @include media-breakpoint-up(sm) {
    margin-top: 12px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 0;
    padding-top: 8px;
    padding-left: 0;
  }
}

.auction-starting-price,
.auction-estimation,
.auction-price-bid {
  margin-bottom: 0;
  font-size: 1.125rem;

  .auction-line-price {
    // margin-left:16px;
    margin-left: 10px;
    display: inline-block;
    font-weight: 600;
  }
}

.auction-starting-price {
  color: #000;

  a & {
    color: theme-color(dark);
  }

  a:hover &,
  a:focus & {
    color: theme-color(primary);
  }
}

.auction-droit-de-suite {
  a {
    color: inherit;

    @include hover-focus {
      color: theme-color(primary);
    }
  }
}

.auction-conditional-wrapper {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: 50%;
  }

  @include media-breakpoint-up(md) {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    width: 50%;
  }

  @include media-breakpoint-up(xl) {
    width: 100%;
  }
}

.auction-ask,
.auction-btn-wrap {
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 50%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 200px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 0;
  }
}

.auction-btn-wrap-container .auction-btn-wrap {
  display: var(--auctionOrderButtonDisplay);
}

.auction-btn-wrap {
  margin: 18px auto 0 0;
  @include media-breakpoint-up(lg) {
    padding-left: ($grid-gutter-width / 2);
  }

  @include media-breakpoint-up(xl) {
    margin-left: 0;
  }
}

.auction-ask {
  margin: 12px auto 0 0;
  padding-left: 16px;
  font-size: var(--CTAFontSize);

  @include media-breakpoint-up(sm) {
    margin-top: 24px;
  }

  @include media-breakpoint-up(lg) {
    // padding-left:0;
    text-align: center;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 0;
  }

  + .auction-conditional-sale {
    @include media-breakpoint-up(xl) {
      margin-top: 12px;
    }
  }

  + .auction-btn-wrap {
    margin-top: 12px;
  }

  a {
    text-transform: uppercase;
  }
}

.auction-conditional-sale {
  margin-top: 8px;

  + .auction-btn-wrap {
    margin-top: 12px;
  }
}

.auction-aside-socials-share {
  @extend .artist-aside-socials-share;

  margin-left: 0;
  margin-right: 0;
  position: absolute;
  top: auto;
  bottom: 0;
  left: ($grid-gutter-width / 2);
  right: ($grid-gutter-width / 2);
  text-align: right;
}

.auction-presentation-materials {
  margin-top: 38px;
  font-size: 1rem;
}
