.partners-block {
  padding: {
    top: 60px;
    bottom: 100px;
  };

  .partners-title {
    margin-bottom: 1rem;
    color: theme-color(primary);
    font-size: 1.5rem;
    text-align: left;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      text-align: center;
    }
  }

  .partners-list {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    text-align: left;
  }

  .partners-item {
    width: 100%;
    max-width: 50%;
    height: 0;
    margin: 4px 0;
    padding-bottom: 100%;
    display: flex;
    position: relative;

    @include media-breakpoint-up(sm) {
      max-width: 33.3333%;
      padding-bottom: 33.3333%;
    }

    @include media-breakpoint-up(md) {
      max-width: 25%;
      padding-bottom: 25%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 16.6666%;
      padding-bottom: 16.6666%;
    }
  }

  .partner-link,
  .partner-nolink {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 2px;
    right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .partner-logo {
    max-height: 100%;
    vertical-align: middle;
  }
}
