.upload-form {
  padding-top: 46px;

  &__title {
    margin-bottom: 22px;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
  }

  &__subtitle {
    margin-bottom: 10px;
    font-size: 1.125rem;
  }

  &__body {
    padding: {
      top: 56px;
      bottom: 80px;
    };
    background-color: rgba(242, 242, 242, .51);
    position: relative;

    &.row {
      @include media-breakpoint-up(sm) {
        margin: {
          left: 0;
          right: 0;
        };
      }
    }
  }

  &__row {
    > .form-control-outer {
      @include media-breakpoint-up(sm) {
        padding: {
          left: 0;
          right: 0;
        };
      }
    }
  }

  &__col {
    margin-bottom: 54px;

    &--first {
      @include media-breakpoint-up(sm) {
        .upload-form__subtitle {
          padding: {
            left: 5px;
            right: 5px;
          };
        }
      }

      @include media-breakpoint-up(lg) {
        margin-right: 80px;
      }

      @include media-breakpoint-up(xl) {
        margin-right: 124px;
      }
    }

    &--second {
      @include media-breakpoint-up(sm) {
        .upload-form__subtitle {
          padding: {
            left: 5px;
            right: 5px;
          };
        }
      }

      @include media-breakpoint-up(md) {
        .upload-form__subtitle {
          padding: {
            left: 35px;
          };
        }

        .upload-form__section-wrap {
          paddin: {
            left: 5px;
            left: 5px;
          };
        }
      }

      @include media-breakpoint-up(lg) {
        .upload-form__subtitle {
          padding: {
            left: 5px;
          };
        }
      }

      @include media-breakpoint-up(xl) {
        padding: {
          right: 58px !important;
        };

        .upload-form__subtitle {
          padding: {
            left: 0;
          };
        }
      }
    }
  }

  &__label {
    @include media-breakpoint-up(sm) {
      padding: {
        right: 10px !important;
      };
    }

    &--captcha {
      margin-bottom: 8px;
      padding: 0 5px;
      display: block;
      text-align: right;

      @include media-breakpoint-up(sm) {
        max-width: 306px;
        text-align: left;
      }

      @include media-breakpoint-up(lg) {
        max-width: 306px;
        text-align: right;
      }
    }

    &--file {
      width: 100%;
      max-width: 304px;
      min-height: 0;
      margin: {
        left: auto;
        right: 0;
        bottom: 36px;
      };
      padding: 45px 8px 8px 8px;
      border: 1px dashed theme-color(primary);
      background-color: #ffff;
      display: block;
      position: relative;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        max-width: 302px;
      }

      @include media-breakpoint-up(xl) {
        margin: {
          left: 0;
          right: auto;
        };
      }

      &.is-invalid {
        border-color: rgba($invalidFieldColor, .8);
      }
    }
  }

  .upload-form__label {
    text-align: right;

    @include media-breakpoint-up(sm) {
      text-align: left;
    }
  }

  &__control {
    &,
    &[type="text"],
    &[type="tel"],
    &[type="email"] {
      padding: {
        top: 5px;
        bottom: 5px;
      };
    }

  }

  &__files {
    padding: 0 !important;
  }

  &__files-field-wrapper {
    width: .1px;
    height: .1px;
    opacity: 0;
    display: block;
    overflow: hidden;
  }

  &__files-field {
    width: .1px;
    height: .1px;
  }

  &__files-info {
    height: 23px;
    position: absolute;
    top: 14px;
    bottom: auto;
    left: 0;
    right: 0;
    display: block;
    color: theme-color(primary);
    text-align: center;

    .is-invalid & {
      color: rgba($invalidFieldColor, .8);
    }

    &--error {
      height: 46px;
      padding: 0 8px;
      display: flex;
      align-content: center;
      align-items: center;
      top: 0;
      color: $invalidFieldColor;
      font-size: .75rem;
      vertical-align: middle;
    }
  }

  &__files-item {
    width: 100%;
    padding: 6px;
    margin-bottom: 4px;
    border: 1px solid var(--primary);
    border-radius: 2px;
    overflow: hidden;
    display: block;
    color: var(--primary);
    font-size: .875rem;
  }

  &__file-close {
    float: right;
  }

  &__disclaimer {
    margin-bottom: 0;
    color: theme-color(secondary);
    font-size: .875rem;
  }

  &__message {
    border: 1px solid theme-color(light);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
  }

  &__message-content {
    position: absolute;
    top: 50%;
    bottom: auto;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
  }

}
