.slider-figure-wrapper {
  @include hoverable-dimmed(relative, after, 0, 0.3);
  @include hoverable-zoomed(img);
}

.owl-dot {
  padding: 0;
}

@import "carousels/top";
@import "carousels/mid";
@import "carousels/hero";
