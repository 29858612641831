$navbarWrapperMaxWidths: $wrappercontainerMaxWidths;
$secondaryColor: theme-color(secondary);

html:not([data-scroll='0']) {
 header:not(.single-menu-version) {
    .navbar {
      border-bottom-color: rgba(theme-color(light), 0);

      @include media-breakpoint-up(sm) {
        padding: {
          top: 0;
          bottom: 14px;
        }
      }

      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 38 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{(map-get($hamburgerMenu, linesColorOnFixed))}' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' d='M3 3h32M3 15h32M3 27h32'/%3E%3C/svg%3E");
      }

      > .container {
        @include media-breakpoint-up(lg) {
          align-items: baseline;
        }
      }
    }

    .navbar-menu {
      .nav-link {
        @include media-breakpoint-up(lg) {
          background-color: map-get($menuLink, bgOnFixed);
          color: map-get($menuLink, colorOnFixed);

          @include hover-focus {
            background-color: map-get($menuLink, bgOnFixedHover);
            color: map-get($menuLink, colorOnFixedHover);
          }
        }
      }

      .active .nav-link {
        @include media-breakpoint-up(lg) {
          background-color: map-get($menuLinkActive, bgOnFixed);
          color: map-get($menuLinkActive, colorOnFixed);

          @include hover-focus {
            background-color: map-get($menuLinkActive, bgOnFixedHover);
            color: map-get($menuLinkActive, colorOnFixedHover);
          }
        }
      }
    }

    .navbar-menus-wrapper {
      @include media-breakpoint-up(lg) {
        padding-top: 19px;
        align-items: center;
      }

      @include media-breakpoint-up(xl) {
        padding-top: 27px;
      }
    }

    .navbar-collapse {
      @include media-breakpoint-up(lg) {
        margin-right: 0;
      }

      @include media-breakpoint-up(xl) {
        margin-right: -($grid-gutter-width / 2);
      }
    }
  }
}


html.has-open-menu {
  max-height: 100vh;
  overflow: hidden;
}

.navbar {
  padding-top: 36px;
  padding-bottom: 16px;
  background-color: map-get($menu, bgMobile);
  transition-property: background-color;
  transition-duration: 900ms;
  transition-timing-function: $headerTransitionFunction;

  .site-header-animated & {
    transition-property: background-color, padding;
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
    margin: {
      left: auto;
      right: auto;
    }
    padding-top: 18px;
    // border-bottom:1px solid rgba(theme-color(light),1);
    background-color: map-get($menu, bg);
    align-items: flex-start;

    // .home &{
    //   border-bottom-color:rgba(theme-color(light),0);
    // }

    & > .container {
      align-items: flex-start;
    }
  }

  > .container {
    @include make-container-max-widths($navbarWrapperMaxWidths);

    @include media-breakpoint-up(lg) {
      align-items: flex-end;
    }
  }

  .navbar-toggler {
    color: rgba(255, 255, 255, 1);
    border-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .navbar-toggler-icon {
    width: 38px;
    height: 32px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 38 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{(map-get($hamburgerMenu, linesColor))}' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' d='M3 3h32M3 15h32M3 27h32'/%3E%3C/svg%3E");

    .main-at-top & {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 38 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{(map-get($hamburgerMenu, linesColorAtTop))}' stroke-width='4' stroke-linecap='round' stroke-miterlimit='10' d='M3 3h32M3 15h32M3 27h32'/%3E%3C/svg%3E");
    }
  }
}

.navbar-menu {
  display: block;
  width: 100%;

  @include media-breakpoint-up(lg) {
    margin-left: auto;
    margin-right: 2.35vw;
    display: flex;
    flex-direction: row;
    width: auto;
  }

  .nav-link {
    background-color: map-get($menuLink, bgMobile);
    display: inline-block;
    position: relative;
    transition-property: color, background-color;
    transition-duration: $defaultTransitionDuration;
    transition-timing-function: $defaultTransitionFunction;
    color: map-get($menuLink, colorMobile);
    line-height: 1;
    text-decoration: none;
    text-transform: var(--menuItemTextTransform);

    @include hover-focus {
      background-color: map-get($menuLink, bgMobileHover);
      color: map-get($menuLink, colorMobileHover);
      text-decoration: none;
    }

    @include media-breakpoint-up(lg) {
      padding: {
        top: map-get($menuLinkPadding, lgTop);
        bottom: map-get($menuLinkPadding, lgBottom);
        left: 18px !important;
        right: 18px !important;
      }
      background-color: map-get($menuLink, bg);
      color: map-get($menuLink, color);

      @include hover-focus {
        background-color: map-get($menuLink, bgHover);
        color: map-get($menuLink, colorHover);
      }

      .main-at-top & {
        background-color: map-get($menuLink, bgAtTop);
        color: map-get($menuLink, colorAtTop);

        @include hover-focus {
          background-color: map-get($menuLink, bgAtTopHover);
          color: map-get($menuLink, colorAtTopHover);
        }
      }
    }
  }

  .active .nav-link {
    background-color: map-get($menuLinkActive, bgMobile);
    color: map-get($menuLinkActive, colorMobile);

    @include hover-focus {
      background-color: map-get($menuLinkActive, bgMobileHover);
      color: map-get($menuLinkActive, colorMobileHover);
    }

    @include media-breakpoint-up(lg) {
      background-color: map-get($menuLinkActive, bg);
      color: map-get($menuLinkActive, color);

      @include hover-focus {
        background-color: map-get($menuLinkActive, bgHover);
        color: map-get($menuLinkActive, colorHover);
      }

      .main-at-top & {
        background-color: map-get($menuLinkActive, bgAtTop);
        color: map-get($menuLinkActive, colorAtTop);

        @include hover-focus {
          background-color: map-get($menuLinkActive, bgAtTopHover);
          color: map-get($menuLinkActive, colorAtTopHover);
        }
      }
    }
  }

  .nav-link-inner {
    width: 100%;
    height: 100%;
    display: inline-block;
    white-space: nowrap;
  }
}

.navbar-nav {
  display: block !important;

  @include media-breakpoint-up(lg) {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
  }

  .nav-item {
    font-size: var(--menuFontSize) !important;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.25rem;

      &:last-child {
        margin-right: -14px;
      }
    }
  }
}

.menu-lining {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--primary);
  position: absolute;
  transition: all 600ms ease-in-out;
}

.navbar-menu {
  .nav-item {
    &.color {
      .nav-link {
        color: var(--menuItemActiveFontColor);
      }
    }

    &.background {
      .nav-link {
        background-color: transparent;
        color: var(--menuItemFontColor);
        font-family: var(--menuFontFamily) !important;
      }
    }

    .nav-link {
      &:hover {
        background-color: var(--menuItemBackgroundColor) !important;
        color: var(--menuItemActiveFontColor) !important;

        span {
          text-decoration: var(--menuItemDecoration) !important;
          font-weight: var(--menuItemFontWeight) !important;
        }
      }

      &.active {
        background-color: var(--menuItemBackgroundColor) !important;
        color: var(--menuItemActiveFontColor) !important;

        span {
          text-decoration: var(--menuItemDecoration) !important;
          font-weight: var(--menuItemFontWeight) !important;
        }
      }
    }

  }
}

.navbar-menu-backdrop {
  max-width: 1024px;
  margin: 0 auto;
  padding: 32px ($grid-gutter-width / 2);

  @include media-breakpoint-up(lg) {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
}

.navbar-menus-wrapper {
  padding-top: 60px;
  display: flex;
  box-orient: horizontal;
  box-align: center;
  flex-direction: row;
  align-items: center;
  transition: padding $defaultTransitionDuration $defaultTransitionFunction;

  @include media-breakpoint-up(lg) {
    margin-left: auto;
    margin-right: auto;
    padding-top: 26px;
    align-items: flex-end;
  }
}

.navbar-collapse {
  @include media-breakpoint-down(md) {
    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
    background-color: var(--mainBackground);
    transition: opacity ($defaultTransitionDuration * 2) $defaultTransitionFunction,
    max-height ($defaultTransitionDuration * 2) $defaultTransitionFunction;
    z-index: -999;
    pointer-events: none;

    &.opening {
      max-height: none;
      opacity: 1;
      display: block;
    }

    &,
    &.opening,
    &.show {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &.opening,
    &.show {
      height: auto !important;
      max-height: 999999px;
      opacity: 1;
      z-index: $zindex-modal;
      pointer-events: all;

      .navbar-menu-backdrop {
        overflow-x: hidden;
        opacity: 1;
      }

      .navbar-menus-wrapper {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
      }
    }

    .close {
      width: 40px;
      height: 40px;
      min-height: 40px;
      margin: 0 0 0 auto;
      transform: rotate(0deg);
      transition: transform $defaultTransitionDuration $defaultTransitionFunction;
      position: relative;
      opacity: 1;
      cursor: pointer;

      @include hover-focus {
        transform: rotate(90deg);
      }

      span {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 60px;
        color: var(--menuItemIconsColor);
      }
    }

    .navbar-menu-backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      background: var(--menuBackgroundColor) !important;
      opacity: 0;
      transition: opacity ($defaultTransitionDuration * 4) $defaultTransitionFunction;
    }

    .navbar-menus-wrapper {
      width: 80vw;
      margin: auto;
      padding-top: 32px;
      padding-bottom: 32px;
      display: flex;
      flex-direction: column;
      position: relative;
      // top:50%; bottom:auto;
      // left:50%; right:auto;
      opacity: 0;
      // overflow:hidden;
      transform: translate3d(0, -100%, 0) scale(2);
      transition: transform ($defaultTransitionDuration * 2) $defaultTransitionFunction,
      opacity ($defaultTransitionDuration * 2) $defaultTransitionFunction;
    }

    .navbar-nav,
    .lang-menu {
      width: 100%;
      max-width: 100%;
      position: relative;
      flex: 0 0 100%;
    }
    li {
      width: 100%;
      max-width: 100%;
      display: block;
    }
    .lang-menu {
      height: 36px;
    }

    a {
      width: 100%;
      display: block;
      color: theme-color(secondary);
    }
  }

  @include media-breakpoint-up(lg) {
    margin-left: 0;
    margin-right: -($grid-gutter-width / 2);
  }
}
