.single-artist {
  .site-main {
    padding-bottom: 36px;

    @include media-breakpoint-up(md) {
      padding-bottom: 54px;
    }
  }

  .page-title {
    margin-bottom: 28px;
    padding: 12px 48px 0;
    font-size: 1.5rem;
    text-align: center;

    @include media-breakpoint-up(md) {
      padding: 8px 0 0;
      text-align: left;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }

    @include media-breakpoint-up(xxl) {
      padding-top: 30px;
    }
  }

  .art-presentation-wrapper {
    margin-bottom: 60px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 16px;
      padding-left: 40px;
    }
  }

  .images-presentation-images {
    .art-presentation-outer,
    .art-thumbs-outer {

      @include media-breakpoint-between(md, lg) {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
  }

  .art-presentation-outer {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 12px;
    }
  }

  .art-presentation {
    margin-bottom: 0;

    .grid-figure {
      @include media-breakpoint-up(md) {
        margin: {
          left: 0;
          right: auto;
        }
      }

      @include media-breakpoint-up(xl) {
        margin: {
          left: auto;
          right: 0;
        }
      }
    }
  }

  .modal .art-presentation .grid-figure {
    @include media-breakpoint-up(md) {
      margin-left: auto;
    }

    @include media-breakpoint-up(xl) {
      margin-right: auto;
    }
  }

  .art-thumbs-outer {
    @include media-breakpoint-up(md) {
      padding-bottom: 30px;
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: 0;
    }
  }

  .artist-aside-socials-share {
    @include media-breakpoint-up(md) {
      margin-top: -30px;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }
  }

}

.page-title {
  .artist-dates {
    display: inline-block;
    color: var(--dark);
    font-size: 1.25rem;
    font-weight: bold;
  }
}

.artist-internal-nav {
  top: 60px;

  @include media-breakpoint-up(md) {
    top: 72px;
  }

  @include media-breakpoint-only(xl) {
    max-width: 450px;
  }

  @include media-breakpoint-up(xxl) {
    top: 200px;
  }
}

// .artist-content-section{
//   overflow:hidden;
//
//   .artist-tabs{
//     width:400vw;
//   }
// }

.artist-tabs {
  .nav-link {
    border-width: 0;
    padding: 8px 16px;
    text-transform: none;
  }
}

.artist-tabs-content {
  padding-top: 24px;
  padding-bottom: 60px;

  #artist-works &,
  #artist-archive & {
    padding-top: 10px;
  }

  #artist-videos &,
  #artist-related-exhibitions &,
  #artist-related-events & {
    padding-top: 30px;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 40px;

    #artist-videos &,
    #artist-related-exhibitions &,
    #artist-related-events & {
      padding-bottom: 60px;
    }
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
  }

  .artist-section-subtitle {
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }
}

.artist-art-thumbs {
  @include media-breakpoint-up(md) {
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);
  }

  @include media-breakpoint-up(xl) {
    margin-left: 0;
    margin-right: 0;
  }
}

.artist-art-thumbs-list {
  margin-bottom: 10px;
  padding: 0 6px;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: right;
  }

  @include media-breakpoint-up(xl) {
    text-align: left;
  }

  .art-thumb-item {
    margin: 0 -6px;
    padding: 7px 10px;
  }
}

#artist-biography {
  @include media-breakpoint-up(md) {
    margin-right: -($grid-gutter-width / 2);
  }
}

.artist-section-content {
  margin-bottom: 24px;
}

.artist-related-item-content {
  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width;
  }

  @include media-breakpoint-up(lg) {
    padding-left: $grid-gutter-width + ($grid-gutter-width / 2);
  }
}

.artist-figure {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

span.artist-figure {
  height: 0;
  padding: {
    bottom: 75%;
  }
  display: block;
  background: no-repeat 50% 50% / cover;
}

.artist-masonry-grid {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 16px;

  .masonry-grid-item:hover {
    background-color: var(--secondaryBackground);
  }
}

.artist-section-article {
  margin-bottom: 30px;

  + .artist-section-article {
    margin-top: 40px;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }

  .img-fluid {
    width: 100%;
  }
}

.artist-related-image-link {
  display: inline-block;

  @include hoverable-dimmed();
  @include hoverable-zoomed("img");

  .artist-section-article & {
    width: 100%;
  }
}

.artist-presentation-text + .col-12 .art-presentation-wrapper {
  @include media-breakpoint-up(xl) {
    margin-top: -94px;
  }
}

.artist-aside-socials-share {
  padding-top: 30px;
  display: block;
  text-align: center;

  .art-presentation-wrapper + & {
    padding-top: 0;
  }

  @include media-breakpoint-up(md) {
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);
    text-align: right;
  }

  @include media-breakpoint-up(xl) {
    margin-left: 0;
    margin-right: 0;
  }
}
