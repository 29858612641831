@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @for $i from 1 through $grid-columns {
      .card-columns#{$infix}-#{$i} {
        column-count: $i;
        column-gap: $card-columns-gap;
      }
    }
  }
}
