.single-exhibition {
  .page-header {
    margin-bottom: 42px;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .header-small-text {
      margin-bottom: 8px;
    }
  }

  .exhibition-row {
    padding-bottom: 50px;
  }

  .images-presentation-images {
    margin-bottom: 10px;

    .art-presentation-outer {
      margin-bottom: 0;
    }
  }

  .exhibition-title {
    padding-left: 45px;
    padding-right: 45px;

    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: 100px
    }
  }

  .exhibition-time {
    margin-bottom: 0;
    line-height: 1;
  }

  .exhibition-content-section {
    margin-top: 80px;
    padding-bottom: 0;
  }

  .masonry-grid {
    padding-bottom: 140px;
  }

  .object-related-list {
    padding: 0;
    font-size: 1rem;
    list-style: none;

    .related-list-item {
      display: inline;

      &:after {
        content: ",";
      }

      &:last-child:after {
        content: "";
      }
    }

    .related-object-link {
      width: 100%;
      display: block;
    }

  }

}

.exhibition-internal-nav {
  @include media-breakpoint-only(xl) {
    max-width: 48%;
    left: 0;
    right: auto;
  }
}

.single-exhibition-breadcrumb {
  margin-bottom: 36px;

  @include media-breakpoint-up(md) {
    margin-bottom: 56px;
  }
}

.exhibition-presentation-text {
  @include media-breakpoint-up(xl) {
    padding-right: $grid-gutter-width;
  }
}

.exhibition-presentation-materials {
  @include media-breakpoint-up(xl) {
    padding-left: $grid-gutter-width;
  }
}

.exhibition-title {
  color: var(--newsExhibitionTitleFontColor) !important;
  font-size: var(--newsExhibitionTitleFontSize);
  line-height: 1.2;
  text-transform: var(--newsExhibitionTitleTextTransform);
  padding-bottom: var(--paddingBottomNewsExhibitionTitle);
  padding-top: var(--paddingTopNewsExhibitionTitle);
  display: flex;
  margin-bottom: 0;

  a {
    color: var(--newsExhibitionTitleFontColor);
    line-height: 1;

    &:hover {
      color: var(--primary);
    }
  }
}

.exhibition-time {
  display: var(--newsExhibitionDateDisplay) !important;
  font-size: var(--newsExhibitionDateFontSize);
  margin-bottom: var(--paddingBottomNewsExhibitionDate) !important;
}

.exhibition-presentation-outer {
  margin-bottom: 10px;
}

.exhibition-aside-socials-share {
  @extend .artist-aside-socials-share;

  margin: {
    top: 4px;
    left: 0;
    right: 0;
  };
  text-align: right;
}

.related-events {
  padding-top: 6px;

  .related-object-name,
  p {
    color: theme-color(secondary);
    font-size: 1rem;
  }

  .related-object-name {
    text-transform: uppercase;

    a {
      color: theme-color(secondary);

      @include hover-focus {
        color: theme-color(secondary);
      }
    }
  }
}

.related-item-link {
  color: theme-color(secondary);

  @include hover-focus {
    color: theme-color(secondary);
  }
}

.exhibition-catalogue-id {
  min-width: 30px;
  height: 30px;
  padding: 0 3px;
  display: block;
  float: right;
  background-color: theme-color(light);
  color: theme-color(dark);
  font-size: 1rem;
  line-height: 30px;
  text-align: center;
  position: absolute;
  right: 0;
  top: var(--artistNameOnListPaddingTopForOffer);
  transform: translateY(-4px);
}
